import { Skeleton, Stack } from '@chakra-ui/react';

export const SkeletonLoading = () => {
  return (
    <Stack w="full">
      <Skeleton height="10px" />
      <Skeleton height="10px" />
      <Skeleton height="10px" />
    </Stack>
  );
};
