import { Flex } from '@chakra-ui/react';
import { useState } from 'react';

interface TabProps {
  index: number;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab = ({ label, isActive, onClick }: TabProps) => {
  return (
    <Flex
      color={isActive ? 'white' : '#95acda'}
      fontWeight="600"
      onClick={onClick}
      mr={5}
      pb={1}
      borderBottomWidth={isActive ? 2 : 0}
      borderBottomColor={isActive ? 'secondary.default' : 'none'}
      _hover={{
        color: 'white',
      }}
      cursor="pointer"
      fontSize={{ base: 'md', md: 'xl' }}
    >
      {label}
    </Flex>
  );
};

interface TabsMenuProps {
  tabs: {
    label: string;
    content: JSX.Element | string;
  }[];
}

export const TabsMenu = ({ tabs }: TabsMenuProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Flex flexDirection="column" maxW="100%" w="full">
      <Flex overflowX="scroll">
        {tabs.map((tab: any, index) => (
          <Tab
            key={tab.index}
            index={index}
            label={tab.label}
            isActive={index === activeTab}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </Flex>
      <Flex mt={10}>
        {tabs.find((tab: any, index) => index === activeTab)?.content}
      </Flex>
    </Flex>
  );
};
