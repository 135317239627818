import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { handleBackendError } from '../../../../common/services/handleBackendError';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import { TournamentPool } from '../../../types/adminTournamentCategory.types';

export const DeletePoolModalConfirmation = ({
  isOpen,
  handleClose,
  pool,
}: {
  isOpen: boolean;
  handleClose: () => void;
  pool?: TournamentPool;
}) => {
  const { translate } = useTranslation();
  const {
    deletePool,
    isDeletePoolLoading,
    refetchTournamentCategoryPoolsList,
  } = useAdminTournaments(pool?.tournamentId, pool?.tournamentCategoryId);
  const toast = useToast();

  const handleDeletePool = async () => {
    if (!pool?.id) {
      toast({
        title: translate('MISSING_POOL_ID'),
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      await deletePool(pool?.id);
      await refetchTournamentCategoryPoolsList();
      toast({
        title: translate('TEAM_DELETED_FROM_POOL'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={handleClose} size="xl">
      <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
        {translate('DELETE_POOL_TITLE')}
      </Heading>
      <Flex mt={5}>
        <Text color="textColor.default" textAlign="center">
          {translate('DELETE_POOL_SUBTITLE')}
        </Text>
      </Flex>
      <Flex w="full" mt={5}>
        <Button
          w="full"
          mr={2}
          onClick={handleClose}
          isLoading={isDeletePoolLoading}
        >
          {translate('CANCEL')}
        </Button>
        <Button
          w="full"
          backgroundColor="red"
          ml={2}
          color="white"
          _hover={{
            backgroundColor: 'none',
          }}
          onClick={handleDeletePool}
          isLoading={isDeletePoolLoading}
        >
          {translate('CONFIRM')}
        </Button>
      </Flex>
    </ResponsiveModal>
  );
};
