import { Heading, Text, Button, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const Error = () => {
  return (
    <Flex
      backgroundColor="background.third"
      h="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgColor="secondary.default"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2} color="white">
        PAGE NOT FOUND
      </Text>
      <Text color={'gray.500'} mb={6}>
        THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST 😬
      </Text>
      <Link to="/">
        <Button
          colorScheme="teal"
          bgColor="secondary.default"
          color="white"
          variant="solid"
          _hover={{
            bgColor: 'secondary.default',
          }}
        >
          Homepage
        </Button>
      </Link>
    </Flex>
  );
};
