import { Flex, Spinner, Text } from '@chakra-ui/react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from '../../../../../../translations/useTranslations';

import { PlayerTeam } from '../../../../types/tournament.types';

export const TournamentCountDown = ({
  playerTeam,
}: {
  playerTeam: PlayerTeam;
}) => {
  const { translate } = useTranslation();
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);

  // Ensure the createdAt timestamp is available
  if (!playerTeam?.createdAt) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="secondary.default"
        size="sm"
      />
    );
  }

  const isPaymentCompleted = playerTeam?.playersPayments?.every(
    (player) => player.hasPaid === true,
  );

  // Convert createdAt to a Date object
  const createdAt = new Date(playerTeam.createdAt);

  // Calculate the target time (24 hours after createdAt)
  const targetTime = createdAt.getTime() + 24 * 60 * 60 * 1000;

  // Calculate the remaining time from now until the target time
  const now = Date.now();
  const remainingTime = targetTime - now;

  if (isPaymentCompleted) {
    return null;
  }

  if (remainingTime <= 0) {
    return (
      <Flex backgroundColor="red" borderRadius={5} p={2} mb={3}>
        <Flex alignItems="center">
          <Flex m={2}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="secondary.default"
              size="sm"
            />
          </Flex>
          <Flex>
            <Text fontSize={{ base: 'sm', md: 'md' }}>
              {translate('TEAM_BEING_DELETE')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setIsTimerCompleted(true);
    } else {
      const formatTime = (time: any) => String(time).padStart(2, '0');

      return (
        <Text as="span" fontSize={{ base: 'sm', md: 'md' }}>
          {translate('TEAM_PAYMENT_TIME_WARNING_PART_1', {
            test1: formatTime(hours),
            test2: formatTime(minutes),
            test3: formatTime(seconds),
          })}
        </Text>
      );
    }
  };

  return isTimerCompleted ? (
    <Flex backgroundColor="red" borderRadius={5} p={2} mb={3}>
      <Flex alignItems="center">
        <Flex m={2}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="secondary.default"
            size="sm"
          />
        </Flex>
        <Flex>
          <Text fontSize={{ base: 'sm', md: 'md' }}>
            {translate('TEAM_BEING_DELETE')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <Flex backgroundColor="orange" borderRadius={5} p={2} mb={3}>
      <Flex alignItems="center">
        <Flex m={2}>
          <FontAwesomeIcon icon={faTriangleExclamation} size="lg" />
        </Flex>
        <Countdown
          date={Date.now() + remainingTime}
          renderer={renderer}
          autoStart
        />
      </Flex>
    </Flex>
  );
};
