import { Button, Flex, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { handleBackendError } from '../../../../common/services/handleBackendError';
import { TournamentTeam } from '../../../../formats/types/tournament.types';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
// import { handleBackendError } from '../../../../common/services/handleBackendError';
import { TournamentCategory } from '../../../types/adminTournamentCategory.types';

export type Inputs = {
  name: string;
  teams?: TournamentTeam[];
};

interface GenerateBrackModalProps {
  isOpen: boolean;
  onClose: () => void;
  chosenCategory?: TournamentCategory;
  tournamentId: number;
}

export const GenerateBracketModal = ({
  isOpen,
  onClose,
  chosenCategory,
  tournamentId,
}: GenerateBrackModalProps) => {
  const { translate } = useTranslation();
  const [numberOfRounds, setNumberOfRounds] = useState<number | null>(null);
  const {
    creatNewBracketCategory,
    isCreatNewBracketCategoryLoading,
    refetchTournamentCategoryBracket,
  } = useAdminTournaments(tournamentId, chosenCategory?.id);
  const toast = useToast();

  const handleSubmit = async () => {
    if (!numberOfRounds || numberOfRounds <= 0) {
      toast({
        title: translate('NUMBER_OF_ROUNDS_ERROR'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await creatNewBracketCategory({ numberOfRounds: numberOfRounds });
      await refetchTournamentCategoryBracket();
      toast({
        title: translate('NEW_BRACKET_CREATED'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleOnChange = (event: any) => {
    setNumberOfRounds(parseInt(event.target.value));
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={handleClose} size="xl">
      <>
        <Heading
          color="white"
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign="center"
        >
          {translate('BRACKET_SIZE_TITLE', {
            category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
          })}
        </Heading>
        <Text color="textColor.default" textAlign="center" mt={5}>
          {translate('BRACKET_SIZE_SUBTITLE')}
        </Text>

        <Flex mt={5}>
          <Input
            color="white"
            placeholder={translate('NUMBER_OF_ROUNDS_PLACEHOLDER')}
            type="number"
            onChange={handleOnChange}
          />
        </Flex>

        <Flex mt={10}>
          <Button
            w="full"
            mr={2}
            onClick={handleClose}
            isLoading={isCreatNewBracketCategoryLoading}
          >
            {translate('CANCEL')}
          </Button>
          <Button
            w="full"
            ml={2}
            backgroundColor="secondary.default"
            color="white"
            _hover={{
              backgroundColor: 'secondary.default',
              color: 'white',
            }}
            onClick={handleSubmit}
            isLoading={isCreatNewBracketCategoryLoading}
          >
            {translate('CREATE_BRACKET')}
          </Button>
        </Flex>
      </>
    </ResponsiveModal>
  );
};
