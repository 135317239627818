import { Flex, HStack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../../../translations/useTranslations';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import { useTournaments } from '../../../../hooks/useTournaments';
import { TournamentCategory } from '../../../../types/tournamentCategory.types';

export const TournamentCategories = ({
  tournamentId,
  setChosenCategory,
}: {
  tournamentId: number;
  setChosenCategory: (category: TournamentCategory) => void;
}) => {
  const { translate } = useTranslation();

  const { tournament } = useTournaments(Number(tournamentId));
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const { isMobile } = useMediaQuery();

  const categoriesList = tournament?.tournamentCategories ?? [];

  useEffect(() => {
    setChosenCategory(categoriesList[0]);
  }, [tournament]);

  const handleCategoryClick = (index: number) => {
    setChosenCategory(categoriesList[index]);
    setSelectedCategory(index);
  };
  return (
    <Flex overflow="scroll" justifyContent="center">
      <HStack
        spacing="15px"
        wrap={isMobile ? 'nowrap' : 'wrap'}
        w="fit-content"
        align="stretch"
      >
        {categoriesList.map((category: any, index: number) => (
          <Flex
            key={`cat-${category.id}`}
            borderWidth={1}
            borderColor={
              index === selectedCategory ? 'secondary.default' : '#2F384C'
            }
            color={
              index === selectedCategory
                ? 'secondary.default'
                : 'textColor.default'
            }
            p={3}
            borderRadius={5}
            flexDirection="column"
            cursor="pointer"
            w={150}
            _hover={{
              borderColor: 'secondary.default',
              color: 'secondary.default',
            }}
            onClick={() => handleCategoryClick(index)}
          >
            <Text fontSize="0.8em" textAlign="center">
              {`${translate(category.categoryGender)} - ${
                category.categoryRanking
              }`}
            </Text>
            <Text fontSize="0.7em" textAlign="center">
              {`[${category.minElo} - ${category.maxElo}]`}
            </Text>
          </Flex>
        ))}
      </HStack>
    </Flex>
  );
};
