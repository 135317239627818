// @ts-nocheck
import React, { ReactElement } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react';
import { GiChicken } from 'react-icons/gi';
import { IconType } from 'react-icons';
import { useTranslation } from '../../../translations/useTranslations';

interface LinkItemProps {
  name: string;
  icon: IconType;
  type: string;
}

interface IAdminSideBarProps {
  children: ReactElement;
  onClick: (type: string) => void; // Type adjusted to take a string and return void
}

export const AdminSideBar = ({ onClick, children }: IAdminSideBarProps) => {
  const { isOpen, onClose } = useDisclosure();

  return (
    <Flex minH="100vh" w="100%">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        onClick={onClick}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      {/* <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} /> */}
      {children}
    </Flex>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, onClick, ...rest }: SidebarProps) => {
  const { translate } = useTranslation();

  const LinkItems: Array<LinkItemProps> = [
    {
      name: translate('TOURNAMENTS'),
      icon: GiChicken,
      type: 'padelTournaments',
    },
    // {
    //   name: translate('SPORT_CATEGORIES'),
    //   icon: GiNoodles,
    //   type: 'sportCategories',
    // },
  ];

  return (
    <Box
      bg="background.secondary"
      borderRight="1px"
      borderRightColor="gray.300"
      w={{ base: 'full', md: 60 }}
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          onClick={() => onClick(link?.type)}
          color="white"
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'secondary.default',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

// interface MobileProps extends FlexProps {
//   onOpen: () => void;
// }
// const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
//   return (
//     <Flex
//       ml={{ base: 0, md: 60 }}
//       px={{ base: 4, md: 24 }}
//       height="20"
//       alignItems="center"
//       bg={useColorModeValue("white", "gray.900")}
//       borderBottomWidth="1px"
//       borderBottomColor={useColorModeValue("gray.200", "gray.700")}
//       justifyContent="flex-start"
//       {...rest}
//     >
//       <IconButton
//         variant="outline"
//         onClick={onOpen}
//         aria-label="open menu"
//         icon={<FiMenu />}
//       />

//       <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
//         Logo
//       </Text>
//     </Flex>
//   );
// };
