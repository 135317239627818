/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import useWindowSize from './useWindowSize';

export default function useMediaQuery() {
  const windowSize = useWindowSize();

  const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  };

  const isMobile = windowSize.width < breakpoints.sm;
  const isTablet =
    windowSize.width < breakpoints.lg && windowSize.width >= breakpoints.sm;
  const isDesktop = windowSize.width >= breakpoints.lg;

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}
