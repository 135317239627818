import { useMutation } from 'react-query';
import { post } from '../../common/services/HttpMethods';

export const usePayment = () => {
  const createTournamentCheckoutPaymentMutation = useMutation(
    (tournamentCheckoutData: {
      userEmail: string;
      tournamentId: number;
      currency: string;
      playerOneTitle: string;
      playerTwoTitle: string;
      imageUrl: string;
      tounamentPriceCents: number;
      teamId: number;
    }): Promise<any> =>
      post('/payment/checkout/tournament', tournamentCheckoutData),
  );

  return {
    tournamentCheckoutPayment:
      createTournamentCheckoutPaymentMutation.mutateAsync,
    isTournamentCheckoutPaymentLoading:
      createTournamentCheckoutPaymentMutation.isLoading,
  };
};
