import { useMutation } from 'react-query';
import { apiRoutes } from '../../common/services/apiRoutes';
import { post } from '../../common/services/HttpMethods';

export const useResetPassword = () => {
  const updatePasswordRequest = useMutation(
    (email: string): Promise<any> =>
      post(apiRoutes.RESET_PASSWORD_REQUEST_ROUTE, { email }),
  );

  const resetPassword = useMutation(
    ({
      resetPasswordToken,
      newPassword,
    }: {
      resetPasswordToken: string;
      newPassword: string;
    }): Promise<any> =>
      post(apiRoutes.RESET_PASSWORD_ROUTE, { resetPasswordToken, newPassword }),
  );

  return {
    updatePasswordRequest: updatePasswordRequest.mutateAsync,
    isUpdatePasswordRequestLoading: updatePasswordRequest.isLoading,
    resetPassword: resetPassword.mutateAsync,
    isResetPasswordLoading: resetPassword.isLoading,
  };
};
