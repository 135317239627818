import { Flex } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { Header } from '../../common/components/Header';
import Footer from '../../common/components/Footer';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useUserProfile } from '../../user/hooks/useUserProfile';
import i18n from '../../../translations/i18n';
import { useHotjar } from '../../common/hooks/hotjar/useHotjar';

export const Root = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();
  const { userProfile, updateUserProfile } = useUserProfile(isAuthenticated);
  useHotjar(isAuthenticated, userProfile);

  useEffect(() => {
    if (userProfile?.locale) {
      i18n.changeLanguage(userProfile?.locale);
    } else {
      const setUserLocale = async () => {
        if (!userProfile?.locale && userProfile?.id) {
          await updateUserProfile({
            id: userProfile?.id,
            locale: navigator.language || 'en-US',
          });
        }
      };
      setUserLocale();
    }
  }, [userProfile]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Flex position="relative" minH="100%" flexDirection="column">
      <Header />
      <Outlet />
      <Footer />
    </Flex>
  );
};
