import { useMutation, useQuery } from 'react-query';
import { del, get, post, put } from '../../common/services/HttpMethods';
import { Tournament } from '../types/adminTournament.types';

export const useAdminTournaments = (
  tournamentId?: number,
  categoryId?: number,
) => {
  const tournamentsListQuery = useQuery<any>(
    ['tournamentsList'],
    async (): Promise<Tournament[]> => get(`/tournaments`),
    {
      staleTime: 50000,
    },
  );

  const tournamentQuery = useQuery<Tournament>(
    [`tournamment-${tournamentId}`],
    async (): Promise<any> => get(`/tournaments/${tournamentId}`),
    {
      staleTime: 50000,
      enabled: !!tournamentId,
    },
  );

  const tournamentTeamsByCategoryQuery = useQuery<[]>(
    [`tournamment-${tournamentId}-teams-${categoryId}`],
    async (): Promise<any> =>
      get(`/admin/tournaments/${tournamentId}/categories/${categoryId}/teams`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!categoryId,
    },
  );

  const tournamentPoolsCategoryList = useQuery(
    [`pools-tournament-${tournamentId}-category-${categoryId}`],
    async (): Promise<any> =>
      get(`/admin/tournaments/${tournamentId}/categories/${categoryId}/pools`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!categoryId,
    },
  );

  const createPoolCategoryMutation = useMutation(
    (newPoolCategory: any): Promise<any> =>
      post(
        `/admin/tournaments/${tournamentId}/categories/${categoryId}/pools`,
        newPoolCategory,
      ),
  );

  const updatePoolMutation = useMutation(
    ({ poolId, updatedPool }: any): Promise<any> =>
      put(`/admin/pools/${poolId}`, updatedPool),
  );

  const deletePoolMutation = useMutation(
    (poolId: number): Promise<any> => del(`/admin/pools/${poolId}`),
  );

  const tournamentsBracketQuery = useQuery<any>(
    [`tournament-${tournamentId}-category-${categoryId}-bracket`],
    async (): Promise<any> =>
      get(`/tournaments/${tournamentId}/categories/${categoryId}/bracket`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!categoryId,
    },
  );

  const createTournamentBracketByCategoryMutation = useMutation(
    (numberOfRounds: { numberOfRounds: number }): Promise<any> =>
      post(
        `/admin/tournaments/${tournamentId}/categories/${categoryId}/bracket`,
        numberOfRounds,
      ),
  );

  const deleteBracketMatchesByCategoryMutation = useMutation(
    ({
      tournamentId,
      categoryId,
    }: {
      tournamentId: number;
      categoryId: number;
    }): Promise<any> =>
      del(
        `/admin/tournaments/${tournamentId}/categories/${categoryId}/bracket`,
      ),
  );

  return {
    tournamentsList: tournamentsListQuery.data,
    isTournamentListLoading: tournamentsListQuery.isLoading,
    tournament: tournamentQuery.data,
    isTournamentLoading: tournamentQuery.isLoading,
    refetchTournament: tournamentQuery.refetch,
    tournamentCategoryPoolsList: tournamentPoolsCategoryList.data,
    isTournamentPoolsCategoryListLoading: tournamentPoolsCategoryList.isLoading,
    refetchTournamentCategoryPoolsList: tournamentPoolsCategoryList.refetch,
    creatNewPoolCategory: createPoolCategoryMutation.mutateAsync,
    isCreateNewPoolCategoryLoading: createPoolCategoryMutation.isLoading,
    deletePool: deletePoolMutation.mutateAsync,
    isDeletePoolLoading: deletePoolMutation.isLoading,
    updatePool: updatePoolMutation.mutateAsync,
    isUpdatePoolLoading: updatePoolMutation.isLoading,
    tournamentTeams: tournamentTeamsByCategoryQuery.data,
    isTournamentTeamsLoading: tournamentTeamsByCategoryQuery.isLoading,
    tournamentCategoryBracket: tournamentsBracketQuery.data,
    isTournamentCategoryBracketLoading: tournamentsBracketQuery.isLoading,
    refetchTournamentCategoryBracket: tournamentsBracketQuery.refetch,
    creatNewBracketCategory:
      createTournamentBracketByCategoryMutation.mutateAsync,
    isCreatNewBracketCategoryLoading:
      createTournamentBracketByCategoryMutation.isLoading,
    deleteBracketCategory: deleteBracketMatchesByCategoryMutation.mutateAsync,
    isDeleteBracketCategoryLoading:
      deleteBracketMatchesByCategoryMutation.isLoading,
  };
};
