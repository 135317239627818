import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { handleBackendError } from '../../../../common/services/handleBackendError';
import { useAdminTournamentsMatches } from '../../../hooks/useAdminTournamentMatches';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import { Match } from '../../../types/adminTournament.types';

interface ResetTeamsModalProps {
  isOpen: boolean;
  onClose: () => void;
  match?: Match;
}

export const ResetTeamsModal = ({
  isOpen,
  onClose,
  match,
}: ResetTeamsModalProps) => {
  const { translate } = useTranslation();
  const { updateMatchTeam, isUpdateMatchTeamLoading } =
    useAdminTournamentsMatches();
  const { refetchTournamentCategoryBracket } = useAdminTournaments(
    match?.tournamentId,
    match?.categoryId,
  );

  const toast = useToast();

  const handleSubmit = async () => {
    try {
      await updateMatchTeam({
        matchId: match?.id,
        updatedMatch: {
          teamAId: null,
          teamBId: null,
          startDate: null,
          endDate: null,
          courtName: null,
          matchStatus: null,
        },
      });

      toast({
        title: translate('MATCH_EDITED_SUCCESSFULLY'),
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
      await refetchTournamentCategoryBracket();
      onClose();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} size="xl">
      <>
        <Heading
          color="white"
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign="center"
        >
          {translate('RESET_TEAMS_IN_MATCH')}
        </Heading>

        <Text color="textColor.default" textAlign="center" mt={5}>
          {translate('RESET_TEAMS_IN_MATCH_CONFIRM')}
        </Text>

        <Flex mt={10}>
          <Button
            w="full"
            mr={2}
            onClick={onClose}
            isLoading={isUpdateMatchTeamLoading}
          >
            {translate('CANCEL')}
          </Button>
          <Button
            w="full"
            ml={2}
            backgroundColor="red"
            color="white"
            _hover={{
              backgroundColor: 'red',
              color: 'white',
            }}
            onClick={handleSubmit}
            isLoading={isUpdateMatchTeamLoading}
          >
            {translate('DELETE_BRACKET_BUTTON_TITLE')}
          </Button>
        </Flex>
      </>
    </ResponsiveModal>
  );
};
