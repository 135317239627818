import React, { FC } from 'react';
import { DrawerProps, ModalProps } from '@chakra-ui/react';
import useMediaQuery from '../hooks/useMediaQuery';
import { Drawer } from './Drawer';
import { Modal } from './Modal';

type Props = ModalProps | DrawerProps;

export const ResponsiveModal: FC<Props> = ({ children, ...props }) => {
  const { isMobile } = useMediaQuery();

  if (isMobile)
    return (
      <Drawer placement="bottom" {...props}>
        {children}
      </Drawer>
    );

  return (
    <Modal isCentered {...props}>
      {children}
    </Modal>
  );
};
