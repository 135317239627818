import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthTokenFromLocalStorage } from '../../common/services/HttpMethods';

const AuthenticatedRouteGuard = ({ children }: { children: ReactElement }) => {
  const accessToken = getAuthTokenFromLocalStorage();

  if (!accessToken) {
    return children;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default AuthenticatedRouteGuard;
