import { Heading } from '@chakra-ui/react';
import { useTranslation } from '../../../translations/useTranslations';
import { PagesContainer } from '../../common/components/PagesContainer';
import { MissingInfosCard } from './MissingInfosCard';
import { PlayerDashboardContent } from './PlayerDashboardContent';

export const PlayerDashboard = () => {
  const { translate } = useTranslation();

  return (
    <PagesContainer
      flexProps={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
    >
      <>
        <Heading color="textColor.secondary">
          {translate('DASHBOARD_TITLE')}
        </Heading>
        <MissingInfosCard />
        <PlayerDashboardContent />
      </>
    </PagesContainer>
  );
};
