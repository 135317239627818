import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { MatchStatus as MatchStatusEnum } from '../../types/adminTournament.types';
import { useTranslation } from '../../../../translations/useTranslations';

const statusColors = {
  [MatchStatusEnum.ONGOING]: 'green',
  [MatchStatusEnum.COMPLETED]: 'purple',
  [MatchStatusEnum.SCHEDULED]: 'blue',
  [MatchStatusEnum.CANCELLED]: 'red',
};

interface MatchStatusProps {
  status?: MatchStatusEnum;
}

export const MatchStatus: React.FC<MatchStatusProps> = ({ status }) => {
  const { translate } = useTranslation();
  if (!status) return null;

  return (
    <Box
      display="inline-block"
      borderRadius="999"
      backgroundColor={statusColors[status]}
      color="white"
      fontWeight="bold"
      px={3}
    >
      <Text fontSize="sm">{translate(`${status}`)}</Text>
    </Box>
  );
};
