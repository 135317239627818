import React from 'react';
import { Text, Box } from '@chakra-ui/react';

export const CustomMarkdown = ({ text }: any) => {
  // Convert line breaks
  const paragraphs = text.split(/\n\n/);

  const parseText = (text: string) => {
    // Bold: **text**
    text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Italics: *text*
    text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');

    // Links: [label](url)
    text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

    // Replace line breaks
    text = text.replace(/\n/g, '<br/>');

    return text;
  };

  return (
    <Box>
      {paragraphs.map((paragraph: any, index: any) => (
        <Text
          key={index}
          dangerouslySetInnerHTML={{ __html: parseText(paragraph) }}
          mb={4}
        />
      ))}
    </Box>
  );
};
