import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import { User } from '../../../user/types/user.types';

const siteId = 3744643;
const hotjarVersion = 6;

export const useHotjar = (
  isAuthenticated: boolean,
  userProfile: User | undefined,
) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Hotjar.init(siteId, hotjarVersion);

      if (isAuthenticated && userProfile) {
        Hotjar.identify(userProfile.id.toString(), {
          email: userProfile?.email,
          locale: userProfile?.locale,
          name: userProfile.displayName || '',
        });

        Hotjar.event('authenticated_user');
      } else {
        Hotjar.event('guest_user');
      }
    }
  }, [isAuthenticated, userProfile]);
};
