import { createContext, useContext } from 'react';
import { useAuth as useAuthHook } from '../modules/auth/hooks/useAuth';

interface AuthContextType {
  isAuthenticated: boolean;
  authToken: string | null;
  handleLoginSuccess: (data: any) => void;
  logIn: (user: any) => Promise<void>; // Replace 'any' with the actual type for user
  isLoginLoading: boolean;
  registerUser: (newUser: any) => Promise<void>; // Replace 'any' with the actual type for newUser
  isRegisterUserLoading: boolean;
  logOut: () => Promise<unknown>;
  isLogoutLoading: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuthHook();

  return (
    <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
