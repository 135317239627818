import { useEffect, useState } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import i18n from '../../../translations/i18n';
import { useTranslation } from '../../../translations/useTranslations';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useUserProfile } from '../../user/hooks/useUserProfile';
import { handleBackendError } from '../services/handleBackendError';

export const languages = [
  { locale: 'en-US', flag: '🇺🇸' },
  { locale: 'fr-BE', flag: '🇫🇷' },
  { locale: 'nl-BE', flag: '🇳🇱' },
];

export const LanguagePicker = () => {
  const { isAuthenticated } = useAuthContext();
  const { userProfile, updateUserProfile } = useUserProfile(isAuthenticated);
  const { translate } = useTranslation();
  const toast = useToast();
  const [currentLanguage, setCurrentLanguage] = useState(() => i18n.language);

  useEffect(() => {
    if (userProfile?.locale) {
      setCurrentLanguage(userProfile.locale);
    }
  }, [userProfile]);

  const handleChangeLanguage = async (locale: any) => {
    i18n.changeLanguage(locale);
    setCurrentLanguage(locale);
    try {
      if (userProfile?.id) {
        await updateUserProfile({
          id: userProfile.id,
          locale: locale,
        });
        toast({
          title: translate('DEFAULT_LANGUAGE_SET'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const selectedLanguage = languages.find(
    (lang) => lang.locale === currentLanguage,
  );

  return (
    <Menu>
      <MenuButton
        as={Button}
        border="none"
        backgroundColor="transparent"
        _hover={{ bg: 'transparent' }}
        _expanded={{ bg: 'transparent' }}
        _focus={{ boxShadow: 'none' }}
        position="relative"
        top="2px"
      >
        {selectedLanguage ? selectedLanguage.flag : ''}
      </MenuButton>
      <MenuList minWidth="auto" maxWidth="auto">
        {languages.map((element) => (
          <MenuItem
            key={element.locale}
            onClick={() => handleChangeLanguage(element.locale)}
            color="black"
          >
            {`${element.flag} ${translate(element.locale)}`}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
