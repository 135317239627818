import { useRef } from 'react';
import {
  Flex,
  Heading,
  Image,
  Input,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { PagesContainer } from '../../common/components/PagesContainer';
import { UserProfileForm } from './UserProfileForm';
import { useUserProfile } from '../hooks/useUserProfile';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useTranslation } from '../../../translations/useTranslations';
import { handleBackendError } from '../../common/services/handleBackendError';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import usePageView from '../../common/hooks/GA4/usePageView';

export const UserProfile = () => {
  usePageView('/profile', 'Profile page view');
  const { isMobile } = useMediaQuery();
  const { isAuthenticated } = useAuthContext();
  const { translate } = useTranslation();
  const toast = useToast();
  const {
    userProfile,
    addProfilePicture,
    refetchUserProfile,
    deleteProfilePicture,
    isAddProfilePictureLoading,
    isDeleteProfilePictureLoading,
  } = useUserProfile(isAuthenticated);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file); // Ensure the backend expects 'image' as the key

      try {
        await addProfilePicture(formData); // send the FormData
        toast({
          title: translate('PROFILE_PICTURE_UPDATED'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        await refetchUserProfile();
      } catch (error) {
        handleBackendError(error, toast, translate);
      }
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      await deleteProfilePicture(); // send the FormData
      toast({
        title: translate('PROFILE_PICTURE_DELETED'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await refetchUserProfile();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const handleChangeProfilePicture = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };

  return (
    <PagesContainer>
      <>
        <Flex flexDirection="column" w="100%">
          <Flex>
            <Flex>
              <Input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="center" w="full">
              <Flex color="white" ml={5}>
                <Heading fontSize={{ base: '3xl', sm: '5xl' }} color="white">
                  {userProfile?.displayName}
                </Heading>
              </Flex>
              <Flex
                onClick={handleChangeProfilePicture}
                cursor="pointer"
                mt={5}
              >
                {isAddProfilePictureLoading || isDeleteProfilePictureLoading ? (
                  <Spinner size="xl" color="secondary.default" />
                ) : (
                  <Image
                    borderRadius="full"
                    boxSize={isMobile ? '150px' : '300px'}
                    objectFit="cover"
                    src={userProfile?.profilePictureUrl}
                    _hover={{
                      opacity: 0.7,
                    }}
                    fallbackSrc={`${process.env.REACT_APP_CLOUDFRONT_URL}/static/avatar.png`}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Stack
          spacing={6}
          direction={['column', 'column', 'column', 'column', 'row']}
          w="100%"
          mt={10}
        >
          <Stack direction="row" w="100%" position="relative">
            <Flex w="100%" flexDirection="column">
              <Flex flexDirection="column" alignItems="center">
                <Flex
                  color="secondary.default"
                  textDecoration="underline"
                  cursor="pointer"
                  justifyContent="center"
                  textAlign="center"
                  onClick={handleChangeProfilePicture}
                  w="fit-content"
                >
                  {translate('UPDATE_PROFILE_PICTURE_TITLE')}
                </Flex>
                {userProfile?.profilePictureUrl && (
                  <Flex
                    color="red"
                    textDecoration="underline"
                    cursor="pointer"
                    mt={2}
                    justifyContent="center"
                    textAlign="center"
                    w="fit-content"
                    onClick={handleDeleteProfilePicture}
                  >
                    {translate('DELETE_PROFILE_PICTURE_TITLE')}
                  </Flex>
                )}
              </Flex>
              <UserProfileForm />
            </Flex>
          </Stack>
        </Stack>
      </>
    </PagesContainer>
  );
};
