import { Button, Flex, Heading, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import { TournamentCategory } from '../../../types/adminTournamentCategory.types';
import { DeleteTournamentBracketModal } from '../tournamentPool/DeleteTournamentBracketModal';
import { GenerateBracketModal } from '../tournamentPool/GenerateBracketModal';
import { AdminTournamentBrackets } from './AdminTournamentBracket';

export const FinalPhase = ({
  chosenCategory,
  tournamentId,
}: {
  chosenCategory?: TournamentCategory;
  tournamentId: number;
}) => {
  const { translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const { tournamentCategoryBracket } = useAdminTournaments(
    tournamentId,
    chosenCategory?.id,
  );

  return (
    <Stack
      direction={['column']}
      spacing="24px"
      mt={10}
      borderWidth={1}
      borderRadius={10}
      p={5}
      backgroundColor="background.secondary"
    >
      <Heading fontSize={{ base: '2xl', md: '3xl' }} color="secondary.default">
        {translate('FINAL_PHASE', {
          category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
        })}
      </Heading>
      <Flex>
        {!tournamentCategoryBracket ||
          (tournamentCategoryBracket.length === 0 ? (
            <Button
              ml={2}
              backgroundColor="secondary.default"
              color="white"
              _hover={{
                backgroundColor: 'secondary.default',
                color: 'white',
              }}
              onClick={() => setIsModalOpen(true)}
              // isLoading={isUpdateMatchLoading}
            >
              {translate('GENERATE_TOURNAMENT_CATEGORY_BRACKET_TITLE', {
                category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
              })}
            </Button>
          ) : (
            <Button
              ml={2}
              backgroundColor="red"
              color="white"
              _hover={{
                backgroundColor: 'red',
                color: 'white',
              }}
              onClick={() => setIsModalDeleteOpen(true)}
              // isLoading={isUpdateMatchLoading}
            >
              {translate('DELETE_TOURNAMENT_CATEGORY_BRACKET_TITLE', {
                category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
              })}
            </Button>
          ))}
      </Flex>
      <AdminTournamentBrackets
        categoryBracket={tournamentCategoryBracket}
        chosenCategory={chosenCategory}
      />
      <GenerateBracketModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
      />
      <DeleteTournamentBracketModal
        isOpen={isModalDeleteOpen}
        onClose={() => setIsModalDeleteOpen(false)}
        tournamentId={tournamentId}
        chosenCategory={chosenCategory}
      />
    </Stack>
  );
};
