import { Flex, Stack, Text } from '@chakra-ui/react';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import { TournamentPool } from '../../../types/adminTournamentCategory.types';
import { DeletePoolModalConfirmation } from './DeletePoolModal';
import { EditCategoryPoolModal } from './EditCategoryPoolModal';

export const CategoryPoolsList = ({
  chosenCategory,
  tournamentId,
}: {
  chosenCategory: any;
  tournamentId: number;
}) => {
  const { translate } = useTranslation();
  const { tournamentCategoryPoolsList } = useAdminTournaments(
    tournamentId,
    chosenCategory?.id,
  );
  const [isEditPoolModalOpen, setIsEditPoolModalOpen] = useState(false);
  const [isDeletePoolModalOpen, setIsDeletePoolModalOpen] = useState(false);
  const [currentPool, setCurrentPool] = useState<TournamentPool>();

  return (
    <Flex w="full" overflowX="hidden" flexDirection="column">
      <Stack direction={['column', 'row']} w="full">
        <Flex w="full" flexDirection="column">
          {chosenCategory?.teams?.length === 0 && (
            <Flex justifyContent="center" color="textColor.default">
              {translate('NO_CATEGORY_TEAM_REGISTERED')}
            </Flex>
          )}
          <Stack
            direction={['column', 'row']}
            spacing="24px"
            flexWrap="wrap"
            justifyContent="center"
          >
            {tournamentCategoryPoolsList?.map(
              (pool: TournamentPool, index: number) => {
                return (
                  <Flex
                    key={index}
                    color="white"
                    minW={400}
                    borderRadius={6}
                    p={4}
                    flexDirection="column"
                    backgroundColor="background.default"
                  >
                    <Flex justifyContent="space-between" position="relative">
                      <Text
                        textAlign="center"
                        fontSize="xl"
                        fontWeight="bold"
                        w="full"
                      >
                        {translate('POOL_NAME', {
                          name: pool?.name,
                        })}
                      </Text>
                      <Flex
                        _hover={{
                          cursor: 'pointer',
                        }}
                        mr={5}
                        alignItems="center"
                        onClick={() => {
                          setCurrentPool(pool);
                          setIsEditPoolModalOpen(true);
                        }}
                        position="absolute"
                        right={7}
                        top={1}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="sm"
                          color="white"
                        />
                      </Flex>
                      <Flex
                        _hover={{
                          cursor: 'pointer',
                        }}
                        mr={5}
                        alignItems="center"
                        onClick={() => {
                          setCurrentPool(pool);
                          setIsDeletePoolModalOpen(true);
                        }}
                        position="absolute"
                        right={0}
                        top={1}
                      >
                        <FontAwesomeIcon icon={faTrash} size="sm" color="red" />
                      </Flex>
                    </Flex>

                    <Flex
                      px={3}
                      justifyContent="space-between"
                      borderBottomWidth={1}
                    >
                      <Text color="secondary.default" fontWeight="bold">
                        {translate('TEAMS_TITLE')}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column">
                      {pool.teams?.map((team, index) => {
                        const playerOne = team?.players?.[0];
                        const playerTwo = team?.players?.[1];
                        const playerEloOneAtRegistration =
                          team?.playersEloAtRegistration?.find(
                            (el) => el?.playerId === playerOne?.id,
                          );

                        const playerEloTwoAtRegistration =
                          team?.playersEloAtRegistration?.find(
                            (el) => el?.playerId === playerTwo?.id,
                          );

                        return (
                          <Flex
                            key={index}
                            p={3}
                            justifyContent="space-between"
                          >
                            <Flex flexDirection="column">
                              <Flex color="textColor.default">
                                {`${playerOne?.user?.displayName}`}
                                {playerEloOneAtRegistration &&
                                  ` (${playerEloOneAtRegistration.elo})`}
                              </Flex>
                              <Flex color="textColor.default">
                                {`${playerTwo?.user?.displayName}`}
                                {playerEloTwoAtRegistration &&
                                  ` (${playerEloTwoAtRegistration.elo})`}
                              </Flex>
                            </Flex>
                            <Flex alignItems="center">
                              <Text
                                fontWeight="bold"
                                color="secondary.default"
                                fontSize="xl"
                              >
                                {team?.eloTeam}
                              </Text>
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Flex>
                );
              },
            )}
          </Stack>
        </Flex>
      </Stack>
      <EditCategoryPoolModal
        isOpen={isEditPoolModalOpen}
        onClose={() => setIsEditPoolModalOpen(false)}
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
        pool={currentPool}
      />
      <DeletePoolModalConfirmation
        isOpen={isDeletePoolModalOpen}
        handleClose={() => setIsDeletePoolModalOpen(false)}
        pool={currentPool}
      />
    </Flex>
  );
};
