import React, { ReactElement, useState } from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from '../../../../translations/useTranslations';
import { DrawerComponent } from '../../common/components/Drawer';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSport } from '../../hooks/useSport';
import { useSportCategories } from '../../hooks/useSportCategories';
import { handleBackendError } from '../../../common/services/handleBackendError';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { DeleteModal } from '../../common/components/DeleteModal';
import GenericTable from '../../common/components/GenericTable';

const columnHelper = createColumnHelper<SportCategory>();

export type SportCategory = {
  id: number;
  name: string;
  sport: { id: number; name: string };
  actions?: ReactElement;
};

export type Inputs = {
  id: number;
  name: string;
  order?: string;
  sportId: number;
};

export const AdminSportCategories = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null);
  const [editingCategory, setEditingCategory] = useState<Inputs | null>(null);

  const { translate } = useTranslation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const { sportsList } = useSport();
  const {
    createSportCategory,
    isCreateSportCategoryLoading,
    sportsCategoriesList,
    isSportsCategoriesListLoading,
    refetchCategoriesList,
    deleteSportCategory,
    updateSportCategory,
  } = useSportCategories();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (editingCategory) {
        await updateSportCategory({
          categoryId: Number(editingCategory.id),
          updatedCategory: { ...data, sportId: Number(data.sportId) },
        });
      } else {
        await createSportCategory(data);
      }
      toast({
        title: editingCategory
          ? translate('SPORT_CATEGORY_UPDATED')
          : translate('NEW_SPORT_CATEGORY_CREATED'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      refetchCategoriesList();
      reset();
      setIsDrawerOpen(false);
      setEditingCategory(null);
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const handleDeleteCategory = async (id: number) => {
    if (!id) return;
    try {
      await deleteSportCategory(Number(id));
      toast({
        title: translate('SPORT_CATEGORY_DELETED'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      refetchCategoriesList();
    } catch (error) {
      handleBackendError(error, toast, translate);
    } finally {
      setDeleteCategoryId(null);
    }
  };

  const columns: ColumnDef<SportCategory, any>[] = [
    columnHelper.accessor('name', {
      header: () => translate('NAME'),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor((row) => (row.sport ? row.sport.name : ''), {
      id: 'sportName',
      header: () => translate('SPORT_TITLE'),
      cell: (info) => {
        return translate(`${info.getValue()}`) || 'N/A';
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Flex justifyContent="flex-end">{translate('ACTIONS')}</Flex>
      ),
      cell: ({ row }) => {
        return (
          <Flex justifyContent="flex-end">
            <EditIcon
              mr={5}
              cursor="pointer"
              onClick={() => {
                setEditingCategory({
                  id: row.original.id,
                  name: row.original.name,
                  sportId: row.original.sport.id,
                });
                setIsDrawerOpen(true);
              }}
            />
            <DeleteIcon
              cursor="pointer"
              color="red"
              onClick={() => setDeleteCategoryId(row.original.id)}
            />
            <DeleteModal
              isOpen={deleteCategoryId !== null}
              onClose={() => setDeleteCategoryId(null)}
              onConfirm={() => handleDeleteCategory(deleteCategoryId as number)}
              title={translate('DELETE_CATEGORY_TITLE')}
              content={translate('DELETE_CATEGORY_CONTENT')}
            />
          </Flex>
        );
      },
    }),
  ];

  return (
    <Flex w="full" flexDirection="column" p={10}>
      <Heading fontSize={{ base: '3xl', md: '5xl' }}>
        {translate('SPORT_CATEGORIES')}
      </Heading>
      <Flex justifyContent="flex-end">
        <Button
          bg={'secondary.default'}
          color={'white'}
          _hover={{
            bg: 'secondary.default',
          }}
          onClick={() => setIsDrawerOpen(true)}
        >
          {translate('ADD_SPORT_CATEGORY_TITLE')}
        </Button>
      </Flex>
      <GenericTable
        data={sportsCategoriesList || []}
        columns={columns}
        isLoading={isSportsCategoriesListLoading}
      />

      <form>
        <DrawerComponent
          title={translate('ADD_SPORT_CATEGORY_TITLE')}
          isOpen={isDrawerOpen}
          isLoading={isCreateSportCategoryLoading}
          onClose={() => {
            reset();
            setEditingCategory(null);
            setIsDrawerOpen(false);
          }}
          handleSubmit={handleSubmit(onSubmit)}
        >
          <FormControl>
            <FormLabel>{translate('NAME')}</FormLabel>
            <Input
              placeholder=" "
              defaultValue={editingCategory?.name}
              {...register('name', { required: true })}
            />
            {errors.name && (
              <Flex color="red">{translate('FIELD_REQUIRED')}</Flex>
            )}
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>{translate('SPORT')}</FormLabel>
            <Select
              placeholder="Select option"
              defaultValue={editingCategory?.sportId.toString()}
              {...register('sportId', { required: true })}
            >
              {sportsList &&
                sportsList?.map((sport: any) => {
                  return (
                    <option value={Number(sport.id)}>
                      {translate(`${sport.name}`)}
                    </option>
                  );
                })}
            </Select>
            {errors.sportId && (
              <Flex color="red">{translate('FIELD_REQUIRED')}</Flex>
            )}
          </FormControl>
        </DrawerComponent>
      </form>
    </Flex>
  );
};
