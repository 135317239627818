import { useQuery } from 'react-query';
import { get } from '../../common/services/HttpMethods';

interface TournamentPoolsHookProps {
  tournamentId?: number;
  categoryId?: number;
  poolId?: number;
}

export const useTournamentsPools = ({
  tournamentId,
  categoryId,
  poolId,
}: TournamentPoolsHookProps) => {
  const tournamentPoolsCategoryList = useQuery(
    [`client-pools-tournament-${tournamentId}-category-${categoryId}`],
    async (): Promise<any> =>
      get(`/tournaments/${tournamentId}/categories/${categoryId}/pools`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!categoryId,
    },
  );

  const tournamentPoolRankingList = useQuery(
    [`client-tournament-${tournamentId}-pool-${poolId}-rankings`],
    async (): Promise<any> =>
      get(`/tournaments/${tournamentId}/pools/${poolId}/rankings`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!poolId,
    },
  );

  return {
    tournamentCategoryPoolsList: tournamentPoolsCategoryList.data,
    isTournamentPoolsCategoryListLoading: tournamentPoolsCategoryList.isLoading,
    refetchTournamentCategoryPoolsList: tournamentPoolsCategoryList.refetch,
    tournamentPoolRankingList: tournamentPoolRankingList.data,
    isTournamentPoolRankingListLoading: tournamentPoolRankingList.isLoading,
  };
};
