import {
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Radio,
  Text,
  useToast,
} from '@chakra-ui/react';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthProvider';
import { useTranslation } from '../../../../../translations/useTranslations';
import {
  calculatePartnerEloBoundaries,
  checkCategoriesBoundaries,
  genderCheck,
  isRegistrationClosed,
} from '../../../../../utils/utils';
import { CustomMarkdown } from '../../../../common/components/CustomMarkdown';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { SkeletonLoading } from '../../../../common/components/SkeletonLoading';
import { useUserSearch } from '../../../../common/hooks/useSearchPlayers';
import { handleBackendError } from '../../../../common/services/handleBackendError';
import { useTournamentCheckout } from '../../../../payment/hooks/useTournamentCheckout';
import { usePlayerProfile } from '../../../../player/hooks/usePlayer';
import { useUserProfile } from '../../../../user/hooks/useUserProfile';
import { useTournaments } from '../../../hooks/useTournaments';
import { TournamentCategory } from '../../../types/tournamentCategory.types';

interface JoinTournamentModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  categoryChosen?: TournamentCategory | null;
  clearCategoryChosen: () => void;
  tournament: any;
}

export const ChooseTournamentCategory = ({
  category,
  tournament,
  handleChosenCategory,
}: {
  category: TournamentCategory;
  tournament: any;
  handleChosenCategory: (category: TournamentCategory) => void;
}) => {
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const { playerProfile } = usePlayerProfile(userProfile?.id);
  const { translate } = useTranslation();

  const isCategoryFull = category?.teams.length === category?.capacity;
  const isClosed = isRegistrationClosed(tournament?.registrationClosingDate);

  const canJoinCategory =
    userProfile &&
    playerProfile &&
    genderCheck(userProfile.gender, category?.categoryGender) &&
    !isCategoryFull &&
    !isClosed &&
    checkCategoriesBoundaries(
      playerProfile.elo,
      category.minElo,
      category.maxElo,
    );

  if (
    playerProfile &&
    !checkCategoriesBoundaries(
      playerProfile.elo,
      category.minElo,
      category.maxElo,
    )
  )
    return null;

  return (
    <Flex
      mt={5}
      p={5}
      borderWidth={1}
      borderColor="#2F384C"
      borderRadius={10}
      flexWrap="wrap"
    >
      <HStack spacing="24px" w="full">
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text color="textColor.light">{translate('CATEGORIES_TITLE')}</Text>
          <Text color="white">
            {`${translate(category?.categoryGender)} - ${
              category?.categoryRanking
            }`}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text color="textColor.light">{translate('ELO_RANGE_TITLE')}</Text>
          <Text color="white">
            {`[${category?.minElo} - ${category?.maxElo}]`}
          </Text>
        </Flex>
      </HStack>
      <HStack spacing="24px" w="full" mt={5}>
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text color="textColor.light" textAlign="center">
            {translate('TEAMS_REGISTERED_TITLE_SHORT')}
          </Text>
          <Text color="white">
            {category?.teams.length} / {category?.capacity}
          </Text>
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          w="full"
        >
          {!canJoinCategory && (
            <Text color="red" textAlign="center">
              {translate('CATEGORY_NOT_ACCESSIBLE')}
            </Text>
          )}
          {canJoinCategory ? (
            <Button
              bg="secondary.default"
              color={'white'}
              _hover={{
                backgroundColor: 'secondary.default',
              }}
              onClick={() => handleChosenCategory(category)}
            >
              {translate('REGISTER_MYSELF')}
            </Button>
          ) : (
            ''
          )}
        </Flex>
      </HStack>
    </Flex>
  );
};

export const JoinTournamentModal = ({
  isModalOpen,
  onClose,
  categoryChosen,
  clearCategoryChosen,
}: JoinTournamentModalProps) => {
  const { id } = useParams();
  const { tournament, refetchTournament } = useTournaments(Number(id));
  const [chosenCategory, setChosenCategory] =
    useState<TournamentCategory | null>();
  const { translate } = useTranslation();
  const [input, setInput] = useState('');
  const [debouncedInput, setDebouncedInput] = useState(input);
  const { searchPlayers, isSearchLoading } = useUserSearch(debouncedInput);
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null); // State to track the selected player
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const { playerProfile } = usePlayerProfile(userProfile?.id);
  const { createNewTeam } = useTournaments();
  const [newCreatedTeam, setNewCreatedTeam] = useState<any>();
  const navigate = useNavigate();
  const { playerTeam, refetchPlayerTeam } = useTournaments(
    tournament?.id,
    playerProfile?.id,
  );
  const { handlePayment, isTournamentCheckoutPaymentLoading } =
    useTournamentCheckout();
  const toast = useToast();

  const handleChangeCategory = (category: TournamentCategory) => {
    setChosenCategory(category);
  };

  useEffect(() => {
    if (categoryChosen) {
      setChosenCategory(categoryChosen);
    }
  }, [categoryChosen]);

  useEffect(() => {
    setSelectedPlayer(null);
    const timerId = setTimeout(() => {
      setDebouncedInput(input);
    }, 300); // Debounce the input by 300 ms

    return () => {
      clearTimeout(timerId);
    };
  }, [input]);

  const handleSelectPlayer = (player: any) => {
    setSelectedPlayer(player); // Set selected player
  };

  const handleOnClose = () => {
    setChosenCategory(null);
    clearCategoryChosen();
    setInput('');
    onClose();
  };

  let partnerEloBoundaries;

  if (playerProfile && chosenCategory) {
    partnerEloBoundaries = calculatePartnerEloBoundaries(
      playerProfile?.elo,
      chosenCategory?.minElo,
      chosenCategory?.maxElo,
    );
  }

  const handleAddPartner = async () => {
    const userIds = [userProfile?.id, selectedPlayer?.id];
    const newTeamData: any = {
      tournamentId: tournament?.id,
      categoryId: chosenCategory?.id,
      userIds: userIds,
    };

    try {
      const teamCreated = await createNewTeam(newTeamData);

      refetchTournament();
      refetchPlayerTeam();
      if (teamCreated) {
        toast({
          title: translate('TEAM_SUCCESSFULLY_CREATED_TITLE'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }

      setNewCreatedTeam(teamCreated);
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const playerOne = playerTeam?.players?.find(
    (player: any) => player.userId === userProfile?.id,
  );
  const playerTwo = playerTeam?.players?.find(
    (player: any) => player.userId !== userProfile?.id,
  );

  const handleTournamentCheckout = () => {
    if (
      !tournament?.id ||
      !tournament?.tournamentImageUrl ||
      !tournament?.priceCents
    )
      return null;
    if (userProfile && newCreatedTeam) {
      handlePayment({
        userEmail: userProfile?.email,
        tournamentId: tournament?.id,
        currency: 'eur',
        playerOneTitle: translate('TOURNAMENT_REGISTRATION_PAYMENT_TITLE', {
          tournamentName: tournament?.name,
          playerName: playerOne?.user.displayName,
        }),
        playerTwoTitle: translate('TOURNAMENT_REGISTRATION_PAYMENT_TITLE', {
          tournamentName: tournament?.name,
          playerName: playerTwo?.user.displayName,
        }),
        imageUrl: tournament?.tournamentImageUrl,
        tounamentPriceCents: tournament?.priceCents,
        teamId: newCreatedTeam?.id,
      });
    }
  };

  return (
    <ResponsiveModal isOpen={isModalOpen} onClose={handleOnClose} size="xl">
      {!chosenCategory && !categoryChosen && (
        <>
          <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
            {!playerProfile?.elo || playerProfile?.elo === 0
              ? translate('ELO_MISSING_TITLE')
              : translate('CHOOSE_CATEGORY_TITLE')}
          </Heading>

          <Flex flexDirection="column" mt={5}>
            {(!playerProfile?.elo || playerProfile?.elo === 0) && (
              <Button
                backgroundColor="secondary.default"
                onClick={() => navigate('/dashboard')}
                color="white"
                _hover={{
                  backgroundColor: 'none',
                }}
              >
                {translate('UPDATE_ELO_TITLE')}
              </Button>
            )}
            {tournament &&
              tournament?.tournamentCategories.length > 0 &&
              tournament?.tournamentCategories.map(
                (category: any, index: number) => {
                  return (
                    <ChooseTournamentCategory
                      key={index}
                      category={category}
                      tournament={tournament}
                      handleChosenCategory={handleChangeCategory}
                    />
                  );
                },
              )}
          </Flex>
        </>
      )}

      {!newCreatedTeam && (chosenCategory || categoryChosen) && (
        <>
          <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
            {translate('CHOOSE_PARTNER_TITLE')}
          </Heading>
          <Flex>
            <Text color="secondary.default" mt={3}>
              <CustomMarkdown
                text={translate('WARNING_MESSAGE_PARTNER_ELO_TOURNAMENT', {
                  categoryMinElo: chosenCategory?.minElo,
                  categoryMaxElo: chosenCategory?.maxElo,
                  eloPlayer: playerProfile?.elo,
                  minEloPartner: partnerEloBoundaries?.minEloPartner,
                  maxEloPartner: partnerEloBoundaries?.maxEloPartner,
                })}
              />
            </Text>
          </Flex>
          <Flex flexDirection="column" mt={5}>
            <Input
              type="text"
              color="white"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={translate('SEARCH_PLAYER_PLACEHOLDER')}
            />
          </Flex>

          {isSearchLoading && (
            <Flex mt={5}>
              <SkeletonLoading />
            </Flex>
          )}

          {!isSearchLoading && !searchPlayers?.length && input && (
            <Flex justifyContent="center" mt={3} color="orange">
              {translate('PLAYER_NOT_FOUND_TITLE')}
            </Flex>
          )}

          {!newCreatedTeam && searchPlayers && searchPlayers?.length > 0 && (
            <Flex mt={5} w="full" flexDirection="column">
              {searchPlayers.map((player: any, index) => {
                if (player?.id === userProfile?.id) return;
                return (
                  <Flex
                    key={index}
                    w="full"
                    color="white"
                    borderRadius={5}
                    borderWidth={1}
                    p={3}
                    borderColor="#2F384C"
                    alignItems="center"
                    mt={3}
                    cursor="pointer"
                    onClick={() => handleSelectPlayer(player)}
                  >
                    <Radio isChecked={selectedPlayer === player} />
                    <Text ml={5} mr={3} mt={1}>
                      {`${player?.displayName} (${player?.playerProfile?.elo})`}
                    </Text>
                    {player?.gender && (
                      <FontAwesomeIcon
                        icon={player?.gender === 'MEN' ? faMars : faVenus}
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          )}
        </>
      )}

      {!newCreatedTeam && (chosenCategory || categoryChosen) && (
        <Flex w="full" justifyContent="flex-end" mt={10}>
          <Button
            backgroundColor="#384257"
            color="white"
            w={100}
            _hover={{
              backgroundColor: 'none',
            }}
            mr={5}
            onClick={() => {
              setChosenCategory(null);
              clearCategoryChosen();
            }}
          >
            {translate('BACK_TITLE')}
          </Button>
          <Button
            w={100}
            backgroundColor="secondary.default"
            color="white"
            isDisabled={!selectedPlayer}
            _hover={{
              backgroundColor: 'none',
            }}
            onClick={handleAddPartner}
          >
            {translate('CONFIRM')}
          </Button>
        </Flex>
      )}

      {newCreatedTeam && (
        <Flex flexDirection="column">
          <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
            {translate('PAYMENT_TITLE')}
          </Heading>
          <Text color="textColor.default" mt={2}>
            {translate('PAYMENT_SUBTITLE')}
          </Text>

          <Button
            mt={5}
            w="full"
            backgroundColor="secondary.default"
            color="white"
            _hover={{
              backgroundColor: 'none',
            }}
            onClick={handleTournamentCheckout}
            isLoading={isTournamentCheckoutPaymentLoading}
          >
            {translate('FINISH_REGISTERING_TITLE')}
          </Button>
        </Flex>
      )}
    </ResponsiveModal>
  );
};
