import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from '@chakra-ui/react';
import Select from 'react-select';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from '../../../../../translations/useTranslations';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { TournamentTeam } from '../../../../formats/types/tournament.types';
import { useEffect, useState } from 'react';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import { handleBackendError } from '../../../../common/services/handleBackendError';
import {
  TournamentCategory,
  TournamentPool,
} from '../../../types/adminTournamentCategory.types';
import DateTimePicker from '../../../common/components/DateTimePicker';

export type Inputs = {
  name: string;
  teams?: { label: string; team: TournamentTeam; value: number }[];
};

interface EditCategoryPoolModalProps {
  isOpen: boolean;
  onClose: () => void;
  chosenCategory: TournamentCategory;
  tournamentId: number;
  pool?: TournamentPool;
}

export const EditCategoryPoolModal = ({
  isOpen,
  onClose,
  chosenCategory,
  tournamentId,
  pool,
}: EditCategoryPoolModalProps) => {
  const { translate } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const {
    updatePool,
    isUpdatePoolLoading,
    tournamentCategoryPoolsList,
    refetchTournamentCategoryPoolsList,
  } = useAdminTournaments(tournamentId, chosenCategory?.id);
  const toast = useToast();
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      name: pool?.name || '',
      teams: tournamentCategoryPoolsList,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const teams = data?.teams?.map((element: any) => element.team);

    try {
      await updatePool({
        poolId: pool?.id,
        updatedPool: {
          name: data.name,
          startDate: startDateTime,
          endDate: endDateTime,
          teams,
        },
      });
      toast({
        title: translate('POOL_EDITED_SUCCESSFULLY'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await refetchTournamentCategoryPoolsList();
      handleClose();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  useEffect(() => {
    if (pool) {
      reset({
        name: pool.name,
        teams: [],
      });

      const defaultTeams = pool?.teams?.map((team) => ({
        value: team.id,
        label: `${team.players[0]?.user.displayName} / ${team.players[1]?.user.displayName}`,
        team: team,
      }));

      //@ts-ignore
      setValue('teams', defaultTeams);
      setSelectedOptions(defaultTeams);
      setStartDateTime(pool?.startDate);
      setEndDateTime(pool?.endDate);
    }
  }, [isOpen]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const poolTeamsSet = new Set(
    tournamentCategoryPoolsList?.flatMap((pool: TournamentPool) =>
      pool?.teams?.map((t) => t.id),
    ),
  );

  const availableTeams: TournamentTeam[] | undefined =
    chosenCategory?.teams?.filter(
      (team: TournamentTeam) => !poolTeamsSet.has(team.id),
    );

  const options = availableTeams?.map((team: TournamentTeam) => {
    const label = `${team?.players?.[0]?.user?.displayName} / ${team?.players?.[1]?.user?.displayName}`;

    return {
      value: team?.id,
      label: label,
      team: team,
    };
  });

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
    setValue('teams', selected);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={handleClose} size="xl">
      <>
        <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
          {translate('EDIT_POULE_TITLE', {
            name: pool?.name,
            category: `${chosenCategory?.categoryGender} - ${chosenCategory?.categoryRanking}`,
          })}
        </Heading>
        <form>
          <Flex w="full">
            <FormControl>
              <FormLabel>{translate('NAME')}</FormLabel>
              <Input
                color="white"
                placeholder="Enter pool name"
                {...register('name', { required: true })}
              />
              {errors.name && (
                <Flex color="red">{translate('FIELD_REQUIRED')}</Flex>
              )}
            </FormControl>
          </Flex>
          <Flex my={5} flexDirection="column">
            <DateTimePicker
              title={translate('START_TIME')}
              initialDate={pool?.startDate}
              onDateChange={(date) => setStartDateTime(date)}
            />
            <DateTimePicker
              title={translate('END_TIME')}
              initialDate={pool?.endDate}
              onDateChange={(date) => setEndDateTime(date)}
            />
          </Flex>
          <Flex mt={5}>
            <FormControl>
              <FormLabel color="white">{translate('TEAMS_TITLE')}</FormLabel>
              <Select
                {...register('teams')}
                options={options}
                onChange={handleChange}
                isOptionDisabled={() => selectedOptions.length >= 4}
                isMulti
                value={selectedOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'grey' : 'grey',
                    backgroundColor: 'transparent',
                  }),

                  option: (styles, { isSelected }) => ({
                    ...styles,
                    color: isSelected ? 'white' : 'black',
                  }),
                  input: (styles) => ({
                    ...styles,
                    color: 'white', // Set the input text color to white
                  }),
                }}
              />
            </FormControl>
          </Flex>
        </form>

        <Flex mt={10}>
          <Button
            w="full"
            mr={2}
            onClick={handleClose}
            isLoading={isUpdatePoolLoading}
          >
            {translate('CANCEL')}
          </Button>
          <Button
            w="full"
            ml={2}
            backgroundColor="secondary.default"
            color="white"
            _hover={{
              backgroundColor: 'secondary.default',
              color: 'white',
            }}
            onClick={handleSubmit(onSubmit)}
            isLoading={isUpdatePoolLoading}
          >
            {translate('EDIT_POOL')}
          </Button>
        </Flex>
      </>
    </ResponsiveModal>
  );
};
