import { useMutation, useQuery } from 'react-query';
import { get, put } from '../../common/services/HttpMethods';
import { PlayerProfile } from '../../user/types/player.types';

export const usePlayerProfile = (userId?: number | undefined) => {
  const playerProfileQuery = useQuery<PlayerProfile>(
    ['playerProfile', userId],
    async (): Promise<PlayerProfile> => get(`/players/${userId}`),
    {
      enabled: !!userId,
    },
  );

  const updatePlayerProfileMutation = useMutation(
    (updatedPlayer: any): Promise<PlayerProfile> =>
      put('/players', updatedPlayer),
  );

  return {
    playerProfile: playerProfileQuery.data,
    isPlayerProfileLoading: playerProfileQuery.isLoading,
    playerProfileError: playerProfileQuery.error,
    updatePlayerProfile: updatePlayerProfileMutation.mutateAsync,
    isUpdatePlayerProfileLoading: updatePlayerProfileMutation.isLoading,
  };
};
