import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { AdminSideBar } from './components/AdminSideBar';
import { AdminSportCategories } from './components/sportCategories/AdminSportCategories';
import { AdminTournaments } from './components/tournaments/AdminTournaments';

export const Admin = () => {
  const [type, setType] = useState('');

  const handleChangeAdminSection = (type: string) => {
    setType(type);
  };

  return (
    <Flex w="100%">
      <AdminSideBar onClick={handleChangeAdminSection}>
        <Flex
          flex={1}
          flexDirection="column"
          pt={90}
          backgroundColor="background.default"
        >
          <Flex w="100%" p={5}>
            {type === 'padelTournaments' && <AdminTournaments />}
          </Flex>
          <Flex w="100%">
            {type === 'sportCategories' && <AdminSportCategories />}
          </Flex>
        </Flex>
      </AdminSideBar>
    </Flex>
  );
};
