import { Flex, Text } from '@chakra-ui/react';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { MatchStatus } from '../../../common/components/MatchStatus';
import { useAdminTournamentsMatches } from '../../../hooks/useAdminTournamentMatches';
import { Match } from '../../../types/adminTournament.types';
import { MatchModal } from './MatchModal';

export const MatchesPoolList = ({ poolId }: { poolId?: number | null }) => {
  const { poulesMatchesByIdList } = useAdminTournamentsMatches(poolId);
  const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);
  const [currentMatch, setCurrenMatch] = useState<Match | null>(null);
  const [matchNumber, setMatchNumber] = useState<number>(0);
  const { translate } = useTranslation();

  return (
    <Flex mt={5} flexDirection="column">
      <Flex>
        <Text color="white" fontWeight="bold" fontSize="3xl">
          {translate('NUMBER_OF_MATCHES', {
            number: poulesMatchesByIdList?.length,
          })}
        </Text>
      </Flex>
      <Flex flexDirection="column" w="full">
        {poulesMatchesByIdList?.map((match: Match, index: number) => {
          const teamA = match?.teamA;
          const teamB = match?.teamB;
          return (
            <Flex
              borderTopWidth={1}
              borderBottomWidth={1}
              borderTopColor="background.fourth"
              borderBottomColor="background.fourth"
              minH={100}
              p={3}
              w="full"
              cursor="pointer"
              _hover={{
                backgroundColor: 'background.fourth',
              }}
              onClick={() => {
                setIsMatchModalOpen(true);
                setCurrenMatch(match);
                setMatchNumber(index + 1);
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                w="20%"
              >
                <Text color="white">
                  {translate('MATCH_NUMBER', {
                    number: index + 1,
                  })}
                </Text>
                <Flex color="white">
                  <MatchStatus status={match?.matchStatus} />
                </Flex>
              </Flex>
              <Flex
                w="60%"
                borderLeftWidth={1}
                borderRightWidth={1}
                borderLeftColor="background.fourth"
                borderRightColor="background.fourth"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                pl={5}
              >
                <Flex>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${teamA?.players?.[0]?.user?.displayName} / ${teamA?.players?.[1]?.user?.displayName}`}</Text>
                </Flex>
                <Flex>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${teamB?.players?.[0]?.user?.displayName} / ${teamB?.players?.[1]?.user?.displayName}`}</Text>
                </Flex>
              </Flex>
              <Flex w="20%" justifyContent="flex-end" alignItems="center">
                <FontAwesomeIcon icon={faPenToSquare} size="lg" color="white" />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <MatchModal
        isOpen={isMatchModalOpen}
        onClose={() => setIsMatchModalOpen(false)}
        matchNumber={matchNumber}
        match={currentMatch}
      />
    </Flex>
  );
};
