import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from '../../../../translations/useTranslations';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import { DrawerComponent } from '../../common/components/Drawer';
import { useAdminTournaments } from '../../hooks/useAdminTournaments';
import { TournamentCategory } from '../../types/adminTournamentCategory.types';
import { FinalPhase } from './tournamentFinal/FinalePhase';
import { RegisteredPlayersByCategory } from './tournamentPlayers/RegisteredPlayersByCategory';
import { PoolPhase } from './tournamentPool/PoolPhase';
import { PoolPhaseMatches } from './tournamentPool/PoolPhaseMatches';

export type TournamentInputs = {
  name: string;
  location: string;
};

export const EditTournament = ({ isOpen, close, tournamentId }: any) => {
  const { translate } = useTranslation();
  const [chosenCategory, setChosenCategory] = useState<TournamentCategory>();
  const { tournament } = useAdminTournaments(tournamentId, chosenCategory?.id);

  const categoriesList = tournament?.tournamentCategories ?? [];

  useEffect(() => {
    //SET FIRST CATEGORY AS DEFAULT SELECTED
    setChosenCategory(categoriesList[0]);
  }, [tournament]);

  const {
    register,
    reset,
    // handleSubmit,
    formState: { errors },
  } = useForm<TournamentInputs>();

  const handleSetChosenCategory = (category: TournamentCategory) => {
    setChosenCategory(category);
  };

  return (
    <DrawerComponent
      title={translate('EDIT_TOURNAMENT', {
        tournamentName: tournament?.name,
      })}
      isOpen={isOpen}
      isLoading={false}
      onClose={() => {
        reset();
        close();
      }}
      handleSubmit={() => console.log('submit')}
      size="full"
    >
      <form>
        <Stack direction={['column', 'row']} spacing="24px">
          <FormControl>
            <FormLabel color="textColor.default">
              {translate('TOURNAMENT_NAME')}
            </FormLabel>
            <Input
              color="textColor.default"
              defaultValue={tournament?.name}
              {...register('name', { required: true })}
            />
            {errors.name && (
              <Flex color="red">{translate('FIELD_REQUIRED')}</Flex>
            )}
          </FormControl>
          <FormControl>
            <FormLabel color="textColor.default">
              {translate('LOCATION')}
            </FormLabel>
            <Input
              color="textColor.default"
              defaultValue={tournament?.location}
              {...register('location', { required: true })}
            />
            {errors.location && (
              <Flex color="red">{translate('FIELD_REQUIRED')}</Flex>
            )}
          </FormControl>
        </Stack>
      </form>
      <Flex w="full" mt={10} mb={10} borderWidth={1} />
      <TournamentCategories
        tournamentId={tournamentId}
        setChosenCategory={handleSetChosenCategory}
      />
      <PoolPhase chosenCategory={chosenCategory} tournamentId={tournamentId} />
      <PoolPhaseMatches
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
      />
      <FinalPhase chosenCategory={chosenCategory} tournamentId={tournamentId} />
      <RegisteredPlayersByCategory
        tournamentId={tournamentId}
        chosenCategory={chosenCategory}
      />
    </DrawerComponent>
  );
};

const TournamentCategories = ({
  tournamentId,
  setChosenCategory,
}: {
  tournamentId: number;
  setChosenCategory: (category: TournamentCategory) => void;
}) => {
  const { translate } = useTranslation();
  const { tournament } = useAdminTournaments(Number(tournamentId));
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const { isMobile } = useMediaQuery();

  const categoriesList = tournament?.tournamentCategories ?? [];

  const handleCategoryClick = (index: number) => {
    setChosenCategory(categoriesList[index]);
    setSelectedCategory(index);
  };
  return (
    <Flex overflow="scroll" justifyContent="center">
      <HStack
        spacing="15px"
        wrap={isMobile ? 'nowrap' : 'wrap'}
        w="fit-content"
        align="stretch"
      >
        {categoriesList.map((category: any, index: number) => (
          <Flex
            key={`cat-${category.id}`}
            borderWidth={1}
            borderColor={
              index === selectedCategory ? 'secondary.default' : '#2F384C'
            }
            color={
              index === selectedCategory
                ? 'secondary.default'
                : 'textColor.default'
            }
            p={3}
            borderRadius={5}
            flexDirection="column"
            cursor="pointer"
            w={150}
            _hover={{
              borderColor: 'secondary.default',
              color: 'secondary.default',
            }}
            onClick={() => handleCategoryClick(index)}
          >
            <Text fontSize="0.8em" textAlign="center">
              {`${translate(category.categoryGender)} - ${
                category.categoryRanking
              }`}
            </Text>
            <Text fontSize="0.7em" textAlign="center">
              {`[${category.minElo} - ${category.maxElo}]`}
            </Text>
          </Flex>
        ))}
      </HStack>
    </Flex>
  );
};
