import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  get,
  post,
  getAuthTokenFromLocalStorage,
  removeAuthtokenFromLocaleStorage,
  setAuthTokenLocaleStorage,
} from '../../common/services/HttpMethods';

export const useAuth = () => {
  const [authToken, setAuthToken] = useState(getAuthTokenFromLocalStorage());
  const queryClient = useQueryClient();

  const handleLoginSuccess = async (data: any) => {
    // Assume the token is in the response data, adjust according to your API response
    const token = data.accessToken;
    setAuthTokenLocaleStorage(token);
    setAuthToken(token);
    // Invalidate the userProfile query to trigger a refetch
    queryClient.invalidateQueries('userProfile');
  };

  const handleLogoutSuccess = () => {
    removeAuthtokenFromLocaleStorage();
    setAuthToken(null);
    // Invalidate the userProfile query on logout
    queryClient.invalidateQueries('userProfile');
  };

  const loginMutation = useMutation((user) => post('/auth/login', user), {
    onSuccess: handleLoginSuccess,
  });

  const registerMutation = useMutation(
    (newUser) => post('/auth/register', newUser),
    {
      onSuccess: handleLoginSuccess,
    },
  );

  const logoutMutation = useMutation(() => get('/auth/logout'), {
    onSuccess: handleLogoutSuccess,
  });

  const isAuthenticated = authToken ? true : false;

  return {
    isAuthenticated,
    authToken,
    handleLoginSuccess,
    logIn: loginMutation.mutateAsync,
    isLoginLoading: loginMutation.isLoading,
    registerUser: registerMutation.mutateAsync,
    isRegisterUserLoading: registerMutation.isLoading,
    logOut: logoutMutation.mutateAsync,
    isLogoutLoading: logoutMutation.isLoading,
  };
};
