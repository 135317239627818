import { useToast } from '@chakra-ui/react';
import { useTranslation } from '../../../translations/useTranslations';
import { handleBackendError } from '../../common/services/handleBackendError';
import { usePayment } from '../hooks/usePayment';

interface TournamentCheckoutProps {
  userEmail: string;
  tournamentId: number;
  currency: string;
  playerOneTitle: string;
  playerTwoTitle: string;
  imageUrl: string;
  tounamentPriceCents: number;
  teamId: number;
}

export const useTournamentCheckout = () => {
  const { translate } = useTranslation();
  const { tournamentCheckoutPayment, isTournamentCheckoutPaymentLoading } =
    usePayment();
  const toast = useToast();

  const handlePayment = async ({
    userEmail,
    tournamentId,
    currency,
    playerOneTitle,
    playerTwoTitle,
    imageUrl,
    tounamentPriceCents,
    teamId,
  }: TournamentCheckoutProps) => {
    try {
      const { url } = await tournamentCheckoutPayment({
        userEmail,
        tournamentId,
        currency,
        playerOneTitle,
        playerTwoTitle,
        imageUrl,
        tounamentPriceCents,
        teamId,
      });

      if (url) window.location.href = url;
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  return {
    handlePayment,
    isTournamentCheckoutPaymentLoading,
  };
};
