import { Flex, Heading } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { useTranslation } from '../../../../translations/useTranslations';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import GenericTable from '../../common/components/GenericTable';
import { useAdminTournaments } from '../../hooks/useAdminTournaments';
import { getAmountWithCurrency } from '../../../../utils/utils';
import { useUserProfile } from '../../../user/hooks/useUserProfile';
import { useAuthContext } from '../../../../contexts/AuthProvider';
import { EditTournament } from './EditTournament';

const columnHelper = createColumnHelper<TournamentColumnType>();

export type TournamentColumnType = {
  id: number;
  name: string;
  location: string;
  priceCents: number;
  isOpen: boolean;
  actions?: ReactElement;
};

export const AdminTournaments = () => {
  const [isEditTournamentOpen, setEditTournament] = useState(false);
  const { tournamentsList } = useAdminTournaments();
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const [tournamentId, setTournamentId] = useState<number | null>(null);

  const { translate } = useTranslation();

  const columns: ColumnDef<TournamentColumnType, any>[] = [
    columnHelper.accessor('name', {
      header: () => translate('NAME'),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('location', {
      header: () => translate('LOCATION'),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('priceCents', {
      header: () => translate('PRICE'),
      cell: (info) =>
        getAmountWithCurrency(
          info.renderValue(),
          userProfile?.locale || 'en-US',
          'EUR',
        ),
    }),
    columnHelper.accessor('isOpen', {
      header: () => translate('STATUS'),
      cell: (info) =>
        info.renderValue() ? translate('OPEN') : translate('CLOSED'),
    }),

    columnHelper.display({
      id: 'actions',
      header: () => (
        <Flex justifyContent="flex-end">{translate('ACTIONS')}</Flex>
      ),
      cell: ({ row }) => {
        return (
          <Flex justifyContent="flex-end">
            <EditIcon
              mr={5}
              cursor="pointer"
              onClick={() => {
                setTournamentId(row.original.id);
                // setEditingCategory({
                //   id: row.original.id,
                //   name: row.original.name,
                //   sportId: row.original.sport.id,
                // });
                setEditTournament(true);
              }}
            />
            <DeleteIcon
              cursor="pointer"
              color="red"
              // onClick={() => setDeleteCategoryId(row.original.id)}
            />
            {/* <DeleteModal
              isOpen={deleteCategoryId !== null}
              onClose={() => setDeleteCategoryId(null)}
              onConfirm={() => handleDeleteCategory(deleteCategoryId as number)}
              title={translate('DELETE_CATEGORY_TITLE')}
              content={translate('DELETE_CATEGORY_CONTENT')}
            /> */}
          </Flex>
        );
      },
    }),
  ];

  return (
    <Flex w="full" flexDirection="column">
      <Flex justifyContent="space-between">
        <Heading
          fontSize={{ base: '3xl', md: '5xl' }}
          color="secondary.default"
        >
          {translate('TOURNAMENTS')}
        </Heading>
        {/* <Button
          bg={'secondary.default'}
          color={'white'}
          _hover={{
            bg: 'secondary.default',
          }}
          onClick={() => setIsDrawerOpen(true)}
        >
          {translate('ADD_TOURNAMENT')}
        </Button> */}
      </Flex>
      <GenericTable
        data={tournamentsList || []}
        columns={columns}
        // isLoading={isSportsCategoriesListLoading}
      />
      <EditTournament
        isOpen={isEditTournamentOpen}
        close={() => setEditTournament(false)}
        tournamentId={tournamentId}
      />
    </Flex>
  );
};
