import React, { ReactNode } from 'react';
import { Flex, Tooltip, useDisclosure } from '@chakra-ui/react';

export const CustomTooltip = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
      setTimeout(onClose, 3000);
    }
  };

  const handleMouseEnter = () => {
    onOpen();
    setTimeout(onClose, 3000);
  };

  return (
    <Tooltip label={title} isOpen={isOpen} placement="top" color="white">
      <Flex
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onClose}
      >
        {children}
      </Flex>
    </Tooltip>
  );
};
