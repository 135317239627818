import { Spinner } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const SpinnerWrapper = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: ReactNode;
}) => {
  if (isLoading)
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="secondary.default"
      />
    );
  return <>{children}</>;
};
