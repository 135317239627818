import { Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { Divider } from '@chakra-ui/react';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import { KwickersMasterList } from './KwickersMastersList';

export const KwickersMasters = () => {
  const { translate } = useTranslation();
  const { isMobile } = useMediaQuery();
  return (
    <>
      <Flex flexDirection="column" marginY={10}>
        <Heading
          fontSize={{ base: '2xl', sm: '3xl' }}
          fontWeight={'bold'}
          color="secondary.default"
          as="h2"
          textAlign={isMobile ? 'center' : 'start'}
        >
          {translate('KWICKERS_MASTER_SUBTITLE')}
        </Heading>

        {/* <Flex mt={5}>
          <Text
            color="textColor.default"
            fontSize={'xl'}
            as="h3"
            textAlign={isMobile ? 'center' : 'start'}
          >
            {translate('KWICKERS_TOUR_DESCRIPTION')}
          </Text>
        </Flex> */}
      </Flex>
      <Divider mb={10} />
      <KwickersMasterList />
    </>
  );
};
