import { useMutation, useQuery } from 'react-query';
import { get, post } from '../../common/services/HttpMethods';
import {
  PlayerTeam,
  Tournament,
  TournamentTeam,
} from '../types/tournament.types';

export const useTournaments = (tournamentId?: number, playerId?: number) => {
  const tournamentsListQuery = useQuery<any>(
    ['tournamentsList'],
    async (): Promise<Tournament[]> => get(`/tournaments`),
    {
      staleTime: 50000,
    },
  );

  const tournamentQuery = useQuery<Tournament>(
    [`tournamment-${tournamentId}`],
    async (): Promise<any> => get(`/tournaments/${tournamentId}`),
    {
      staleTime: 50000,
      enabled: !!tournamentId,
    },
  );

  const createNewTeamMutation = useMutation(
    (newTeamData: {
      tournamentId: number;
      categoryId: number;
      playerIds: number[];
    }): Promise<TournamentTeam> => post('/tournaments/team', newTeamData),
  );

  const tournamentPlayerTeamQuery = useQuery<PlayerTeam>(
    [`tournamment-${tournamentId}-player-${playerId}-team`],
    async (): Promise<any> =>
      get(`/tournaments/${tournamentId}/player/${playerId}/team`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!playerId,
    },
  );

  return {
    tournamentsList: tournamentsListQuery.data,
    isTournamentListLoading: tournamentsListQuery.isLoading,
    tournament: tournamentQuery.data,
    isTournamentLoading: tournamentQuery.isLoading,
    refetchTournament: tournamentQuery.refetch,
    createNewTeam: createNewTeamMutation.mutateAsync,
    isCreatingTeam: createNewTeamMutation.isLoading,
    playerTeam: tournamentPlayerTeamQuery.data,
    isPlayerTeamLoading: tournamentPlayerTeamQuery.isLoading,
    refetchPlayerTeam: tournamentPlayerTeamQuery.refetch,
  };
};
