import { Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from '../../../../../../translations/useTranslations';
import { SpinnerLoading } from '../../../../../common/components/SpinnerLoading';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import { useTournamentsPools } from '../../../../hooks/useTournamentPool';
import { TournamentPool } from '../../../../types/tournamentCategory.types';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { TournamentTeam } from '../../../../types/tournament.types';
import { SkeletonWrapper } from '../../../../../common/components/SkeletonWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCircle,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';
import { PoolMatchList } from './PoolMatchList';
import { CustomTooltip } from '../../../../../common/components/CustomTooltip';

export const CategoryPoolsList = ({
  chosenCategory,
  tournamentId,
}: {
  chosenCategory: any;
  tournamentId: number;
}) => {
  const { translate } = useTranslation();

  const { tournamentCategoryPoolsList, isTournamentPoolsCategoryListLoading } =
    useTournamentsPools({
      tournamentId: Number(tournamentId),
      categoryId: Number(chosenCategory?.id),
    });

  return (
    <Flex w="full" overflowX="hidden" flexDirection="column" mt={10}>
      <Stack direction={['column', 'row']} w="full">
        <Flex w="full" flexDirection="column">
          {isTournamentPoolsCategoryListLoading ? (
            <SpinnerLoading />
          ) : (
            !tournamentCategoryPoolsList ||
            (tournamentCategoryPoolsList.length === 0 && (
              <Flex justifyContent="center" color="textColor.default">
                {translate('POOLS_NOT_AVAILABLE_YET')}
              </Flex>
            ))
          )}
          <Stack
            direction={['column', 'row']}
            spacing="24px"
            flexWrap="wrap"
            justifyContent="center"
          >
            {tournamentCategoryPoolsList?.map(
              (pool: TournamentPool, index: number) => {
                return (
                  <PoolCard
                    tournamentId={tournamentId}
                    pool={pool}
                    index={index}
                  />
                );
              },
            )}
          </Stack>
        </Flex>
      </Stack>
    </Flex>
  );
};

const PoolCard = ({
  tournamentId,
  pool,
  index,
}: {
  tournamentId: number;
  pool: TournamentPool;
  index: number;
}) => {
  const { translate } = useTranslation();
  const { isDesktop } = useMediaQuery();
  const [poolId, setPoolId] = useState<number | null>(null);
  const [view, setView] = useState<'teams' | 'results'>('teams');
  const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);

  const startDate = pool?.startDate;
  const endDate = pool?.endDate;

  const { tournamentPoolRankingList, isTournamentPoolRankingListLoading } =
    useTournamentsPools({
      tournamentId: Number(tournamentId),
      poolId: Number(poolId),
    });

  const handleCheckRankings = (poolId: number) => {
    setPoolId(poolId);
  };

  const handleLeftClick = () => {
    handleCheckRankings(pool?.id);
    setView('results');
  };

  const handleRightClick = () => {
    setView('teams');
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleLeftClick,
    onSwipedRight: handleRightClick,
    // @ts-ignore
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleShowMatches = () => {
    setIsMatchModalOpen(true);
  };

  return (
    <Flex
      key={index}
      color="white"
      minW={isDesktop ? 400 : 'auto'}
      borderRadius={10}
      p={4}
      flexDirection="column"
      backgroundColor="background.secondary"
      position="relative"
    >
      <Flex
        position="absolute"
        right={3}
        top={3}
        backgroundColor="background.third"
        p={3}
        borderRadius={10}
        _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
        onClick={handleShowMatches}
        zIndex={10}
      >
        <FontAwesomeIcon
          icon={faTrophy}
          color="#FDCC26"
          size="sm"
          onClick={handleShowMatches}
        />
      </Flex>
      <Flex justifyContent="space-between" position="relative">
        {startDate && endDate && (
          <Text textAlign="center" fontSize="xl" fontWeight="bold" w="full">
            {translate('POOL_NAME', {
              name: pool?.name,
            })}
          </Text>
        )}
      </Flex>

      {startDate && endDate && (
        <Flex justifyContent="space-between" position="relative">
          <Text textAlign="center" fontWeight="bold" w="full">
            {`${DateTime.fromISO(startDate).toFormat(
              'HH:mm',
            )} - ${DateTime.fromISO(endDate).toFormat('HH:mm')}`}
          </Text>
        </Flex>
      )}

      <Flex w="100%" {...swipeHandlers}>
        {view === 'teams' ? (
          <PoolTeamsList pool={pool} />
        ) : (
          <PoolTeamRankings
            tournamentPoolRankingList={tournamentPoolRankingList}
            isLoading={isTournamentPoolRankingListLoading}
          />
        )}
      </Flex>
      <Flex justifyContent="space-between">
        <Flex
          backgroundColor="background.third"
          p={3}
          borderRadius={10}
          _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
          onClick={handleRightClick}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="sm" />
        </Flex>

        <Flex alignItems="center">
          <Flex>
            <FontAwesomeIcon
              icon={view === 'teams' ? faCircle : faCircleEmpty}
              width={5}
            />
          </Flex>
          <Flex ml={2}>
            <FontAwesomeIcon
              icon={view === 'results' ? faCircle : faCircleEmpty}
              width={5}
            />
          </Flex>
        </Flex>

        <Flex
          backgroundColor="background.third"
          p={3}
          borderRadius={10}
          _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
          onClick={handleLeftClick}
        >
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </Flex>
      </Flex>
      <PoolMatchList
        isOpen={isMatchModalOpen}
        onClose={() => setIsMatchModalOpen(false)}
        tournamentId={tournamentId}
        pool={pool}
      />
    </Flex>
  );
};

const PoolTeamsList = ({ pool }: { pool: TournamentPool }) => {
  const { translate } = useTranslation();
  return (
    <Flex flexDirection="column" w="100%" maxW={500}>
      <Flex justifyContent="space-between" borderBottomWidth={1}>
        <Text color="secondary.default" fontWeight="bold">
          {translate('TEAMS_TITLE')}
        </Text>
      </Flex>
      <Flex flexDirection="column">
        {pool.teams?.map((team: TournamentTeam, index: number) => {
          const playerOne = team?.players?.[0];
          const playerTwo = team?.players?.[1];
          const playerEloOneAtRegistration =
            team?.playersEloAtRegistration?.find(
              (el) => el?.playerId === playerOne?.id,
            );

          const playerEloTwoAtRegistration =
            team?.playersEloAtRegistration?.find(
              (el) => el?.playerId === playerTwo?.id,
            );

          return (
            <Flex key={index} py={3} justifyContent="space-between">
              <Flex flexDirection="column">
                <Flex color="textColor.default">
                  {`${playerOne?.user?.displayName}`}
                  {playerEloOneAtRegistration &&
                    ` (${playerEloOneAtRegistration.elo})`}
                </Flex>
                <Flex color="textColor.default">
                  {`${playerTwo?.user?.displayName}`}
                  {playerEloTwoAtRegistration &&
                    ` (${playerEloTwoAtRegistration.elo})`}
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <Text fontWeight="bold" color="secondary.default" fontSize="xl">
                  {team?.eloTeam}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

const PoolTeamRankings = ({ tournamentPoolRankingList, isLoading }: any) => {
  const { translate } = useTranslation();

  return (
    <Flex
      color="white"
      w="100%"
      maxW={500}
      height="100%"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" borderBottomWidth={1}>
        <Text color="secondary.default" fontWeight="bold">
          {translate('RANKING_POOL_TITLE')}
        </Text>
      </Flex>
      <Flex flexDirection="column" mt={2}>
        <SkeletonWrapper
          isLoading={isLoading}
          count={4}
          skeleton={customSkeletonWrapper}
        >
          {tournamentPoolRankingList?.map(
            (teamPoolStats: any, index: number) => {
              const playerOne = teamPoolStats?.team?.players?.[0];
              const playerTwo = teamPoolStats?.team?.players?.[1];

              return (
                <Flex
                  key={index}
                  justifyContent="space-between"
                  flexDirection="column"
                  marginY={2}
                >
                  <Flex
                    flexDirection="column"
                    backgroundColor="background.third"
                    pl={3}
                    borderRightRadius={999}
                    borderLeftRadius={10}
                    position="relative"
                  >
                    <Flex color="white">{`${playerOne?.user?.displayName}`}</Flex>
                    <Flex color="white">{`${playerTwo?.user?.displayName}`}</Flex>
                    <Flex
                      position="absolute"
                      right={5}
                      top={1}
                      fontSize="3xl"
                      fontWeight="bold"
                      color={
                        index === 0 || index === 1
                          ? 'secondary.default'
                          : 'none'
                      }
                    >
                      {index + 1}
                    </Flex>
                  </Flex>

                  <Flex mt={3} justifyContent="space-between" px={5}>
                    <CustomTooltip
                      title={translate('MATCHES_WON_TITLE_TOOLTIP')}
                    >
                      <TeamStatCell
                        icon="🏆"
                        value={teamPoolStats?.matchesWon}
                      />
                    </CustomTooltip>
                    <CustomTooltip
                      title={translate('MATCHES_LOST_TITLE_TOOLTIP')}
                    >
                      <TeamStatCell
                        icon="😞"
                        value={teamPoolStats?.matchesLost}
                      />
                    </CustomTooltip>

                    <CustomTooltip title={translate('GAMES_WON_TITLE_TOOLTIP')}>
                      <TeamStatCell icon="💪" value={teamPoolStats?.gamesWon} />
                    </CustomTooltip>
                    <CustomTooltip
                      title={translate('GAMES_LOST_TITLE_TOOLTIP')}
                    >
                      <TeamStatCell
                        icon="😢"
                        value={teamPoolStats?.gamesLost}
                      />
                    </CustomTooltip>
                    {/* <TeamStatCell
                      icon="😮‍💨"
                      value={teamPoolStats?.tiebreaksPointsWon}
                    />
                    <TeamStatCell
                      icon="🫠"
                      value={teamPoolStats?.tiebreaksPointsLost}
                    /> */}
                  </Flex>
                </Flex>
              );
            },
          )}
        </SkeletonWrapper>
      </Flex>
    </Flex>
  );
};

const TeamStatCell = ({ icon, value }: any) => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" flexDirection="column">
        <Text textAlign="center">{icon}</Text>
      </Flex>
      <Text textAlign="center" fontWeight="bold">
        {value}
      </Text>
    </Flex>
  );
};

const customSkeletonWrapper = (
  <Flex flexDirection="column" mt={5} w="full">
    <Skeleton
      height="50"
      startColor="gray.600"
      endColor="gray.700"
      borderRadius={10}
      width="100%"
    />
  </Flex>
);
