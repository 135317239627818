import { DateTime } from 'luxon';

export const getFormattedDate = (
  date: string | undefined,
  locale: string,
  isDesktop: boolean,
) => {
  if (!date) return null;

  const formattedDate = DateTime.fromISO(date, {
    zone: 'utc',
  });

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    year: '2-digit',
    month: '2-digit',
  };

  if (isDesktop) {
    options.weekday = 'long';
    options.day = 'numeric';
    options.weekday = 'long';
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.hourCycle = 'h24';
  }

  return formattedDate.setLocale(locale).toLocaleString(options);
};

export const getDefaultCurrency = () => {
  return 'EUR';
  // switch (locale) {
  //   case 'en-US':
  //     return 'USD';
  //   default:
  //     return 'EUR'; // Fallback currency
  // }
};

export const getAmountWithCurrency = (
  amountCents: number | undefined,
  locale: string,
  currency: string,
) => {
  if (!amountCents || !locale || !currency) return null;
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amountCents / 100);

  return formattedAmount;
};

export const genderCheck = (
  userGender: 'MEN' | 'WOMEN',
  categoryGender: 'MEN' | 'WOMEN' | 'MIXED',
) => {
  if (!userGender || !categoryGender) return false;
  else if (categoryGender === 'MIXED') return true;
  else if (userGender !== categoryGender) return false;
  else return true;
};

export const eloCategoryCheck = (
  userElo: number,
  minEloCategory: number,
  maxEloCategory: number,
) => {
  if (userElo < minEloCategory || userElo > maxEloCategory) return false;
  else return true;
};

export const isRegistrationClosed = (targetDateStr: string | undefined) => {
  if (!targetDateStr) return true;
  // Convert the target date string to a Luxon DateTime object
  const targetDate = DateTime.fromISO(targetDateStr);

  // Get the current UTC DateTime to match the timezone of your target date
  const currentDate = DateTime.utc();

  return currentDate > targetDate;
};

export const checkCategoriesBoundaries = (
  playerElo: number | undefined,
  categoryMin: number,
  categoryMax: number,
) => {
  if (!playerElo) return false;

  if (playerElo < 1800 && categoryMin >= 1800) {
    return false;
  }

  let playersEloDiff;

  if (playerElo && categoryMin >= 1800) {
    playersEloDiff = 400;
  } else {
    playersEloDiff = 200;
  }

  const secondPlayerMin = playerElo - playersEloDiff;
  const secondPlayerMax = playerElo + playersEloDiff;

  const minAverageElo = Math.ceil((playerElo + secondPlayerMin) / 2);
  const maxAverageElo = Math.ceil((playerElo + secondPlayerMax) / 2);

  return minAverageElo <= categoryMax && maxAverageElo >= categoryMin;
};

export const calculatePartnerEloBoundaries = (
  playerElo: number,
  categoryMin: number,
  categoryMax: number,
) => {
  let playersEloDiff;

  if (playerElo && categoryMin >= 1800) {
    playersEloDiff = 400;
  } else {
    playersEloDiff = 200;
  }

  // Ensuring the minimum average team Elo does not fall below the category minimum
  const minEloPartner = Math.max(
    categoryMin * 2 - playerElo,
    playerElo - playersEloDiff,
  );
  // Ensuring the maximum average team Elo does not exceed the category maximum
  const maxEloPartner = Math.min(
    categoryMax * 2 - playerElo,
    playerElo + playersEloDiff,
  );

  return {
    minEloPartner,
    maxEloPartner,
  };
};

export const computeNewElo = (
  eloTeamWinning: number,
  eloTeamLosing: number,
  kFactor: number,
) => {
  // Calculate the expected scores (probability of winning)
  const expectedScoreWinning =
    1 / (1 + Math.pow(10, (eloTeamLosing - eloTeamWinning) / 400));
  const expectedScoreLosing = 1 - expectedScoreWinning;

  // Calculate the new Elo ratings
  const newEloWinning = Math.round(
    eloTeamWinning + kFactor * (1 - expectedScoreWinning),
  );
  const newEloLosing = Math.round(
    eloTeamLosing + kFactor * (0 - expectedScoreLosing),
  );

  // Calculate the difference in Elo rating compared to the original
  const eloDifferenceWinning = newEloWinning - eloTeamWinning;
  const eloDifferenceLosing = newEloLosing - eloTeamLosing;

  return {
    newEloWinning: newEloWinning,
    newEloLosing: newEloLosing,
    eloDifferenceWinning: eloDifferenceWinning,
    eloDifferenceLosing: eloDifferenceLosing,
    probabilityWinning: expectedScoreWinning,
    probabilityLosing: expectedScoreLosing,
  };
};

export const formatDateToRead = (date: string) => {
  if (!date) return null;
  const matchStart = DateTime.fromISO(date).toJSDate();
  return matchStart
    ? DateTime.fromJSDate(matchStart)
        .setZone('Europe/Brussels')
        .toFormat("d LLLL yyyy 'à' HH:mm", {
          locale: 'fr',
        })
    : 'None';
};
