import React, { ReactNode } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  DrawerProps,
} from '@chakra-ui/react';
import { useTranslation } from '../../../../translations/useTranslations';

interface IDrawerComponentProps extends DrawerProps {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  isFooterVisible?: boolean;
  children: ReactNode;
}

export const DrawerComponent = ({
  title,
  isOpen,
  onClose,
  isLoading,
  handleSubmit,
  children,
  isFooterVisible,
  ...restProps
}: IDrawerComponentProps) => {
  const { translate } = useTranslation();

  return (
    <>
      <Drawer
        placement={'right'}
        onClose={onClose}
        isOpen={isOpen}
        {...restProps}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor="background.default">
          <DrawerCloseButton color="white" />
          <DrawerHeader
            borderBottomWidth="1px"
            fontSize="3xl"
            color="textColor.default"
          >
            {title}
          </DrawerHeader>
          <DrawerBody mt={5}>{children}</DrawerBody>
          {isFooterVisible && (
            <DrawerFooter justifyContent="space-between">
              <Button
                variant="outline"
                w="full"
                mr={2}
                onClick={onClose}
                isLoading={isLoading}
              >
                {translate('CANCEL')}
              </Button>
              <Button
                bg={'secondary.default'}
                color={'white'}
                _hover={{
                  bg: 'secondary.default',
                }}
                w="full"
                ml={2}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                {translate('CONFIRM')}
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
