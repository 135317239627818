import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { TournamentTeam } from '../../../../formats/types/tournament.types';
import { Match } from '../../../types/adminTournament.types';
import Select from 'react-select';
import { useState } from 'react';
import { TournamentCategory } from '../../../../formats/types/tournamentCategory.types';
import { useAdminTournamentsMatches } from '../../../hooks/useAdminTournamentMatches';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import { handleBackendError } from '../../../../common/services/handleBackendError';

interface AddTeamToRoundModalProps {
  isOpen: boolean;
  onClose: () => void;
  match?: Match;
  type: 'A' | 'B' | null;
  chosenCategory?: TournamentCategory;
}

export const AddTeamToRoundModal = ({
  isOpen,
  onClose,
  match,
  type,
  chosenCategory,
}: AddTeamToRoundModalProps) => {
  const { translate } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const toast = useToast();
  const { updateMatchTeam, isUpdateMatchTeamLoading } =
    useAdminTournamentsMatches();
  const { refetchTournamentCategoryBracket } = useAdminTournaments(
    match?.tournamentId,
    match?.categoryId,
  );

  const handleSubmit = async () => {
    if (!type || !selectedOptions) {
      toast({
        title: translate('SELECT_A_TEAM'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let updatedMatch;

    if (type === 'A') {
      updatedMatch = {
        teamAId: selectedOptions?.team?.id,
      };
    } else if (type === 'B') {
      updatedMatch = {
        teamBId: selectedOptions?.team?.id,
      };
    }

    try {
      await updateMatchTeam({
        matchId: match?.id,
        updatedMatch: updatedMatch,
      });

      toast({
        title: translate('MATCH_EDITED_SUCCESSFULLY'),
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
      await refetchTournamentCategoryBracket();
      onClose();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const options = chosenCategory?.teams?.map((team: TournamentTeam) => {
    const label = `${team?.players?.[0]?.user?.displayName} / ${team?.players?.[1]?.user?.displayName}`;
    return {
      value: team?.id,
      label: label,
      team: team,
    };
  });

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} size="xl">
      <>
        <Heading
          color="white"
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign="center"
        >
          {translate('ADD_TEAM')}
        </Heading>

        <Flex mt={5}>
          <FormControl>
            <FormLabel color="white">
              {translate('SELECT_TEAM_TITLE')}
            </FormLabel>
            <Select
              options={options}
              onChange={handleChange}
              value={selectedOptions}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? 'grey' : 'grey',
                  backgroundColor: 'transparent',
                }),

                option: (styles, { isSelected }) => ({
                  ...styles,
                  color: isSelected ? 'white' : 'black',
                }),
                input: (styles) => ({
                  ...styles,
                  color: 'white', // Set the input text color to white
                }),
              }}
            />
          </FormControl>
        </Flex>

        <Flex mt={10}>
          <Button
            w="full"
            mr={2}
            onClick={onClose}
            isLoading={isUpdateMatchTeamLoading}
          >
            {translate('CANCEL')}
          </Button>
          <Button
            w="full"
            ml={2}
            backgroundColor="secondary.default"
            color="white"
            _hover={{
              backgroundColor: 'secondary.default',
              color: 'white',
            }}
            onClick={handleSubmit}
            isLoading={isUpdateMatchTeamLoading}
          >
            {translate('ADD_TEAM')}
          </Button>
        </Flex>
      </>
    </ResponsiveModal>
  );
};
