// @ts-nocheck
import { FormEvent, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useUserProfile } from '../hooks/useUserProfile';
import { useTranslation } from '../../../translations/useTranslations';
import { languages } from '../../common/components/LanguagePicker';

const defaultFormFields = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  gender: '',
  locale: '',
};

export const UserProfileForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { firstname, lastname, email, phoneNumber, gender, locale } =
    formFields;
  const { isAuthenticated } = useAuthContext();
  const {
    userProfile,
    refetchUserProfile,
    updateUserProfile,
    isUpdateUserProfileLoading,
  } = useUserProfile(isAuthenticated);
  const toast = useToast();
  const { translate } = useTranslation();

  const handleChange = (event: FormEvent) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleChangePhoneNumber = (value: string) => {
    setFormFields({ ...formFields, phoneNumber: value });
  };

  useEffect(() => {
    if (userProfile) {
      setFormFields({
        lastname: userProfile.lastname,
        firstname: userProfile?.firstname,
        email: userProfile?.email,
        phoneNumber: userProfile?.phoneNumber,
        gender: userProfile?.gender,
        locale: userProfile?.locale,
      });
    }
  }, [userProfile]);

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    if (
      !lastname ||
      !firstname ||
      !email ||
      !phoneNumber ||
      !gender ||
      !locale
    ) {
      toast({
        title: translate('REQUIRED_FIELDS_MISSING'),
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await updateUserProfile({
        id: userProfile?.id,
        lastname: lastname,
        firstname: firstname,
        phoneNumber: phoneNumber,
        gender: gender,
        locale: locale,
      });
      refetchUserProfile();
      toast({
        title: translate('PROFILE_UPDATED_SUCCESS'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: translate('ERROR_OCCURED'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <Flex align={'center'} justify={'center'} mt={6}>
          <Stack
            spacing={4}
            w={'full'}
            p={6}
            position="relative"
            color="textColor.default"
          >
            <FormControl id="lastname" isRequired>
              <Input
                type="text"
                onChange={handleChange}
                name="lastname"
                value={lastname}
                placeholder={translate('LAST_NAME')}
                border="none"
                backgroundColor="fourth.default"
              />
            </FormControl>
            <FormControl id="firstname" isRequired>
              <Input
                placeholder={translate('FIRST_NAME')}
                type="text"
                onChange={handleChange}
                name="firstname"
                value={firstname}
                border="none"
                backgroundColor="fourth.default"
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <Input
                placeholder={translate('EMAIL_ADDRESS')}
                type="email"
                isDisabled={true}
                name="email"
                value={email}
                border="none"
                backgroundColor="fourth.default"
              />
            </FormControl>
            <FormControl id="phoneNumber" isRequired>
              <PhoneInput
                placeholder={translate('PHONE_NUMBER')}
                value={phoneNumber}
                onChange={handleChangePhoneNumber}
                country="be"
                dropdownStyle={{ color: 'black' }}
                buttonStyle={{ background: '#2D2D2D', border: 'none' }}
                inputStyle={{
                  background: '#2D2D2D',
                  width: '100%',
                  border: 'none',
                  borderRadius: 6,
                }}
              />
            </FormControl>
            <FormControl id="gender" isRequired>
              <Select
                placeholder={translate('GENDER_TYPE')}
                onChange={handleChange}
                name="gender"
                value={gender ? gender : undefined}
              >
                <option value="MEN">{translate('GENDER_TYPE_MEN')}</option>
                <option value="WOMEN">{translate('GENDER_TYPE_WOMEN')}</option>
              </Select>
            </FormControl>
            <FormControl id="locale" isRequired>
              <Select
                placeholder={translate('DEFAULT_LANGUAGE')}
                onChange={handleChange}
                name="locale"
                value={locale || 'en-US'}
              >
                {languages.map((language) => {
                  return (
                    <option key={language.locale} value={language.locale}>
                      {`${language.flag} ${translate(language.locale)}`}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <Stack spacing={6} direction={['column', 'row']}>
              <Button
                bg="primary.default"
                color={'white'}
                w="full"
                _hover={{
                  bg: 'primary.default',
                }}
                type="submit"
                isLoading={isUpdateUserProfileLoading}
              >
                {translate('UPDATE_MY_INFOS')}
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </form>
    </>
  );
};
