import {
  Button,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faGavel,
  faList,
  faLocationDot,
  faLock,
  faMoneyBill,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthProvider';
import { useTranslation } from '../../../../../translations/useTranslations';
import {
  checkCategoriesBoundaries,
  genderCheck,
  getAmountWithCurrency,
  getDefaultCurrency,
  getFormattedDate,
  isRegistrationClosed,
} from '../../../../../utils/utils';
import { CustomMarkdown } from '../../../../common/components/CustomMarkdown';
import { SkeletonWrapper } from '../../../../common/components/SkeletonWrapper';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import { usePlayerProfile } from '../../../../player/hooks/usePlayer';
import { useUserProfile } from '../../../../user/hooks/useUserProfile';
import { useTournaments } from '../../../hooks/useTournaments';
import { TournamentCategory } from '../../../types/tournamentCategory.types';

const TournamentFormatInfos = ({ icon, title, description }: any) => {
  return (
    <Flex
      p={5}
      borderWidth={1}
      borderColor="#2F384C"
      borderRadius={10}
      flexDirection="column"
      w={300}
      minHeight="150px"
      justifyContent="center"
      backgroundColor="background.secondary"
    >
      <Flex>
        <FontAwesomeIcon icon={icon} size="xl" color="#95acda" />
      </Flex>
      <Flex mt={2} flexDirection="column">
        <Text color="white" fontWeight="bold">
          {title}
        </Text>
        <Text color="textColor.light">{description}</Text>
      </Flex>
    </Flex>
  );
};

const TournamentCategoriesInfos = ({
  category,
  tournament,
  handleChoseCategory,
}: {
  category: TournamentCategory;
  tournament: any;
  handleChoseCategory: () => void;
}) => {
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const { playerProfile } = usePlayerProfile(userProfile?.id);
  const { translate } = useTranslation();
  const { playerTeam } = useTournaments(tournament?.id, playerProfile?.id);

  const isCategoryFull = category?.teams.length === category?.capacity;
  const isClosed =
    isRegistrationClosed(tournament?.registrationClosingDate) ||
    !tournament?.isOpen;

  const canJoinCategory =
    userProfile &&
    playerProfile &&
    genderCheck(userProfile.gender, category?.categoryGender) &&
    !isCategoryFull &&
    !isClosed &&
    checkCategoriesBoundaries(
      playerProfile.elo,
      category.minElo,
      category.maxElo,
    );

  return (
    <Flex
      mt={5}
      p={5}
      borderWidth={1}
      borderColor="#2F384C"
      borderRadius={10}
      flexWrap="wrap"
      backgroundColor="background.secondary"
    >
      <HStack spacing="24px" w="full">
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text color="textColor.light">{translate('CATEGORIES_TITLE')}</Text>
          <Text>
            {`${translate(category?.categoryGender)} - ${
              category?.categoryRanking
            }`}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text color="textColor.light">{translate('ELO_RANGE_TITLE')}</Text>
          <Text>{`[${category?.minElo} - ${category?.maxElo}]`}</Text>
        </Flex>
      </HStack>
      <HStack spacing="24px" w="full" mt={5}>
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text color="textColor.light" textAlign="center">
            {translate('TEAMS_REGISTERED_TITLE_SHORT')}
          </Text>
          <Text>
            {category?.teams.length} / {category?.capacity}
          </Text>
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          w="full"
        >
          {playerTeam && playerTeam?.tournamentCategoryId === category?.id && (
            <Text color="green" fontWeight="bold">
              {translate('REGISTERED')}
            </Text>
          )}
          {canJoinCategory && !playerTeam ? (
            <Button
              bg="secondary.default"
              color={'white'}
              _hover={{
                backgroundColor: 'secondary.default',
              }}
              onClick={handleChoseCategory}
            >
              {translate('REGISTER_MYSELF')}
            </Button>
          ) : (
            ''
          )}
        </Flex>
      </HStack>
    </Flex>
  );
};

export const TournamentGeneralInformations = ({
  handleChoseCategory,
}: {
  handleChoseCategory: (category: TournamentCategory) => void;
}) => {
  const { translate } = useTranslation();
  const { isDesktop, isMobile } = useMediaQuery();
  const { id } = useParams();
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const { tournament, isTournamentLoading } = useTournaments(Number(id));
  const locale = userProfile?.locale ?? navigator.language;

  const fullDate = `${getFormattedDate(
    tournament?.startDate,
    locale,
    isDesktop,
  )} - ${getFormattedDate(tournament?.endDate, locale, isDesktop)}`;
  const currency = getDefaultCurrency();

  const targetDate = DateTime.fromISO(
    tournament?.registrationClosingDate || '',
  );
  const currentDate = DateTime.utc();
  const diffInDays = targetDate.diff(currentDate, 'days').days;
  const daysLeft = Math.floor(diffInDays);

  const registrationClosingDate = `${getFormattedDate(
    tournament?.registrationClosingDate,
    locale,
    true,
  )} (${translate('NUMBER_OF_DAYS_LEFT_TITLE', { days: daysLeft })})`;

  return (
    <Flex w="full" overflowX="hidden" flexDirection="column">
      <Stack direction={['column', 'row']} w="full">
        <Flex w={isDesktop ? '60%' : 'full'} flexDirection="column">
          <Flex>
            <Heading
              fontSize={{ base: 'xl', md: '2xl' }}
              textAlign={'center'}
              color="white"
            >
              {translate('FORMAT_TITLE')}
            </Heading>
          </Flex>
          <Flex overflow="scroll">
            <HStack
              spacing="15px"
              wrap={isMobile ? 'nowrap' : 'wrap'}
              w="fit-content"
              align="stretch"
              mt={5}
            >
              <CustomSkeletonWrapperFormatsInfos
                isLoading={isTournamentLoading}
                isMobile={isMobile}
              >
                <TournamentFormatInfos
                  icon={faPlay}
                  title={translate('FORMAT_TITLE')}
                  description={translate('FORMAT_DESCRIPTION')}
                />
                <TournamentFormatInfos
                  icon={faCalendar}
                  title={translate('DATE_TITLE')}
                  description={fullDate}
                />
                <TournamentFormatInfos
                  icon={faList}
                  title={translate('CATEGORIES_TITLE')}
                  description={tournament?.tournamentCategories?.length}
                />
                <TournamentFormatInfos
                  icon={faMoneyBill}
                  title={translate('PRICE_TITLE')}
                  description={translate('PRICE_PER_PERSON_TITLE', {
                    amount: getAmountWithCurrency(
                      tournament?.priceCents,
                      userProfile?.locale || 'en-US',
                      currency,
                    ),
                  })}
                />
                <TournamentFormatInfos
                  icon={faLocationDot}
                  title={translate('ADDRESS_TITLE')}
                  description={tournament?.address}
                />
                <TournamentFormatInfos
                  icon={faLock}
                  title={translate('REGISTERING_CLOSING_AT')}
                  description={registrationClosingDate}
                />
                <TournamentFormatInfos
                  icon={faGavel}
                  title={translate('RULE_BOOK_TITLE')}
                  description={
                    <Flex mt={1}>
                      <Text
                        color="secondary.default"
                        textDecoration="underline"
                        cursor="pointer"
                        onClick={() =>
                          window.open(tournament?.tournamentRulesUrl, '_blank')
                        }
                      >
                        {translate('READ_RULES_TITLE')}
                      </Text>
                    </Flex>
                  }
                />
              </CustomSkeletonWrapperFormatsInfos>
            </HStack>
          </Flex>
          <Stack direction={['column', 'row']} w="full" mt={10}>
            <Flex mt={isMobile ? 5 : 0} flexDirection="column">
              <Flex>
                <Heading
                  fontSize={{ base: 'xl', md: '2xl' }}
                  textAlign={'center'}
                  color="white"
                >
                  {translate('INFORMATION_TITLE')}
                </Heading>
              </Flex>
              <Flex mt={5}>
                <CustomMarkdown
                  text={translate('TOURNAMENT_INFORMATIONS', {
                    tournamentName: tournament?.name,
                    registrationClosingDate: registrationClosingDate,
                    tournamentPrice: getAmountWithCurrency(
                      tournament?.priceCents,
                      userProfile?.locale || 'en-US',
                      currency,
                    ),
                  })}
                />
              </Flex>
            </Flex>
          </Stack>
        </Flex>
        <Flex
          w={isMobile ? 'auto' : '40%'}
          mt={isMobile ? 5 : 0}
          flexDirection="column"
        >
          <Flex>
            <Heading
              fontSize={{ base: 'xl', md: '2xl' }}
              textAlign={'center'}
              color="white"
            >
              {translate('CATEGORIES_TITLE')}
            </Heading>
          </Flex>

          <SkeletonWrapper
            isLoading={isTournamentLoading}
            count={5}
            skeleton={customSkeletonWrapper}
          >
            {tournament &&
              tournament?.tournamentCategories.length > 0 &&
              tournament?.tournamentCategories.map(
                (category: any, index: number) => {
                  return (
                    <TournamentCategoriesInfos
                      key={index}
                      category={category}
                      tournament={tournament}
                      handleChoseCategory={() => handleChoseCategory(category)}
                    />
                  );
                },
              )}
          </SkeletonWrapper>
        </Flex>
      </Stack>
    </Flex>
  );
};

const customSkeletonWrapper = (
  <Flex flexDirection="column" mt={5} w="full">
    <Skeleton
      height="130"
      startColor="gray.600"
      endColor="gray.700"
      borderRadius={10}
      width="100%"
    />
  </Flex>
);

export const CustomSkeletonWrapperFormatsInfos = ({
  isLoading,
  isMobile,
  children,
}: any) => {
  if (isLoading) {
    return (
      <Stack
        spacing="15px"
        wrap={isMobile ? 'nowrap' : 'wrap'}
        direction="row"
        w="fit-content"
        align="stretch"
        mt={5}
      >
        {Array.from({ length: 5 }).map(() => (
          <Flex w={300} minHeight={150} borderRadius={10}>
            <Skeleton
              startColor="gray.600"
              endColor="gray.700"
              borderRadius={10}
              width="100%"
              height="100%"
            />
          </Flex>
        ))}
      </Stack>
    );
  }

  return <>{children}</>;
};
