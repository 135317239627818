import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  Center,
  useToast,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import logo from '../../../assets/images/kwickers.png';
import { Link } from 'react-router-dom';
import useMediaQuery from '../hooks/useMediaQuery';
import { handleBackendError } from '../services/handleBackendError';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useUserProfile } from '../../user/hooks/useUserProfile';
import { useTranslation } from '../../../translations/useTranslations';
import { LanguagePicker } from './LanguagePicker';

export const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { isMobile } = useMediaQuery();
  const { isAuthenticated, logOut } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const toast = useToast();
  const { translate } = useTranslation();

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  return (
    <Box>
      <Flex
        bg="background.default"
        minH={'60px'}
        position="absolute"
        w="full"
        boxShadow="0px 1px 5px 1px rgba(0, 0, 0, 0.4)"
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <CloseIcon w={3} h={3} color="white" />
              ) : (
                <HamburgerIcon w={5} h={5} color="white" />
              )
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
            _active={{ bg: 'transparent' }}
            _focus={{ boxShadow: 'none' }}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Box>
            <Link to="/dashboard">
              <Image
                src={logo}
                alt="kwickers"
                boxSize="40px"
                objectFit="cover"
                borderRadius="full"
              />
            </Link>
          </Box>
          <Flex display={{ base: 'none', md: 'flex' }} ml={150}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
        >
          {isAuthenticated || isMobile ? (
            <Menu>
              <Flex color="white">
                <LanguagePicker />
              </Flex>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                <Avatar size={'sm'} src={userProfile?.profilePictureUrl} />
              </MenuButton>
              <MenuList alignItems={'center'}>
                <br />
                <Center>
                  <Avatar size={'2xl'} src={userProfile?.profilePictureUrl} />
                </Center>
                <br />
                {isAuthenticated && userProfile && (
                  <>
                    <Center>
                      <Box>
                        {userProfile?.displayName
                          ? userProfile?.displayName
                          : userProfile?.email}
                      </Box>
                    </Center>
                    <MenuDivider />
                    {userProfile?.role === 'ADMIN' && (
                      <Link to="adminActivities">
                        <MenuItem color="red">
                          {translate('ADMIN_TITLE')}
                        </MenuItem>
                      </Link>
                    )}
                    <Link to="profile">
                      <MenuItem>{translate('MY_ACCOUNT_TITLE')}</MenuItem>
                    </Link>
                  </>
                )}
                {isAuthenticated ? (
                  <MenuItem onClick={handleSignOut}>
                    {translate('LOG_OUT')}
                  </MenuItem>
                ) : (
                  <>
                    <Link to="/signin">
                      <MenuItem>{translate('LOG_IN')}</MenuItem>
                    </Link>
                    <Link to="/signup">
                      <MenuItem>{translate('CREATE_ACCOUNT')}</MenuItem>
                    </Link>
                  </>
                )}
              </MenuList>
            </Menu>
          ) : (
            <>
              <Flex alignItems="center">
                <Flex color="white">
                  <LanguagePicker />
                </Flex>
                <Link to="/signin">
                  <Button
                    as={'a'}
                    fontSize={'sm'}
                    fontWeight={400}
                    variant={'link'}
                    href={'#'}
                  >
                    {translate('LOG_IN')}
                  </Button>
                </Link>
              </Flex>
              <Link to="/signup">
                <Button
                  as={'a'}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'primary.default'}
                  href={'#'}
                  _hover={{
                    bg: 'primary.default',
                  }}
                >
                  {translate('REGISTER_TITLE')}
                </Button>
              </Link>
            </>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onToggle} />
      </Collapse>
    </Box>
  );
};

const DesktopNav = () => {
  const linkColor = 'white';
  const linkHoverColor = 'secondary.default';
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const { translate } = useTranslation();

  return (
    <Stack direction={'row'} spacing={4} alignItems="center">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link to={navItem.href as string}>
                <Box
                  as="a"
                  p={2}
                  fontSize={'md'}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}
                >
                  {translate(navItem.label)}
                </Box>
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  const { translate } = useTranslation();
  return (
    <Link to={href as string}>
      <Box
        as="a"
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: 'secondary.100' }}
      >
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'primary.default' }}
              fontWeight={500}
              fontSize={'md'}
              as="h1"
            >
              {translate(label)}
            </Text>
            <Text fontSize={'sm'} as="h2">
              {subLabel && translate(subLabel)}
            </Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}
          >
            <Icon color={'primary.default'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Box>
    </Link>
  );
};

const MobileNav = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack
      bg="background.default"
      p={4}
      display={{ md: 'none' }}
      position="absolute"
      w="full"
      zIndex={9999}
    >
      {NAV_ITEMS_MOBILE.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  );
};
//@ts-ignore
const MobileNavItem = ({ label, children, href, onClose }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  const { translate } = useTranslation();

  return (
    <Stack spacing={4} onClick={children && onToggle} zIndex={9999} mb={3}>
      <Link to={href as string} onClick={onClose}>
        <Box
          py={2}
          as="a"
          justifyContent="space-between"
          alignItems="center"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text fontWeight={600} color="white" as="h1" fontSize="xl">
            {translate(label)}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Box>
      </Link>
      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link to={child.href as string} key={child.label}>
                <Box as="a" py={2}>
                  {translate(child.label)}
                </Box>
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  onClose?: () => void;
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Ligue',
  //   children: [
  //     {
  //       label: 'Jouer',
  //       subLabel: 'Découvrez nos formats de jeu',
  //       href: '/formats',
  //     },
  //     {
  //       label: 'Classements',
  //       subLabel: 'Retrouvez tous les joueurs de la ligue',
  //       href: '/rankings',
  //     },
  //   ],
  // },
  {
    label: 'PLAY_TITLE',
    subLabel: 'DISCOVER_OUR_FORMATS',
    href: '/formats',
  },
  {
    label: 'SEARCH_FOR_PARTNERS',
    href: '/whatsapp',
  },
  {
    label: 'MORE_INFOS',
    href: '/infos',
  },
];

const NAV_ITEMS_MOBILE: Array<NavItem> = [
  {
    label: 'HOME_TITLE',
    href: '/',
  },
  {
    label: 'PLAY_TITLE',
    href: '/formats',
  },
  // {
  //   label: 'Classements',
  //   href: '/rankings',
  // },
  {
    label: 'SEARCH_FOR_PARTNERS',
    href: '/whatsapp',
  },
  {
    label: 'MORE_INFOS',
    href: '/infos',
  },
];
