import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { SpinnerLoading } from '../../common/components/SpinnerLoading';

export const SocialAuthSuccess = () => {
  const navigate = useNavigate();
  const { handleLoginSuccess, isAuthenticated } = useAuthContext();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');
    handleLoginSuccess({ accessToken });
  }, [navigate]);

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return <SpinnerLoading />;
};
