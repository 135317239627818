/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { FormEvent, useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Center,
  useToast,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { Link, useNavigate } from 'react-router-dom';

import { PagesContainer } from '../../common/components/PagesContainer';
import { handleBackendError } from '../../common/services/handleBackendError';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useTranslation } from '../../../translations/useTranslations';
import usePageView from '../../common/hooks/GA4/usePageView';
import useEvent from '../../common/hooks/GA4/useEvent';

const defaultFormFields = {
  email: '',
  password: '',
};

export const SignIn = () => {
  usePageView('/signin', 'Sign in page view');
  useEvent('Sign In', 'Get to signin page');
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const toast = useToast();
  const navigate = useNavigate();
  const { logIn, isLoginLoading } = useAuthContext();
  const { translate } = useTranslation();

  const handleChange = (event: FormEvent) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    const { email, password } = formFields;

    try {
      await logIn({ email, password });
      navigate('/dashboard');
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const signInWithGoogle = async () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/auth/google`, '_self');
  };

  return (
    <PagesContainer>
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <Flex align={'center'} justify={'center'}>
          <Stack spacing={8} mx={'auto'} maxW={'lg'}>
            <Stack align={'center'}>
              <Heading
                fontSize={{ base: '3xl', md: '5xl' }}
                color="secondary.default"
              >
                {translate('LOG_IN')}
              </Heading>
            </Stack>
            <Box p={8}>
              <Stack spacing={4} color="textColor.default">
                <FormControl id="email">
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    placeholder={translate('EMAIL_ADDRESS')}
                    border="none"
                    backgroundColor="fourth.default"
                  />
                </FormControl>
                <FormControl id="password">
                  <Input
                    type="password"
                    name="password"
                    placeholder={translate('PASSWORD')}
                    border="none"
                    backgroundColor="fourth.default"
                    onChange={handleChange}
                    value={password}
                  />
                </FormControl>
                <Stack spacing={1}>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    align={'start'}
                    justify={'space-between'}
                    mb={5}
                  >
                    <Text
                      cursor="pointer"
                      color={'blue.400'}
                      textAlign="center"
                      margin="auto"
                    >
                      <Link to="/forgotPassword">Mot de passe oublié ?</Link>
                    </Text>
                  </Stack>
                  <Button
                    bg={'primary.default'}
                    color={'white'}
                    _hover={{
                      bg: 'primary.default',
                    }}
                    type="submit"
                    isLoading={isLoginLoading}
                  >
                    {translate('LOG_IN')}
                  </Button>
                  <Text textAlign="center">{translate('OR')}</Text>

                  <Button
                    w={'full'}
                    maxW={'md'}
                    variant={'outline'}
                    leftIcon={<FcGoogle />}
                    onClick={signInWithGoogle}
                    type="button"
                    color="textColor.default"
                    _hover={{
                      bg: 'none',
                      color: 'secondary.default',
                      borderColor: 'secondary.default',
                    }}
                  >
                    <Center>
                      <Text>{translate('SIGN_IN_WITH_GOOGLE')}</Text>
                    </Center>
                  </Button>
                </Stack>
              </Stack>
              <Stack pt={6}>
                <Text align={'center'} color="textColor.default">
                  {translate('NOT_MEMBER_YET')}
                  <br />
                  <Link to="/signup">
                    <Text
                      color="secondary.default"
                      fontWeight="bold"
                      textDecoration="underline"
                    >
                      {translate('CREATE_ACCOUNT')}
                    </Text>
                  </Link>
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </form>
    </PagesContainer>
  );
};
