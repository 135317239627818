import React, { ReactNode, FC } from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/modal';

export interface ModalProps extends ChakraModalProps {
  children: ReactNode;
  header?: ReactNode;
  closeButtonDisabled?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  header,
  closeButtonDisabled,
  ...props
}) => {
  return (
    <ChakraModal {...props}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="background.secondary"
        paddingBottom={4}
        borderRadius={10}
      >
        <ModalHeader>
          {header}
          {!closeButtonDisabled && (
            <ModalCloseButton
              color="textColor.light"
              _hover={{
                color: 'white',
              }}
            />
          )}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
