import { useQuery } from 'react-query';
import { get } from '../../common/services/HttpMethods';

export const useUserSearch = (searchQuery: string) => {
  const userSearchQuery = useQuery(
    ['userSearch', searchQuery],
    async (): Promise<[]> => {
      if (!searchQuery.trim()) return []; // Directly return an empty array if the input is empty

      return get(`/players/search?name=${encodeURIComponent(searchQuery)}`);
    },
    {
      enabled: true,
      keepPreviousData: true, // Keep displaying the previous data as new data is being fetched
      staleTime: 300000, // Consider fetched data fresh for 5 minutes
      cacheTime: 600000, // The cache of the query will persist for 10 minutes before being garbage collected
    },
  );

  return {
    searchPlayers: userSearchQuery.data,
    isSearchLoading: userSearchQuery.isLoading,
    searchError: userSearchQuery.error,
  };
};
