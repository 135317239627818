import {
  Card,
  CardBody,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useTranslation } from '../../../translations/useTranslations';
import { SkeletonWrapper } from '../../common/components/SkeletonWrapper';
import { SpinnerWrapper } from '../../common/components/SpinnerWrapper';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useUserProfile } from '../../user/hooks/useUserProfile';
import { usePlayerProfile } from '../hooks/usePlayer';

interface ICardPlayerInfoProps {
  value: string | number | undefined;
  title: string;
  isLoading: boolean;
}

const CardPlayerInfo = ({ value, title, isLoading }: ICardPlayerInfoProps) => {
  return (
    <Card backgroundColor="background.secondary" w="full" m={1}>
      <CardBody textAlign="center">
        <SpinnerWrapper isLoading={isLoading}>
          <Heading
            fontSize={{ base: '3xl', sm: '5xl' }}
            color="secondary.default"
          >
            {value ?? '-'}
          </Heading>
          <Heading
            fontSize={{ base: 'md', sm: 'xl' }}
            color="textColor.default"
          >
            {title}
          </Heading>
        </SpinnerWrapper>
      </CardBody>
    </Card>
  );
};

export const PlayerDashboardContent = () => {
  const { isAuthenticated } = useAuthContext();
  const { translate } = useTranslation();
  const { userProfile, isUserProfileLoading } = useUserProfile(isAuthenticated);
  const { playerProfile, isPlayerProfileLoading } = usePlayerProfile(
    userProfile?.id,
  );
  const { isDesktop } = useMediaQuery();

  return (
    <Flex w="100%" mt={5} flexDirection="column">
      <Flex
        justifyContent="center"
        w="full"
        flexDirection={isDesktop ? 'row' : 'column'}
      >
        <CardPlayerInfo
          value={playerProfile?.elo}
          title={translate('ELO_RANKING_TITLE')}
          isLoading={
            isPlayerProfileLoading ||
            isPlayerProfileLoading ||
            !playerProfile ||
            !userProfile
          }
        />
        <CardPlayerInfo
          value={playerProfile?.kwickersPoints}
          title={translate('KWICKERS_POINTS_TITLE')}
          isLoading={
            isPlayerProfileLoading ||
            isPlayerProfileLoading ||
            !playerProfile ||
            !userProfile
          }
        />
        {/* <CardPlayerInfo
          value={userProfile?.credits}
          title={translate('CREDITS_TITLE')}
        /> */}
      </Flex>

      <Flex m={1} mt={8} w="full">
        <Tabs w="full" variant="unstyled" size="lg">
          <TabList borderBottomWidth={1}>
            <Tab
              _selected={{
                color: 'secondary.default',
                borderWidth: 1,
                borderTopRadius: 5,
              }}
              color="textColor.default"
            >
              {translate('GENERAL_TAB_TITLE')}
            </Tab>
            <Tab
              _selected={{
                color: 'secondary.default',
                borderWidth: 1,
                borderTopRadius: 5,
              }}
              color="textColor.default"
            >
              {translate('TOURNAMENTS_TAB_TITLE')}
            </Tab>
            <Tab
              _selected={{
                color: 'secondary.default',
                borderWidth: 1,
                borderTopRadius: 5,
              }}
              color="textColor.default"
            >
              {translate('BOXES_TAB_TITLE')}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <SkeletonWrapper
                isLoading={isUserProfileLoading || isPlayerProfileLoading}
              >
                <Text color="white">{translate('WIP_TITLE')}</Text>
              </SkeletonWrapper>
            </TabPanel>
            <TabPanel>
              <SkeletonWrapper
                isLoading={isUserProfileLoading || isPlayerProfileLoading}
              >
                <Text color="white">{translate('WIP_TITLE')}</Text>
              </SkeletonWrapper>
            </TabPanel>{' '}
            <TabPanel>
              <SkeletonWrapper
                isLoading={isUserProfileLoading || isPlayerProfileLoading}
              >
                <Text color="white">{translate('WIP_TITLE')}</Text>
              </SkeletonWrapper>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
};
