import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  faList,
  faRankingStar,
  faUser,
  faWeightHanging,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthProvider';
import { useTranslation } from '../../../../translations/useTranslations';
import { isRegistrationClosed } from '../../../../utils/utils';
import { JoinKwickersModal } from '../../../common/components/JoinKwickersModal';
import { SkeletonWrapper } from '../../../common/components/SkeletonWrapper';
import { TabsMenu } from '../../../common/components/TabsMenu';
import usePageView from '../../../common/hooks/GA4/usePageView';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import { useTournamentCheckout } from '../../../payment/hooks/useTournamentCheckout';
import { usePlayerProfile } from '../../../player/hooks/usePlayer';
import { useUserProfile } from '../../../user/hooks/useUserProfile';
import { useTournaments } from '../../hooks/useTournaments';
import { TournamentCategory } from '../../types/tournamentCategory.types';
import { JoinTournamentModal } from './tournament/JoinTournamentModal';
import { TournamentCountDown } from './tournament/tournamentCountdown/TournamentCountDown';
import { TournamentFinals } from './tournament/TournamentFinals';
import { TournamentGeneralInformations } from './tournament/TournamentGeneralInformations';
import { TournamentPools } from './tournament/TournamentPools';
import { TournamentTeams } from './tournament/TournamentTeams';

const TournamentStatus = ({ color, title }: any) => {
  return (
    <Flex
      borderWidth={2}
      px={4}
      borderRadius="999"
      w="fit-content"
      borderColor={color}
      color={color}
      fontWeight="bold"
    >
      {title}
    </Flex>
  );
};

export const Tournament = () => {
  const { id } = useParams();
  const { translate, ready } = useTranslation();
  const { isDesktop } = useMediaQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJoinKwickersModalOpen, setIsJoinKwickersModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);
  const { playerProfile } = usePlayerProfile(userProfile?.id);
  const toast = useToast();
  const [categoryChosen, setCategoryChosen] =
    useState<TournamentCategory | null>(null);
  const { tournament, isTournamentLoading } = useTournaments(Number(id));
  const { playerTeam } = useTournaments(tournament?.id, playerProfile?.id);
  const { handlePayment, isTournamentCheckoutPaymentLoading } =
    useTournamentCheckout();
  const navigate = useNavigate();
  usePageView(
    `/formats/tournaments/${id}`,
    `Tournament view: ${tournament?.name}`,
  );

  const success = useMemo(() => searchParams.get('success'), [searchParams]);
  const canceled = useMemo(() => searchParams.get('canceled'), [searchParams]);

  if (!id) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    if (ready && translate) {
      if (success) {
        toast({
          title: translate('PAYMENT_COMPLETED_TITLE'),
          description: translate('PAYMENT_COMPLETED_DESCRIPTION'),
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else if (canceled) {
        toast({
          title: translate('PAYMENT_ABORTED_TRUE'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      searchParams.delete('success');
      searchParams.delete('canceled');
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, []);

  const handleChoseCategory = (category: TournamentCategory) => {
    setCategoryChosen(category);
    setIsModalOpen(true);
  };

  const tabs = [
    {
      label: translate('OVERVIEW_TITLE'),
      content: (
        <TournamentGeneralInformations
          handleChoseCategory={handleChoseCategory}
        />
      ),
    },
    { label: translate('TEAMS_TITLE'), content: <TournamentTeams /> },
    {
      label: translate('POOL_PHASE_TITLE'),
      content: <TournamentPools tournamentId={parseInt(id)} />,
    },
    {
      label: translate('FINALE_PHASE_TITLE'),
      content: <TournamentFinals tournamentId={parseInt(id)} />,
    },
    // {
    //   label: translate('MATCHES_TITLE'),
    //   content: <TournamentMatches tournamentId={parseInt(id)} />,
    // },
  ];

  const isClosed =
    isRegistrationClosed(tournament?.registrationClosingDate) ||
    !tournament?.isOpen;

  const playerOne = playerTeam?.players?.find(
    (player: any) => player.userId === userProfile?.id,
  );
  const playerTwo = playerTeam?.players?.find(
    (player: any) => player.userId !== userProfile?.id,
  );

  const handleCheckoutPayment = (userEmail: string) => {
    if (
      !tournament?.id ||
      !tournament?.tournamentImageUrl ||
      !tournament?.priceCents
    )
      return null;
    if (userProfile && playerTeam) {
      handlePayment({
        userEmail: userEmail,
        tournamentId: tournament?.id,
        currency: 'eur',
        playerOneTitle: translate('TOURNAMENT_REGISTRATION_PAYMENT_TITLE', {
          tournamentName: tournament?.name,
          playerName: playerOne?.user.displayName,
        }),
        playerTwoTitle: translate('TOURNAMENT_REGISTRATION_PAYMENT_TITLE', {
          tournamentName: tournament?.name,
          playerName: playerTwo?.user.displayName,
        }),
        imageUrl: tournament?.tournamentImageUrl,
        tounamentPriceCents: tournament?.priceCents,
        teamId: playerTeam?.id,
      });
    }
  };

  return (
    <>
      <Flex
        backgroundColor="background.default"
        flexGrow={1}
        color="white"
        mt="60px"
        flexDirection="column"
      >
        <Box width="full" position="relative" height="250px">
          <SkeletonWrapper
            isLoading={isTournamentLoading}
            skeleton={customSkeletonWrapper}
            count={1}
          >
            <Image
              src={tournament?.tournamentImageUrl}
              width="full"
              height="100%"
              objectFit="cover"
            />

            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              height="70%"
              backgroundImage="linear-gradient(to top, #181C25, transparent)"
            />
            <Flex
              position="absolute"
              bottom={isDesktop ? 0 : 5}
              justifyContent="space-between"
              w="full"
              px={isDesktop ? 20 : 0}
              alignItems="center"
              flexDirection={isDesktop ? 'row' : 'column'}
            >
              <Heading
                fontSize={{ base: '4xl', md: '5xl' }}
                textAlign={'center'}
                color="white"
                mb={isDesktop ? 0 : 10}
              >
                {tournament?.name}
              </Heading>

              <Flex
                alignItems="center"
                w={isDesktop ? 'auto' : 'full'}
                justifyContent="center"
              >
                {isClosed && (
                  <TournamentStatus
                    color="red"
                    title={translate('REGISTRATION_CLOSED')}
                  />
                )}
                {playerTeam?.paymentStatus === 'WAITING' && (
                  <TournamentStatus
                    color="orange"
                    title={translate('WAITING_PAYMENT_TITLE')}
                  />
                )}
                {playerTeam?.paymentStatus === 'PAID' && (
                  <TournamentStatus
                    color="green"
                    title={translate('REGISTRATION_CONFIRMED')}
                  />
                )}
                {!isClosed && !playerTeam && (
                  <Button
                    bg="secondary.default"
                    color={'white'}
                    _hover={{
                      backgroundColor: 'secondary.default',
                    }}
                    onClick={() => {
                      if (!isAuthenticated) setIsJoinKwickersModalOpen(true);
                      else setIsModalOpen(true);
                    }}
                  >
                    {translate('JOIN_TOURNAMENT')}
                  </Button>
                )}
              </Flex>
            </Flex>
          </SkeletonWrapper>
        </Box>
        {playerTeam && (
          <Flex flexDirection="column" mt={5}>
            <Flex
              borderWidth={1}
              borderColor="#2F384C"
              borderRadius={10}
              p={5}
              m={4}
              mt={1}
              flexDirection="column"
              backgroundColor="background.secondary"
            >
              {playerTeam && <TournamentCountDown playerTeam={playerTeam} />}
              <Text fontWeight="bold" w="full" color="secondary.default">
                {translate('YOUR_TEAM_FOR_TOUNAMENT').toUpperCase()}
              </Text>
              <Flex mt={3}>
                <Flex flexDirection="column">
                  <Flex mt={3}>
                    <FontAwesomeIcon icon={faUser} size="lg" color="#95acda" />
                    <Text ml={2}>{playerOne?.user?.displayName}</Text>
                    <Text ml={2}>{playerOne?.elo}</Text>
                  </Flex>
                  <Flex mt={3}>
                    <FontAwesomeIcon icon={faUser} size="lg" color="#95acda" />
                    <Text ml={2}>{playerTwo?.user?.displayName}</Text>
                    <Text ml={2}>{playerTwo?.elo}</Text>
                  </Flex>
                  <Flex mt={3}>
                    <FontAwesomeIcon
                      icon={faWeightHanging}
                      size="lg"
                      color="#95acda"
                    />
                    <Text ml={2}>
                      {`${translate('TEAM_ELO')} ${playerTeam.eloTeam}`}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Divider mt={5} mb={5} />
              <Text fontWeight="bold" w="full" color="secondary.default">
                {translate('YOUR_CATEGORY').toUpperCase()}
              </Text>
              <Flex flexDirection="column" mt={5}>
                <Flex>
                  <FontAwesomeIcon icon={faList} size="lg" color="#95acda" />
                  <Text ml={5} color="textColor.light">
                    {`${translate(
                      playerTeam.tournamentCategory.categoryGender,
                    )} - ${playerTeam?.tournamentCategory?.categoryRanking}`}
                  </Text>
                </Flex>
                <Flex mt={3}>
                  <FontAwesomeIcon
                    icon={faRankingStar}
                    size="lg"
                    color="#95acda"
                  />
                  <Text ml={4} color="textColor.light">
                    {`[${playerTeam.tournamentCategory.minElo} - ${playerTeam?.tournamentCategory?.maxElo}]`}
                  </Text>
                </Flex>
              </Flex>
              {playerTeam?.paymentStatus === 'WAITING' && (
                <Flex>
                  <Button
                    variant="outline"
                    color="white"
                    w="full"
                    mt={5}
                    _hover={{
                      backgroundColor: 'none',
                      borderColor: 'secondary.default',
                      textColor: 'secondary.default',
                    }}
                    isLoading={isTournamentCheckoutPaymentLoading}
                    onClick={() =>
                      handleCheckoutPayment(userProfile?.email as string)
                    }
                  >
                    {translate('FINISH_REGISTERING_TITLE')}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}

        <Flex maxW="100vw" p={5} mt={isDesktop ? 50 : 0}>
          <TabsMenu tabs={tabs} />
        </Flex>
      </Flex>

      <JoinKwickersModal
        isModalOpen={isJoinKwickersModalOpen}
        onClose={() => setIsJoinKwickersModalOpen(false)}
      />
      <JoinTournamentModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tournament={tournament}
        categoryChosen={categoryChosen}
        clearCategoryChosen={() => setCategoryChosen(null)}
      />
    </>
  );
};

const customSkeletonWrapper = (
  <Flex w="full" height={250} flexDirection="column">
    <Skeleton height="full" startColor="gray.600" endColor="gray.700" />
  </Flex>
);
