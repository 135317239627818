import { Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useTranslation } from '../../../../../../translations/useTranslations';
import { ResponsiveModal } from '../../../../../common/components/ResponsiveModal';
import { useTournamentMatches } from '../../../../hooks/useTournamentMatches';
import { Match } from '../../../../types/tournament.types';
import { TournamentPool } from '../../../../types/tournamentCategory.types';
import { MatchStatus } from './MatchStatus';

export const PoolMatchList = ({
  isOpen,
  onClose,
  tournamentId,
  pool,
}: {
  isOpen: boolean;
  onClose: () => void;
  tournamentId: number;
  pool: TournamentPool;
}) => {
  const { translate } = useTranslation();

  const { tournamentMatchesByPoolIdList } = useTournamentMatches(
    Number(tournamentId),
    Number(pool?.id),
  );

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} size="xl">
      <>
        <Heading
          color="white"
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign="center"
        >
          {translate('MATCHES_POOL_TITLE', {
            poolName: pool?.name,
          })}
        </Heading>

        <Flex flexDirection="column">
          {tournamentMatchesByPoolIdList?.map((match: Match, index: number) => {
            let formattedDate;
            if (match?.startDate) {
              const initialDate = DateTime.fromISO(match?.startDate).toJSDate();
              formattedDate = initialDate
                ? DateTime.fromJSDate(initialDate)
                    .setZone('Europe/Brussels')
                    .toFormat("d LLLL yyyy 'à' HH:mm", {
                      locale: 'fr',
                    })
                : 'None';
            }

            const isTeamAWinning = match?.teamAId === match?.winningTeamId;
            const isTeamBWinning = match?.teamBId === match?.winningTeamId;

            return (
              <Flex w="full" flexDirection="column">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text color="white" fontSize="0.8em">
                    {translate('MATCH_NUMBER', {
                      number: index + 1,
                    })}
                  </Text>
                  <MatchStatus status={match?.matchStatus} />
                </Flex>
                <Flex
                  key={index}
                  color="textColor.default"
                  borderWidth={1}
                  borderColor="textColor.default"
                  p={3}
                  borderRadius={6}
                  flexDirection="column"
                >
                  {/* TEAM A */}
                  <Flex
                    color={isTeamAWinning ? 'white' : 'textColor.default'}
                    fontWeight={isTeamAWinning ? 'bold' : 'normal'}
                  >
                    <Flex flexDirection="column" fontSize="0.8em" w="full">
                      <Flex>
                        {match?.teamA?.players?.[0]?.user?.displayName}
                      </Flex>
                      <Flex>
                        {match?.teamA?.players?.[1]?.user?.displayName}
                      </Flex>
                    </Flex>
                    <Flex
                      w="full"
                      justifyContent="space-around"
                      alignItems="center"
                      color={isTeamAWinning ? 'white' : 'textColor.default'}
                    >
                      <Flex position="relative">
                        <Text fontSize="1.3em">
                          {isTeamAWinning
                            ? match?.matchScore?.set1WinningTeam || 0
                            : match?.matchScore?.set1LosingTeam || 0}
                        </Text>
                        <Text
                          position="absolute"
                          top={-3}
                          left={3}
                          fontSize="0.9em"
                        >
                          {isTeamAWinning
                            ? match?.matchScore?.tieBreakSet1WinningTeam
                            : match?.matchScore?.tieBreakSet1LosingTeam}
                        </Text>
                      </Flex>
                      <Flex position="relative">
                        <Text fontSize="1.3em">
                          {isTeamAWinning
                            ? match?.matchScore?.set2WinningTeam || 0
                            : match?.matchScore?.set2LosingTeam || 0}
                        </Text>
                        <Text
                          position="absolute"
                          top={-3}
                          left={3}
                          fontSize="0.9em"
                        >
                          {isTeamAWinning
                            ? match?.matchScore?.tieBreakSet2WinningTeam
                            : match?.matchScore?.tieBreakSet2LosingTeam}
                        </Text>
                      </Flex>
                      <Flex position="relative">
                        <Text fontSize="1.3em">
                          {isTeamAWinning
                            ? match?.matchScore?.set3WinningTeam || 0
                            : match?.matchScore?.set3LosingTeam || 0}
                        </Text>
                        <Text
                          position="absolute"
                          top={-3}
                          left={3}
                          fontSize="0.9em"
                        >
                          {isTeamAWinning
                            ? match?.matchScore?.tieBreakSet3WinningTeam
                            : match?.matchScore?.tieBreakSet3LosingTeam}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Divider my={2} />
                  {/* TEAM B */}
                  <Flex
                    color={isTeamBWinning ? 'white' : 'textColor.default'}
                    fontWeight={isTeamBWinning ? 'bold' : 'normal'}
                  >
                    <Flex flexDirection="column" fontSize="0.8em" w="full">
                      <Flex>
                        {match?.teamB?.players?.[0]?.user?.displayName}
                      </Flex>
                      <Flex>
                        {match?.teamB?.players?.[1]?.user?.displayName}
                      </Flex>
                    </Flex>
                    <Flex
                      w="full"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Flex position="relative">
                        <Text fontSize="1.3em">
                          {isTeamBWinning
                            ? match?.matchScore?.set1WinningTeam || 0
                            : match?.matchScore?.set1LosingTeam || 0}
                        </Text>
                        <Text
                          position="absolute"
                          top={-3}
                          left={3}
                          fontSize="0.9em"
                        >
                          {isTeamBWinning
                            ? match?.matchScore?.tieBreakSet1WinningTeam
                            : match?.matchScore?.tieBreakSet1LosingTeam}
                        </Text>
                      </Flex>
                      <Flex position="relative">
                        <Text fontSize="1.3em">
                          {' '}
                          {isTeamBWinning
                            ? match?.matchScore?.set2WinningTeam || 0
                            : match?.matchScore?.set2LosingTeam || 0}
                        </Text>
                        <Text
                          position="absolute"
                          top={-3}
                          left={3}
                          fontSize="0.9em"
                        >
                          {isTeamBWinning
                            ? match?.matchScore?.tieBreakSet2WinningTeam
                            : match?.matchScore?.tieBreakSet2LosingTeam}
                        </Text>
                      </Flex>
                      <Flex position="relative">
                        <Text fontSize="1.3em">
                          {isTeamBWinning
                            ? match?.matchScore?.set3WinningTeam || 0
                            : match?.matchScore?.set3LosingTeam || 0}
                        </Text>
                        <Text
                          position="absolute"
                          top={-3}
                          left={3}
                          fontSize="0.9em"
                        >
                          {isTeamBWinning
                            ? match?.matchScore?.tieBreakSet3WinningTeam
                            : match?.matchScore?.tieBreakSet3LosingTeam}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text color="textColor.secondary" fontSize="0.8em">
                    {translate('COURT_TITLE', {
                      courtName: match?.courtName,
                    })}
                  </Text>
                  {formattedDate && (
                    <Text color="white" fontSize="0.8em">
                      {formattedDate}
                    </Text>
                  )}
                </Flex>
                <Divider my={5} />
              </Flex>
            );
          })}
        </Flex>
      </>
    </ResponsiveModal>
  );
};
