import { Flex, Heading, Select, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';
import {
  TournamentCategory,
  TournamentPool,
} from '../../../types/adminTournamentCategory.types';
import { MatchesPoolList } from './MatchesPoolList';

export const PoolPhaseMatches = ({
  chosenCategory,
  tournamentId,
}: {
  chosenCategory?: TournamentCategory;
  tournamentId: number;
}) => {
  const { translate } = useTranslation();

  const [poolId, setPoolId] = useState<number | null>(null);
  const { tournamentCategoryPoolsList } = useAdminTournaments(
    tournamentId,
    chosenCategory?.id,
  );

  const handleOnPoolSelect = (event: any) => {
    setPoolId(parseInt(event.target.value));
  };

  return (
    <Stack
      direction={['column']}
      spacing="24px"
      mt={10}
      borderWidth={1}
      borderRadius={10}
      p={5}
      backgroundColor="background.secondary"
    >
      <Flex justifyContent="space-between">
        <Heading
          fontSize={{ base: '2xl', md: '3xl' }}
          color="secondary.default"
        >
          {translate('POOL_PHASE_MATCHES', {
            category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
          })}
        </Heading>
      </Flex>
      {tournamentCategoryPoolsList?.length > 0 ? (
        <Flex flexDirection="column">
          <Select
            placeholder={translate('CHOOSE_POULE_IN', {
              category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
            })}
            color="white"
            onChange={handleOnPoolSelect}
          >
            {tournamentCategoryPoolsList?.map(
              (pool: TournamentPool, index: number) => {
                return (
                  <option key={index} value={pool?.id}>
                    {translate('POOL_NAME', {
                      name: pool?.name,
                    })}
                  </option>
                );
              },
            )}
          </Select>
          <MatchesPoolList poolId={poolId} />
        </Flex>
      ) : (
        <Text color="white">{translate('NO_POOL_IN_CATEGORY')}</Text>
      )}
    </Stack>
  );
};
