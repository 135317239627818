import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Admin } from './modules/admin/Admin';
import { AdminRouteGuard } from './modules/admin/components/AdminRouteGuard';
import AuthenticatedRouteGuard from './modules/auth/components/AuthenticationRouteGuard';
import { ForgotPassword } from './modules/auth/components/forgotPassword';
import { ProtectedRoute } from './modules/auth/components/ProtectedRoute';
import { ResetPassword } from './modules/auth/components/resetPassword';
import { SignIn } from './modules/auth/components/SignIn';
import { Signup } from './modules/auth/components/SignUp';
import { SocialAuthSuccess } from './modules/auth/components/SocialAuthSuccess';
import InformationsPage from './modules/common/components/InformationsPage';
import { WhatsappPage } from './modules/common/components/WhatsappPage';
import { FormatsList } from './modules/formats/components/FormatsList';
import { FormatsPage } from './modules/formats/components/FormatsPage';
import { Tournament } from './modules/formats/components/Tournaments/Tournament';
import { Tournaments } from './modules/formats/components/Tournaments/Tournaments';
import { Error } from './modules/home/components/Error';
import { Home } from './modules/home/components/Home';
import { Root } from './modules/home/components/Root';
import { PlayerDashboard } from './modules/player/components/PlayerDashboard';
import { RankingEquivalence } from './modules/player/components/RankingEquivalence';
import { UserProfile } from './modules/user/components/UserProfile';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: 'social-auth-success',
        element: <SocialAuthSuccess />,
      },
      {
        index: true,
        path: '/',
        element: (
          <AuthenticatedRouteGuard>
            <Home />
          </AuthenticatedRouteGuard>
        ),
      },
      {
        path: 'adminActivities',
        element: (
          //Can't access this route if user is logged in
          <AdminRouteGuard>
            <Admin />
          </AdminRouteGuard>
        ),
      },
      {
        path: 'signup',
        element: (
          //Can't access this route if user is logged in
          <AuthenticatedRouteGuard>
            <Signup />
          </AuthenticatedRouteGuard>
        ),
      },
      {
        path: 'signin',
        element: (
          //Can't access this route if user is logged in
          <AuthenticatedRouteGuard>
            <SignIn />
          </AuthenticatedRouteGuard>
        ),
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <PlayerDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'whatsapp',
        element: <WhatsappPage />,
      },
      {
        path: 'infos',
        element: <InformationsPage />,
      },
      {
        path: 'forgotPassword',
        element: <ForgotPassword />,
      },
      {
        path: 'resetPasword',
        element: <ResetPassword />,
      },
      {
        path: 'rankingEquivalence',
        element: (
          <ProtectedRoute>
            <RankingEquivalence />
          </ProtectedRoute>
        ),
      },
      {
        path: 'formats',
        element: (
          // <ProtectedRoute>
          <FormatsPage />
          // </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <FormatsList />,
          },
          {
            path: 'tournaments',
            element: <Tournaments />,
          },
          {
            path: 'tournaments/:id', // Dynamic route parameter for tournament ID
            element: <Tournament />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
