import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../translations/useTranslations';
import { PagesContainer } from '../../common/components/PagesContainer';
import { handleBackendError } from '../../common/services/handleBackendError';
import { useResetPassword } from '../hooks/useResetPassword';

const defaultFormFields = {
  password: '',
  confirmPassword: '',
};

export const ResetPassword = () => {
  const toast = useToast();
  const { translate } = useTranslation();
  const { resetPassword, isResetPasswordLoading } = useResetPassword();
  const [showPassword, setShowPassword] = useState(false);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resetPasswordToken = queryParams.get('resetPasswordToken');

  const { password, confirmPassword } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      toast({
        title: translate('DIFFERENT_PASSWORDS_TITLE'),
        description: translate('DIFFERENT_PASSWORDS_DESCRIPTION'),
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!resetPasswordToken) {
      toast({
        title: translate('NOT_RESET_PASSWORD_TOKEN'),
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const newPassword = password;
      await resetPassword({ resetPasswordToken, newPassword });
      toast({
        title: translate('PASSWORD_RESET_SUCCESS'),
        description: translate('PASSWORD_RESET_SUCCESS_SUBTITLE'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      resetFormFields();
      navigate('/signin');
    } catch (error) {
      handleBackendError(error, toast, translate);
      resetFormFields();
    }
  };

  const handleChange = (event: FormEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <PagesContainer>
      <Stack spacing={8} mx={'auto'} maxW={'lg'}>
        <Heading
          fontSize={{ base: '3xl', md: '5xl' }}
          color="secondary.default"
          textAlign="center"
        >
          {translate('RESET_PASSWORD_TITLE')}
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color="textColor.default"
          textAlign="center"
        >
          {translate('CREATE_NEW_PASSWORD')}
        </Text>
        <FormControl id="password" isRequired>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              name="password"
              value={password}
              placeholder={translate('PASSWORD')}
              border="none"
              backgroundColor="fourth.default"
              color="textColor.default"
            />
            <InputRightElement h={'full'}>
              <Button
                variant={'ghost'}
                color="textColor.default"
                _hover={{ bg: 'none' }}
                onClick={() => setShowPassword((showPassword) => !showPassword)}
              >
                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="confirmPassword" isRequired>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              name="confirmPassword"
              value={confirmPassword}
              placeholder={translate('CONFIRM_PASSWORD')}
              border="none"
              backgroundColor="fourth.default"
              color="textColor.default"
            />
            <InputRightElement h={'full'}>
              <Button
                variant={'ghost'}
                color="textColor.default"
                _hover={{ bg: 'none' }}
                onClick={() => setShowPassword((showPassword) => !showPassword)}
              >
                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg="primary.default"
            color={'white'}
            isLoading={isResetPasswordLoading}
            onClick={handleResetPassword}
          >
            {translate('SEND')}
          </Button>
        </Stack>
      </Stack>
    </PagesContainer>
  );
};
