import React, { useState } from 'react';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from '../../../../../translations/useTranslations';
import { TournamentCategory } from '../../../types/tournamentCategory.types';
import { MatchStatus } from './components/MatchStatus';
import { useTournamentBracket } from '../../../hooks/useTournamentBracket';
import { Match } from '../../../types/tournament.types';
import { formatDateToRead } from '../../../../../utils/utils';

interface AdminTournamentProps {
  chosenCategory?: TournamentCategory;
  tournamentId?: number;
}

export const TournamentBrackets = ({
  tournamentId,
  chosenCategory,
}: AdminTournamentProps) => {
  const { isDesktop } = useMediaQuery();
  const [currentPage, setCurrentPage] = useState(0);
  const { translate } = useTranslation();

  const roundsPerPage = isDesktop ? 3 : 1;
  3;

  const { tournamentCategoryBracket } = useTournamentBracket(
    tournamentId,
    chosenCategory?.id,
  );

  const handleLeftClick = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const handleRightClick = () => {
    setCurrentPage((prev) => Math.min(prev + 1, maxPage));
  };

  const handleFullLeftClick = () => {
    setCurrentPage(0);
  };

  const handleFullRightClick = () => {
    setCurrentPage(maxPage);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleRightClick,
    onSwipedRight: handleLeftClick,
    // @ts-ignore
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!tournamentCategoryBracket || tournamentCategoryBracket?.length === 0)
    return (
      <Flex color="white" justifyContent="center" mt={10}>
        {translate('BRACKETS_IN_CATEGORY_NOT_AVAILABLE', {
          category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
        })}
      </Flex>
    );

  const maxPage = tournamentCategoryBracket?.length - 1;

  const currentRounds: { name: string; matches: Match[] }[] =
    tournamentCategoryBracket?.slice(currentPage, currentPage + roundsPerPage);

  return (
    <Flex w="full" flexDirection="column" height="fit-content" mt={10}>
      {/* bracketpagination */}
      <Flex
        w="full"
        borderBottomWidth={1}
        py={2}
        mb={5}
        borderColor="background.third"
      >
        <Flex w="full" color="white">
          <Flex
            backgroundColor="background.third"
            p={3}
            borderRadius={10}
            _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
            onClick={handleFullLeftClick}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
          </Flex>
          <Flex
            backgroundColor="background.third"
            p={3}
            borderRadius={10}
            _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
            ml={1}
            onClick={handleLeftClick}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
          </Flex>
        </Flex>
        {isDesktop && (
          <Flex w="full" justifyContent="center" color="white">
            {Array.from({ length: maxPage + 1 }, (_, index) => {
              return (
                <Flex
                  backgroundColor={
                    currentPage === index
                      ? 'secondary.default'
                      : 'background.third'
                  }
                  px={5}
                  borderRadius={10}
                  ml={1}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setCurrentPage(index)}
                  _hover={{
                    cursor: 'pointer',
                    backgroundColor:
                      currentPage === index
                        ? 'secondary.default'
                        : 'background.fourth',
                  }}
                >
                  {index + 1}
                </Flex>
              );
            })}
          </Flex>
        )}
        <Flex w="full" justifyContent="flex-end" color="white">
          <Flex
            backgroundColor="background.third"
            p={3}
            borderRadius={10}
            _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
            onClick={handleRightClick}
          >
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
          </Flex>
          <Flex
            backgroundColor="background.third"
            p={3}
            borderRadius={10}
            _hover={{ cursor: 'pointer', backgroundColor: 'background.fourth' }}
            ml={1}
            onClick={handleFullRightClick}
          >
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
          </Flex>
        </Flex>
      </Flex>
      {/* bracketpagination */}
      <Flex justifyContent="center" height="fit-content" mt={10}>
        <Box borderRadius="md" w="100%">
          <Flex height="100%" {...swipeHandlers} w="100%">
            {currentRounds.map((round, index) => (
              <Round
                key={`${round?.name}-${index}`}
                name={round?.name}
                matches={round.matches}
                chosenCategory={chosenCategory}
              />
            ))}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

const Round = ({ name, matches, chosenCategory }: any) => {
  const { translate } = useTranslation();
  return (
    <Box height="100%" w="100%" maxW={500}>
      <Text fontSize="xl" textAlign="center" color="white">
        {translate(`${name}`)}
      </Text>
      <VStack spacing={5} height="100%" justifyContent="space-around">
        {matches.reduce((result: any, match: any, index: any, array: any) => {
          if (index % 2 === 0) {
            result.push(
              <RoundPair
                key={index}
                match1={match}
                match2={array[index + 1] || null}
                chosenCategory={chosenCategory}
              />,
            );
          }
          return result;
        }, [])}
      </VStack>
    </Box>
  );
};

const RoundPair = ({ match1, match2, chosenCategory }: any) => {
  console.log({ match1 });
  return (
    <Flex w="full" h="100%">
      <Flex
        direction="column"
        align="center"
        w="full"
        h="100%"
        justifyContent="space-around"
      >
        <MatchCard
          roundMatch={match1}
          isFirst
          chosenCategory={chosenCategory}
        />
        {match2 && (
          <MatchCard roundMatch={match2} chosenCategory={chosenCategory} />
        )}
      </Flex>
      {match1?.match?.nextMatchId && (
        <Flex h="100%" w={4} alignItems="center" ml={2}>
          <Flex
            h="50%"
            borderBottomWidth={2}
            borderBottomRightRadius={8}
            borderRightWidth={2}
            borderTopWidth={2}
            borderTopRightRadius={8}
            borderColor="background.fourth"
            w="100%"
          ></Flex>
        </Flex>
      )}
    </Flex>
  );
};

const MatchCard = ({ roundMatch }: any) => {
  const { translate } = useTranslation();
  const { isMobile } = useMediaQuery();

  const playerOne = roundMatch?.match?.teamA?.players?.[0];
  const playerTwo = roundMatch?.match?.teamA?.players?.[1];
  const playerThree = roundMatch?.match?.teamB?.players?.[0];
  const playerFour = roundMatch?.match?.teamB?.players?.[1];

  const isScoreVisible = roundMatch?.match?.winningTeamId;

  const isTeamAWinning =
    roundMatch?.match?.teamAId === roundMatch?.match?.winningTeamId;
  const isTeamBWinning =
    roundMatch?.match?.teamBId === roundMatch?.match?.winningTeamId;

  return (
    <HStack align="center" w="full">
      <Flex h="100%" w={4} alignItems="center">
        <Flex borderTopWidth={2} w="full" borderColor="background.fourth" />
      </Flex>
      <VStack w="full" alignItems="center" py={10} position="relative">
        <Flex position="absolute" top={5} color="white">
          <Text color="background.light1">
            {formatDateToRead(roundMatch?.match?.startDate)}
          </Text>
        </Flex>
        <Flex
          position="absolute"
          w="full"
          justifyContent="space-between"
          bottom={3}
        >
          <Flex>
            <MatchStatus status={roundMatch?.match?.matchStatus} />
          </Flex>
          <Flex>
            {roundMatch?.match?.courtName && (
              <Text color="textColor.secondary">
                {translate('COURT_TITLE', {
                  courtName: roundMatch?.match?.courtName,
                })}
              </Text>
            )}
          </Flex>
        </Flex>
        <HStack
          w="full"
          backgroundColor="background.third"
          p={1}
          borderRadius={10}
          color="background.light1"
          fontWeight="bold"
          justifyContent="space-between"
          minH={isMobile ? 50 : 65}
          maxH={isMobile ? 50 : 65}
        >
          <Box
            px={2}
            borderRadius="md"
            w="full"
            overflowX="hidden"
            textOverflow="ellipsis"
          >
            {playerOne && playerTwo ? (
              <Flex
                flexDirection="column"
                ml={5}
                fontSize={isMobile ? '0.8em' : '1.2em'}
                color={isTeamAWinning ? 'white' : 'background.light1'}
                isTruncated
              >
                <Text>{playerOne?.user?.displayName}</Text>
                <Text>{playerTwo?.user?.displayName}</Text>
              </Flex>
            ) : (
              <Text>{translate('TO_BE_DEFINED')}</Text>
            )}
          </Box>
          {isScoreVisible && (
            <MatchScore match={roundMatch?.match} isWinning={isTeamAWinning} />
          )}
        </HStack>
        <HStack
          w="full"
          backgroundColor="background.third"
          p={1}
          borderRadius={10}
          color="background.light1"
          fontWeight="bold"
          justifyContent="space-between"
          minH={isMobile ? 50 : 65}
          maxH={isMobile ? 50 : 65}
        >
          <Box
            px={2}
            borderRadius="md"
            w="full"
            overflowX="hidden"
            textOverflow="ellipsis"
          >
            {playerThree && playerFour ? (
              <Flex
                flexDirection="column"
                ml={5}
                fontSize={isMobile ? '0.8em' : '1.2em'}
                color={isTeamBWinning ? 'white' : 'background.light1'}
                isTruncated
              >
                <Text>{playerThree?.user?.displayName}</Text>
                <Text>{playerFour?.user?.displayName}</Text>
              </Flex>
            ) : (
              <Text>{translate('TO_BE_DEFINED')}</Text>
            )}
          </Box>
          {isScoreVisible && (
            <MatchScore match={roundMatch?.match} isWinning={isTeamBWinning} />
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};

const MatchScore = ({ match, isWinning }: any) => {
  const { isMobile } = useMediaQuery();
  return (
    <Flex
      w="full"
      justifyContent="space-around"
      alignItems="center"
      color={isWinning ? 'white' : 'textColor.default'}
    >
      <Flex position="relative">
        <Text fontSize={isMobile ? '0.8em' : '1.2em'}>
          {isWinning
            ? match?.matchScore?.set1WinningTeam || 0
            : match?.matchScore?.set1LosingTeam || 0}
        </Text>
        <Text
          position="absolute"
          top={-3}
          left={3}
          fontSize={isMobile ? '0.6em' : '0.8em'}
        >
          {isWinning
            ? match?.matchScore?.tieBreakSet1WinningTeam
            : match?.matchScore?.tieBreakSet1LosingTeam}
        </Text>
      </Flex>
      <Flex position="relative">
        <Text fontSize={isMobile ? '0.8em' : '1.2em'}>
          {isWinning
            ? match?.matchScore?.set2WinningTeam || 0
            : match?.matchScore?.set2LosingTeam || 0}
        </Text>
        <Text
          position="absolute"
          top={-3}
          left={3}
          fontSize={isMobile ? '0.6em' : '0.8em'}
        >
          {isWinning
            ? match?.matchScore?.tieBreakSet2WinningTeam
            : match?.matchScore?.tieBreakSet2LosingTeam}
        </Text>
      </Flex>
      <Flex position="relative">
        <Text fontSize={isMobile ? '0.8em' : '1.2em'}>
          {isWinning
            ? match?.matchScore?.set3WinningTeam || 0
            : match?.matchScore?.set3LosingTeam || 0}
        </Text>
        <Text
          position="absolute"
          top={-3}
          left={3}
          fontSize={isMobile ? '0.6em' : '0.8em'}
        >
          {isWinning
            ? match?.matchScore?.tieBreakSet3WinningTeam
            : match?.matchScore?.tieBreakSet3LosingTeam}
        </Text>
      </Flex>
    </Flex>
  );
};
