import { useQuery } from 'react-query';
import { get } from '../../common/services/HttpMethods';

export const useTournamentBracket = (
  tournamentId?: number,
  categoryId?: number,
) => {
  const tournamentsBracketQuery = useQuery<any>(
    [`tournament-${tournamentId}-category-${categoryId}-bracket`],
    async (): Promise<any> =>
      get(`/tournaments/${tournamentId}/categories/${categoryId}/bracket`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!categoryId,
    },
  );

  return {
    tournamentCategoryBracket: tournamentsBracketQuery.data,
    isTournamentCategoryBracketLoading: tournamentsBracketQuery.isLoading,
    refetchTournamentCategoryBracket: tournamentsBracketQuery.refetch,
  };
};
