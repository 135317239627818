import { useEffect } from 'react';
import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-8ZS28GZLLP';
ReactGA.initialize(TRACKING_ID);

const usePageView = (page: string, title: string) => {
  if (process.env.NODE_ENV === 'production') {
    if (!page || !title) return;
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: page, title: title });
    }, [page, title]);
  }
};

export default usePageView;
