import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';
import '@fontsource-variable/league-spartan';
import './index.css';
import { AuthProvider } from './contexts/AuthProvider';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0, // No retries for any query
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

const customTheme = extendTheme({
  colors: {
    primary: {
      default: '#062878',
    },
    secondary: {
      default: '#FDCC26',
      100: '#FEECAE',
    },
    third: {
      default: '#DF2935',
    },
    fourth: {
      default: '#2D2D2D',
    },
    fifth: {
      default: '#969696',
    },
    textColor: {
      default: '#CBD5E0',
      secondary: '#FDCC26',
      light: '#95acda',
    },
    background: {
      default: '#171C26',
      secondary: '#1F2633',
      third: '#2F384C',
      fourth: '#364259',
      light1: '#95ACDA',
      light2: '#60759F',
    },
    hover: {
      default: '#202632',
    },
  },
  fonts: {
    body: 'League Spartan Variable, sans-serif',
    heading: 'League Spartan Variable, sans-serif',
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </I18nextProvider>
      </ChakraProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
);
