import { ReactNode } from 'react';
import {
  Box,
  chakra,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import logo from '../../../assets/images/kwickers.png';
import { useNavigate } from 'react-router-dom';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Box
      bg="background.default"
      w="full"
      boxShadow="0px 1px 2px 1px rgba(0, 0, 0, 0.4)"
      color="white"
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}
      >
        <Image
          src={logo}
          alt="kwickers"
          boxSize="50px"
          objectFit="cover"
          borderRadius="full"
        />
        <Stack direction={['column', 'row']} spacing={6} textAlign="center">
          <Box onClick={() => navigate('/')} cursor="pointer" as="h1">
            Home
          </Box>
          <Box onClick={() => navigate('/formats')} cursor="pointer" as="h1">
            Formats
          </Box>
          <Box onClick={() => navigate('/rankings')} cursor="pointer" as="h1">
            Classements
          </Box>
          <Box onClick={() => navigate('/whatsapp')} cursor="pointer" as="h1">
            Recherche de partenaires
          </Box>
          <Box onClick={() => navigate('/infos')} cursor="pointer" as="h1">
            Plus d'infos
          </Box>
        </Stack>
      </Container>

      <Box borderStyle={'solid'} borderColor="gray.200">
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Text as="h1">© 2024 KWICKERS</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton
              label={'Instagram'}
              href={'https://www.instagram.com/kwickers.padel/'}
            >
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
