export interface RequestOptions {
  method: string;
  headers?: { [key: string]: string };
  body?: string;
  credentials?: RequestCredentials;
}

const baseHeaders = {
  'Content-Type': 'application/json',
};

const refreshToken = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/auth/refreshToken`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      },
    );
    const { data } = await response.json();
    if (response.ok) {
      setAuthTokenLocaleStorage(data.accessToken);
      return data.accessToken;
    } else {
      //User will be logged out
      removeAuthtokenFromLocaleStorage();
      window.location.reload();
      return null;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};

const handleResponse = async (response: Response) => {
  const responseData = await response.json();
  if (response.status === 401 && responseData.error === 'API_INVALID_TOKEN') {
    const newAccessToken = await refreshToken();
    if (newAccessToken) {
      return { error: false, tokenExpired: true, newAccessToken };
    } else {
      return { error: true, data: responseData };
    }
  } else if (!response.ok) {
    return { error: true, data: { status: response.status, ...responseData } };
  }
  return { error: false, data: responseData };
};

// Function to set the Bearer token to the local storage
export const setAuthTokenLocaleStorage = (token: string) => {
  window.localStorage.setItem('accessToken', token);
};

// Function to get the Bearer token from local storage
export const getAuthTokenFromLocalStorage = (): string | null => {
  return localStorage.getItem('accessToken');
};

// Function to get the Bearer token from local storage
export const removeAuthtokenFromLocaleStorage = () => {
  return localStorage.removeItem('accessToken');
};

// Function to create a query string from parameters
const createQueryString = (params?: { [key: string]: any }) => {
  if (!params) return '';
  const queryString = new URLSearchParams(params).toString();
  return queryString ? `?${queryString}` : '';
};

// Higher-order function to create generic API request functions
const createApiRequest =
  (method: string) =>
  async <T>(
    url: string,
    params?: any,
    contentType: 'json' | 'formData' = 'json',
  ): Promise<T> => {
    const token = getAuthTokenFromLocalStorage();
    const headers = token
      ? { ...baseHeaders, Authorization: `Bearer ${token}` }
      : baseHeaders;

    //Let browser set the header
    if (contentType === 'formData') {
      // @ts-ignore
      delete headers['Content-Type'];
    }

    const queryString = createQueryString(params);
    const fullUrl = `${process.env.REACT_APP_API_URL}/api${url}${queryString}`;

    const options: RequestOptions = {
      method,
      headers,
      // body: method !== 'GET' && params ? JSON.stringify(params) : undefined,
      body:
        contentType === 'json' && method !== 'GET'
          ? JSON.stringify(params)
          : params,

      credentials: 'include',
    };

    const res = await fetch(fullUrl, options);
    const result = await handleResponse(res);

    if (result.tokenExpired) {
      // Token was refreshed, retry the original request
      return createApiRequest(method)<T>(url, params, contentType);
    }

    if (result.error) {
      // Handle other errors
      throw result.data;
    }

    return result.data.data;
  };

// Create generic API functions for different HTTP methods
export const get = createApiRequest('GET');
export const post = createApiRequest('POST');
export const put = createApiRequest('PUT');
export const del = createApiRequest('DELETE'); // Add DELETE method if needed
