import { useMutation, useQuery } from 'react-query';
import { del, get, post, put } from '../../common/services/HttpMethods';
import { UpdatedUser, User } from '../types/user.types';

export const useUserProfile = (isAuthenticated: boolean) => {
  const userProfileQuery = useQuery<User>(
    'userProfile',
    async (): Promise<User> => get('/users/userProfile'),
    {
      staleTime: 500000,
      enabled: isAuthenticated,
    },
  );

  const updateProfileMutation = useMutation(
    (updatedUser: UpdatedUser): Promise<UpdatedUser> =>
      put('/users/userProfile', updatedUser),
  );

  const addProfilePictureMutation = useMutation(
    (formData: FormData): Promise<any> =>
      post('/users/userProfilePicture', formData, 'formData'),
  );

  const deleteProfilePictureMutation = useMutation(
    (): Promise<any> => del('/users/userProfilePicture'),
  );

  return {
    userProfile: userProfileQuery.data,
    refetchUserProfile: userProfileQuery.refetch,
    isUserProfileLoading: userProfileQuery.isLoading,
    userProfileError: userProfileQuery.error,
    updateUserProfile: updateProfileMutation.mutateAsync,
    isUpdateUserProfileLoading: updateProfileMutation.isLoading,
    addProfilePicture: addProfilePictureMutation.mutateAsync,
    isAddProfilePictureLoading: addProfilePictureMutation.isLoading,
    deleteProfilePicture: deleteProfilePictureMutation.mutateAsync,
    isDeleteProfilePictureLoading: deleteProfilePictureMutation.isLoading,
  };
};
