import { Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from '../../../../../translations/useTranslations';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import { useTournaments } from '../../../hooks/useTournaments';
import { useAuthContext } from '../../../../../contexts/AuthProvider';
import { useUserProfile } from '../../../../user/hooks/useUserProfile';
import { useNavigate } from 'react-router-dom';
import {
  getFormattedDate,
  isRegistrationClosed,
} from '../../../../../utils/utils';
import { SkeletonWrapper } from '../../../../common/components/SkeletonWrapper';

export const KwickersMasterList = () => {
  const { tournamentsList, isTournamentListLoading } = useTournaments();
  const { translate } = useTranslation();
  const { isDesktop } = useMediaQuery();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);

  const handleOpenTournament = (tournamentId: string) => {
    navigate(`/formats/tournaments/${tournamentId}`);
  };

  return (
    <Flex flexDirection="column">
      <SkeletonWrapper isLoading={isTournamentListLoading}>
        {tournamentsList?.length > 0 ? (
          tournamentsList?.map((tournament: any, index: number) => {
            const locale = userProfile?.locale ?? navigator.language;
            const isClosed =
              isRegistrationClosed(tournament?.registrationClosingDate) ||
              !tournament?.isOpen;

            return (
              <Flex
                key={index}
                w="full"
                mt={5}
                h={isDesktop ? '125px' : '80px'}
                _hover={{
                  cursor: 'pointer',
                  backgroundColor: 'hover.default',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                }}
                onClick={() => handleOpenTournament(tournament?.id)}
              >
                <Flex position="relative">
                  <Image
                    w={isDesktop ? '225px' : '140px'}
                    borderRadius={10}
                    objectFit="cover"
                    src={tournament?.tournamentImageUrl}
                  />
                  <Image
                    alt="kwickers"
                    boxSize={isDesktop ? '30px' : '20px'}
                    objectFit="cover"
                    borderRadius="full"
                    src={`${process.env.REACT_APP_CLOUDFRONT_URL}/static/kwickers.png`}
                    position="absolute"
                    top={2}
                    left={2}
                  />
                </Flex>
                <Flex
                  flexDir="column"
                  ml={isDesktop ? 5 : 2}
                  py={isDesktop ? 3 : 0}
                >
                  <Flex w="full">
                    <Text
                      textColor="textColor.default"
                      fontSize={isDesktop ? 'md' : 'sm'}
                    >
                      {getFormattedDate(
                        tournament?.startDate,
                        locale,
                        isDesktop,
                      )}
                    </Text>
                    <Text textColor="textColor.default" mx={2}>
                      -
                    </Text>
                    <Text
                      textColor="textColor.default"
                      fontSize={isDesktop ? 'md' : 'sm'}
                    >
                      {getFormattedDate(tournament?.endDate, locale, isDesktop)}
                    </Text>
                  </Flex>
                  <Text
                    textColor="white"
                    fontSize={isDesktop ? 'xl' : 'md'}
                    fontWeight="bold"
                    lineHeight={isDesktop ? 'auto' : 0.7}
                  >
                    {tournament.name}
                  </Text>
                  <Flex>
                    {isDesktop ? (
                      <Text textColor="textColor.default">
                        {tournament?.address}
                      </Text>
                    ) : (
                      <Text textColor="textColor.default" fontSize="sm">
                        {tournament?.location}
                      </Text>
                    )}
                  </Flex>
                  {isClosed ? (
                    <Flex
                      borderWidth={2}
                      px={4}
                      borderRadius="999"
                      w="fit-content"
                      borderColor="red"
                      color="red"
                      fontWeight="bold"
                      fontSize={isDesktop ? 'sm' : '0.7em'}
                      mt={isDesktop ? 1 : 0}
                    >
                      {translate('REGISTRATION_CLOSED')}
                    </Flex>
                  ) : (
                    <Flex
                      borderWidth={2}
                      px={4}
                      borderRadius="999"
                      w="fit-content"
                      borderColor="#16B2CA"
                      color="#16B2CA"
                      fontWeight="bold"
                      fontSize={isDesktop ? 'sm' : '0.7em'}
                      mt={isDesktop ? 1 : 0}
                    >
                      {translate('REGISTRATION_OPEN')}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            );
          })
        ) : (
          <Text
            textColor="white"
            fontSize="lg"
            fontWeight="bold"
            w="full"
            textAlign="center"
          >
            {translate('NO_TOURNAMENT_TITLE')}
          </Text>
        )}
      </SkeletonWrapper>
    </Flex>
  );
};
