import { useToast } from '@chakra-ui/react';
import { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthProvider';

export const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const { isAuthenticated } = useAuthContext();
  const toast = useToast();

  useEffect(() => {
    if (!isAuthenticated) {
      toast({
        title: 'Il faut être connecté pour accéder à cette page',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isAuthenticated, toast]);

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return children;
};
