'use client';

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ReactElement } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBoxesStacked, faMedal } from '@fortawesome/free-solid-svg-icons';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PagesContainer } from '../../common/components/PagesContainer';
import { useTranslation } from '../../../translations/useTranslations';
import usePageView from '../../common/hooks/GA4/usePageView';

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
  isFormatOpen: boolean;
  pageLink: string;
}

const Card = ({
  heading,
  description,
  icon,
  isFormatOpen,
  pageLink,
}: CardProps) => {
  usePageView('/formats', 'Formats page view');
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const handleOnClick = () => {
    navigate(pageLink);
  };

  return (
    <Box
      maxW={{ base: 'full', md: '300px' }}
      w={'full'}
      borderRadius={10}
      overflow="hidden"
      p={5}
      backgroundColor="background.secondary"
    >
      <Stack align={'start'} spacing={2}>
        <Flex
          align={'center'}
          justify={'center'}
          color={'textColor.secondary'}
          opacity={isFormatOpen ? 1 : 0.4}
          w="100%"
        >
          {icon}
        </Flex>
        <Box mt={2} w="100%" textAlign="center">
          <Heading
            size="lg"
            as="h1"
            opacity={isFormatOpen ? 1 : 0.4}
            color={'textColor.secondary'}
          >
            {heading}
          </Heading>
          <Text
            mt={1}
            fontSize={'md'}
            as="h3"
            color={'textColor.default'}
            opacity={isFormatOpen ? 1 : 0.4}
          >
            {description}
          </Text>
        </Box>
        <Button
          size="md"
          bg={'primary.default'}
          w="full"
          color={'white'}
          _hover={{
            bg: 'primary.default',
          }}
          type="submit"
          onClick={handleOnClick}
          isDisabled={!isFormatOpen}
        >
          {isFormatOpen
            ? translate('DISCOVER_TITLE')
            : translate('AVAILABLE_SOON')}
        </Button>
      </Stack>
    </Box>
  );
};

export const FormatsList = () => {
  const { translate } = useTranslation();
  return (
    <PagesContainer>
      <>
        <Stack spacing={4} textAlign={'center'}>
          <Heading
            fontSize={{ base: '3xl', md: '5xl' }}
            fontWeight={'bold'}
            color="secondary.default"
            as="h1"
          >
            {translate('COMPETITION_FORMATS_TITLE')}
          </Heading>
          <Text
            color={'textColor.default'}
            fontSize={{ base: 'sm', sm: 'lg' }}
            as="h3"
          >
            {translate('DISCOVER_OUR_COMPETITION_FORMATS')}
          </Text>
        </Stack>

        <Container maxW={'full'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center" w="full">
            <Card
              heading={translate('TOURNAMENTS_TITLE')}
              icon={<FontAwesomeIcon icon={faMedal} size="2xl" />}
              description={translate('POOL_PHASE_FINALE_PHASE_TITLE')}
              isFormatOpen={true}
              pageLink="tournaments"
            />
            <Card
              heading={translate('BOXES_TITLE')}
              icon={<FontAwesomeIcon icon={faBoxesStacked} size="2xl" />}
              description={translate('BOX_SUBTITLE')}
              isFormatOpen={false}
              pageLink="boxes"
            />
            <Card
              heading={translate('2VS2_TITLE')}
              icon={<FontAwesomeIcon icon={faCirclePlay} size="2xl" />}
              description={translate('SEARCH_PARTNERS_TITLE')}
              isFormatOpen={false}
              pageLink="/"
            />
          </Flex>
        </Container>
      </>
    </PagesContainer>
  );
};
