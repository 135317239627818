import {
  Container,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from '../../../../translations/useTranslations';
import { PagesContainer } from '../../../common/components/PagesContainer';
import usePageView from '../../../common/hooks/GA4/usePageView';
import { KwickersMasters } from './kwickersMasters/KwickersMasters';
// import { KwickersTour } from './kwickersTour/KwickersTour';

export const Tournaments = () => {
  usePageView('/formats/tournaments', 'Tournaments page view');
  const { translate } = useTranslation();

  return (
    <PagesContainer>
      <>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading
            fontSize={{ base: '3xl', sm: '5xl' }}
            fontWeight={'bold'}
            color="secondary.default"
            as="h1"
          >
            {translate('TOURNAMENTS')}
          </Heading>
        </Stack>
        <Flex m={1} mt={8} w="full">
          <Tabs w="full" variant="unstyled" size="lg">
            <TabList borderBottomWidth={0.6}>
              {/* <Tab
                _selected={{
                  color: 'secondary.default',
                  borderWidth: 1,
                  borderTopRadius: 5,
                }}
                color="textColor.default"
              >
                {translate('KWICKERS_TOUR_TITLE')}
              </Tab> */}
              <Tab
                _selected={{
                  color: 'secondary.default',
                  borderWidth: 1,
                  borderTopRadius: 5,
                }}
                color="textColor.default"
              >
                {translate('KWICKERS_MASTERS_TITLE')}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={0}>
                {/* <KwickersTour /> */}
                <KwickersMasters />
              </TabPanel>
              {/* <TabPanel p={0}>
                <KwickersMasters />
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Flex>
      </>
    </PagesContainer>
  );
};
