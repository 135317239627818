import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { MatchStatus as MatchStatusEnum } from '../../../../types/tournament.types';
import { useTranslation } from '../../../../../../translations/useTranslations';

const statusColors = {
  [MatchStatusEnum.ONGOING]: 'green',
  [MatchStatusEnum.COMPLETED]: 'purple',
  [MatchStatusEnum.SCHEDULED]: 'blue',
  [MatchStatusEnum.CANCELLED]: 'red',
};

interface MatchStatusProps {
  status?: MatchStatusEnum;
}

export const MatchStatus: React.FC<MatchStatusProps> = ({ status }) => {
  const { translate } = useTranslation();
  if (!status) return null;

  return (
    <Flex
      display="inline-block"
      borderRadius="999"
      backgroundColor={statusColors[status]}
      color="white"
      fontWeight="bold"
      px={2}
      justifyContent="center"
    >
      <Text fontSize="0.8em" as="span">
        {translate(`${status}`)}
      </Text>
    </Flex>
  );
};
