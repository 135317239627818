import React, { ReactNode, useEffect, useState } from 'react';
import { Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale/fr';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

interface DateTimePickerProps {
  title?: ReactNode;
  initialDate?: string | null;
  onDateChange: (date: string | null) => void;
}

const DateTimePicker = ({
  title,
  initialDate,
  onDateChange,
}: DateTimePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const formattedDate = selectedDate
    ? DateTime.fromJSDate(selectedDate)
        .setZone('Europe/Brussels')
        .toFormat("d LLLL yyyy 'à' HH:mm", {
          locale: 'fr',
        })
    : 'None';

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(DateTime.fromISO(initialDate).toJSDate());
    }
  }, [initialDate]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      // Convert the date to Brussels time zone and then to UTC
      const brusselsDate = DateTime.fromJSDate(date).setZone('Europe/Brussels');
      const utcDate = brusselsDate.toUTC().toISO();
      setSelectedDate(date);
      onDateChange(utcDate);
    }
  };

  return (
    <Flex w="full" mb={5}>
      <FormControl w="full">
        <FormLabel color="textColor.default">{title}</FormLabel>
        <Flex>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => handleDateChange(date)}
            showTimeSelect
            dateFormat="Pp"
            timeIntervals={15}
            locale={fr}
            customInput={<Input color="white" w="100%" />}
          />
          {selectedDate && (
            <Flex alignItems="center">
              <Flex mt={4} position="relative" bottom={2} ml={10}>
                <Text color="white">{formattedDate}</Text>
              </Flex>
              <Flex
                ml={3}
                cursor="pointer"
                onClick={() => {
                  onDateChange(null);
                  setSelectedDate(null);
                }}
              >
                <FontAwesomeIcon icon={faX} size="sm" color="red" />
              </Flex>
            </Flex>
          )}
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default DateTimePicker;
