import { useQuery } from 'react-query';
import { get } from '../../common/services/HttpMethods';

export const useSport = () => {
  const sportsListQuery = useQuery(
    'sportsList',
    async (): Promise<any> => get('/sports'),
    {
      staleTime: 500000,
    },
  );

  return {
    sportsList: sportsListQuery.data,
  };
};
