import { Avatar, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../../../translations/useTranslations';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import { useTournaments } from '../../../hooks/useTournaments';
import { TournamentTeam } from '../../../types/tournament.types';

type TournamentPlayerType = {
  id: number;
  elo: number;
  kwickersPoints: number;
  user: {
    displayName: string;
    lastname: string;
    firstname: string;
    profileImageName: string | null;
  };
  userId: number;
  playerEloAtRegistration: {
    id: number;
    playerId: number;
    elo: number;
    teamID: number;
  }[];
};

interface ITeamCardProps {
  team: TournamentTeam;
  playerOne: TournamentPlayerType;
  playerTwo: TournamentPlayerType;
}

export const TeamCard = ({ team, playerOne, playerTwo }: ITeamCardProps) => {
  const { isMobile } = useMediaQuery();
  const urlImagePlayerOne = useMemo(
    () =>
      `${process.env.REACT_APP_CLOUDFRONT_URL}/${playerOne?.user?.profileImageName}`,
    [playerOne],
  );
  const urlImagePlayerTwo = useMemo(
    () =>
      `${process.env.REACT_APP_CLOUDFRONT_URL}/${playerTwo?.user?.profileImageName}`,
    [playerTwo],
  );

  return (
    <Flex
      borderLeftWidth="15px"
      borderLeftColor="secondary.default"
      w="full"
      borderRadius={5}
      backgroundColor="background.secondary"
      mt={5}
    >
      <Flex flexDirection="column" w="full" position="relative">
        <Flex w="full" h="full" alignItems="center" p={4}>
          <Flex flexDirection="column" alignItems="center">
            <Avatar src={urlImagePlayerOne} w={4} h={4} />
            <Text fontWeight="bold" mt={1}>
              {playerOne?.user.displayName}
            </Text>
            <Text textAlign="center" fontSize="0.9em">
              {playerOne?.playerEloAtRegistration?.[0]?.elo}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Flex
            borderWidth={1}
            h={0}
            w="full"
            borderColor="background.third"
            ml={5}
          />
          <Flex
            borderWidth={2}
            borderRadius="full"
            minW={isMobile ? '60px' : '80px'}
            minH={isMobile ? '60px' : '80px'}
            alignItems="center"
            justifyContent="center"
            borderColor="background.third"
            backgroundColor="background.secondary"
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <Text
              fontSize={isMobile ? 'md' : 'xl'}
              color="secondary.default"
              fontWeight="bold"
            >
              {team?.eloTeam}
            </Text>
          </Flex>
          <Flex
            borderWidth={1}
            h={0}
            w="full"
            borderColor="background.third"
            mr={5}
          />
        </Flex>
        <Flex
          w="full"
          h="full"
          justifyContent="flex-end"
          p={4}
          alignItems="center"
        >
          <Flex flexDirection="column" alignItems="center">
            <Text textAlign="center" fontSize="0.9em">
              {playerTwo?.playerEloAtRegistration?.[0]?.elo}
            </Text>
            <Text fontWeight="bold" as="span">
              {playerTwo?.user.displayName}
            </Text>
            <Avatar src={urlImagePlayerTwo} w={4} h={4} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const TournamentTeams = () => {
  const { translate } = useTranslation();
  const { id } = useParams();
  const { tournament } = useTournaments(Number(id));
  const { isMobile } = useMediaQuery();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedGenderCategory, setSelectedGenderCategory] = useState<
    'MEN' | 'WOMEN' | null
  >('MEN');

  const categoriesList = tournament?.tournamentCategories ?? [];
  const menCategories = useMemo(
    () =>
      categoriesList.filter((category) => category.categoryGender === 'MEN'),
    [categoriesList],
  );
  const womenCategories = useMemo(
    () =>
      categoriesList.filter((category) => category.categoryGender === 'WOMEN'),
    [categoriesList],
  );

  const handleCategoryClick = (index: number) => {
    setSelectedGenderCategory(null);
    setSelectedCategory(index);
  };

  return (
    <Flex w="full" overflowX="hidden" flexDirection="column">
      <Stack direction={['column', 'row']} w="full">
        <Flex w="full" flexDirection="column">
          <Flex>
            <Heading
              fontSize={{ base: 'xl', md: '2xl' }}
              textAlign="center"
              color="white"
            >
              {translate('CATEGORIES_TITLE')}
            </Heading>
          </Flex>
          <Flex mt={5} flexDirection="column">
            <Flex overflow="scroll">
              <HStack
                spacing="15px"
                wrap={isMobile ? 'nowrap' : 'wrap'}
                w="fit-content"
                align="stretch"
              >
                <Flex
                  key="all-men"
                  borderWidth={1}
                  borderColor={
                    selectedGenderCategory === 'MEN'
                      ? 'secondary.default'
                      : '#2F384C'
                  }
                  color={
                    selectedGenderCategory === 'MEN'
                      ? 'secondary.default'
                      : 'textColor.default'
                  }
                  p={3}
                  borderRadius={5}
                  cursor="pointer"
                  w={150}
                  _hover={{
                    borderColor: 'secondary.default',
                    color: 'secondary.default',
                  }}
                  onClick={() => {
                    setSelectedCategory(null);
                    setSelectedGenderCategory('MEN');
                  }}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Text fontSize="0.8em" textAlign="center">
                    {translate('ALL_TITLE')}
                  </Text>
                  <Text fontSize="0.8em" textAlign="center">
                    {translate('MEN')}
                  </Text>
                </Flex>
                <Flex
                  key="all-women"
                  borderWidth={1}
                  borderColor={
                    selectedGenderCategory === 'WOMEN'
                      ? 'secondary.default'
                      : '#2F384C'
                  }
                  color={
                    selectedGenderCategory === 'WOMEN'
                      ? 'secondary.default'
                      : 'textColor.default'
                  }
                  p={3}
                  borderRadius={5}
                  cursor="pointer"
                  w={150}
                  _hover={{
                    borderColor: 'secondary.default',
                    color: 'secondary.default',
                  }}
                  onClick={() => {
                    setSelectedCategory(null);
                    setSelectedGenderCategory('WOMEN');
                  }}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Text fontSize="0.8em" textAlign="center">
                    {translate('ALL_TITLE')}
                  </Text>
                  <Text fontSize="0.8em" textAlign="center">
                    {translate('WOMEN')}
                  </Text>
                </Flex>
                {categoriesList.map((category, index) => (
                  <Flex
                    key={`cat-${category.id}`}
                    borderWidth={1}
                    borderColor={
                      index === selectedCategory
                        ? 'secondary.default'
                        : '#2F384C'
                    }
                    color={
                      index === selectedCategory
                        ? 'secondary.default'
                        : 'textColor.default'
                    }
                    p={3}
                    borderRadius={5}
                    flexDirection="column"
                    cursor="pointer"
                    w={150}
                    _hover={{
                      borderColor: 'secondary.default',
                      color: 'secondary.default',
                    }}
                    onClick={() => handleCategoryClick(index)}
                  >
                    <Text fontSize="0.8em" textAlign="center">
                      {`${translate(category.categoryGender)} - ${
                        category.categoryRanking
                      }`}
                    </Text>
                    <Text fontSize="0.7em" textAlign="center">
                      {`[${category.minElo} - ${category.maxElo}]`}
                    </Text>
                  </Flex>
                ))}
              </HStack>
            </Flex>
            <Flex mt={10} w="full" flexDirection="column">
              {selectedCategory !== null &&
                categoriesList[selectedCategory]?.teams.length === 0 && (
                  <Flex justifyContent="center">
                    {translate('NO_CATEGORY_TEAM_REGISTERED')}
                  </Flex>
                )}

              {selectedCategory !== null &&
                categoriesList[selectedCategory]?.teams.map(
                  (team: TournamentTeam) => (
                    <TeamCard
                      key={`${categoriesList[selectedCategory].id}-${team.id}`}
                      team={team}
                      playerOne={team.players[0]}
                      playerTwo={team.players[1]}
                    />
                  ),
                )}

              {/* DISPLAY ALL TEAMS IN SELECTED GENDER CATEGORIES */}
              {selectedCategory === null && selectedGenderCategory && (
                <>
                  {selectedGenderCategory &&
                    (selectedGenderCategory === 'MEN'
                      ? menCategories.every(
                          (category) => category.teams.length === 0,
                        )
                      : womenCategories.every(
                          (category) => category.teams.length === 0,
                        )) && (
                      <Flex justifyContent="center">
                        {translate('NO_CATEGORY_TEAM_REGISTERED')}
                      </Flex>
                    )}
                  {(selectedGenderCategory === 'MEN'
                    ? menCategories
                    : womenCategories
                  ).map((category, index) =>
                    category.teams.map((team: TournamentTeam) => (
                      <TeamCard
                        key={`${selectedGenderCategory}-${category.id}-${team.id}-${index}`}
                        team={team}
                        playerOne={team.players[0]}
                        playerTwo={team.players[1]}
                      />
                    )),
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  );
};
