import { Flex, Heading, Progress, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useTranslation } from '../../../translations/useTranslations';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useUserProfile } from '../../user/hooks/useUserProfile';
import { usePlayerProfile } from '../hooks/usePlayer';

export const MissingInfosCard = () => {
  const { isAuthenticated } = useAuthContext();
  const { translate } = useTranslation();
  const { userProfile, isUserProfileLoading } = useUserProfile(isAuthenticated);
  const { playerProfile, isPlayerProfileLoading } = usePlayerProfile(
    userProfile?.id,
  );
  const { isDesktop } = useMediaQuery();

  if (isUserProfileLoading || isPlayerProfileLoading) return null;

  if (userProfile?.isProfileComplete && playerProfile?.isEloCompleted) {
    return null;
  }

  return (
    <Flex
      py={10}
      w="100%"
      backgroundColor="#E9E6E6"
      borderRadius={6}
      mt={5}
      flexDirection={isDesktop ? 'row' : 'column'}
    >
      <Flex
        w={isDesktop ? '40%' : 'full'}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Heading fontSize={{ base: '5xl', sm: '5xl' }}>80 %</Heading>
        <Heading fontSize={{ base: 'xl', sm: 'xl' }}>
          {translate('PROFILE_COMPLETE_STATUS_TITLE')}
        </Heading>
      </Flex>
      <Flex w="full" flexDirection={'column'} px={5}>
        <Flex w="full">
          <Progress
            value={80}
            size="md"
            colorScheme="blue"
            w="100%"
            borderRadius={5}
            mt={isDesktop ? 0 : 5}
          />
        </Flex>
        <Flex mt={5} flexDirection="column">
          <Heading fontSize={{ base: 'xl', sm: 'xl' }} mb={3}>
            {translate('COMPLETE_PROFILE_TITLE')}
          </Heading>
          <Text>{translate('COMPLETE_PROFILE_SUBTITLE')}</Text>
        </Flex>
        <Flex mt={2} flexDirection="column">
          {userProfile?.missingFields.map((element) => (
            <Flex mt={2} alignItems="center">
              <Link to="/profile">
                <Text textDecoration="underline" color="blue">
                  {getMissingValue(element, translate)}
                </Text>
              </Link>
            </Flex>
          ))}
          {userProfile?.gender && !playerProfile?.isEloCompleted && (
            <Flex mt={2} alignItems="center">
              <Link to="/rankingEquivalence">
                <Text textDecoration="underline" color="blue">
                  {getMissingValue('elo', translate)}
                </Text>
              </Link>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const getMissingValue = (key: string, translate: (key: string) => string) => {
  switch (key) {
    case 'email':
      return translate('EMAIL_MISSING');
    case 'lastname':
      return translate('LASTNAME_MISSING');
    case 'firstname':
      return translate('FIRSTNAME_MISSING');
    case 'phoneNumber':
      return translate('PHONENUMBER_MISSING');
    case 'gender':
      return translate('GENDER_MISSING');
    case 'elo':
      return translate('ELO_MISSING');
    default:
      return translate('ELO_MISSING');
  }
};
