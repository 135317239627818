import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { TournamentCategory } from '../../../types/tournamentCategory.types';
import { TournamentCategories } from './components/TournamentCategories';
import { TournamentBrackets } from './TournamentBrackets';

interface TournamentFinalsProps {
  tournamentId: number;
}

export const TournamentFinals = ({ tournamentId }: TournamentFinalsProps) => {
  const [chosenCategory, setChosenCategory] = useState<TournamentCategory>();
  const handleSetChosenCategory = (category: TournamentCategory) => {
    setChosenCategory(category);
  };

  return (
    <Flex w="full" flexDirection="column" height="100%">
      <TournamentCategories
        tournamentId={tournamentId}
        setChosenCategory={handleSetChosenCategory}
      />
      <TournamentBrackets
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
      />
    </Flex>
  );
};
