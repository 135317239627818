import { Container, Heading, Text, Stack, Button } from '@chakra-ui/react';
import { useTranslation } from '../../../translations/useTranslations';
import { PagesContainer } from '../components/PagesContainer';
import usePageView from '../hooks/GA4/usePageView';

const whatsappCommunities = [
  {
    id: 1,
    title: 'BRUSSELS',
    link: 'https://chat.whatsapp.com/LdUXipzdxvrGgd3BLSxdEb',
  },
  {
    id: 2,
    title: 'NAMUR',
    link: 'https://chat.whatsapp.com/JeFEA1n6lLA8BMv4bw2Dvc',
  },
  {
    id: 3,
    title: 'LIEGE',
    link: 'https://chat.whatsapp.com/DYgMque6bvlDMwgnxOj1mD',
  },
  {
    id: 4,
    title: 'HAINAUT',
    link: 'https://chat.whatsapp.com/HKdkE8S2UNxEOlbTMuCbZU',
  },
  {
    id: 5,
    title: 'BRABANT_WALLON',
    link: 'https://chat.whatsapp.com/HnbRHmEm97tD8Lu2TwUeOG',
  },
  {
    id: 6,
    title: 'LUXEMBOURG',
    link: 'https://chat.whatsapp.com/ELmMyxGo9jpBeQojFbxMie',
  },
  {
    id: 7,
    title: 'ANVERS',
    link: 'https://chat.whatsapp.com/K8OEM0k6KYc5yAVnZMMJ4M',
  },
  {
    id: 8,
    title: 'FLANDRE_ORIENTALE',
    link: 'https://chat.whatsapp.com/Las7vl64RVP66auLo6dEPz',
  },
  {
    id: 9,
    title: 'FLANDRE_OCCIDENTALE',
    link: 'https://chat.whatsapp.com/IHx8lEHqYh9EeyzrAVmi37',
  },
  {
    id: 10,
    title: 'BRABANAT_FLAMMAND',
    link: 'https://chat.whatsapp.com/Kx8JnfKX0dMLSIKRyG0FFm',
  },
  {
    id: 11,
    title: 'LIMBOURG',
    link: 'https://chat.whatsapp.com/H4bQfxfNlqwEGaadIGo4QW',
  },
];

export const WhatsappPage = () => {
  usePageView('/whatsapp', 'Whatsapp page view');
  const { translate } = useTranslation();
  return (
    <PagesContainer>
      <>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading
            fontSize={{ base: '3xl', md: '5xl' }}
            color="secondary.default"
            fontWeight="bold"
            as="h1"
          >
            {translate('PARTNERS_SEARCH_TITLE')}
          </Heading>
          <Text color={'textColor.default'} fontSize={'xl'} as="h3">
            {translate('PARTNERS_SEARCH_INFOS')}
          </Text>
        </Stack>

        <Container maxW={'6xl'} mt={10} mb={10}>
          <Stack
            spacing={4}
            as={Container}
            maxW={'3xl'}
            textAlign={'center'}
            direction={['column', 'row']}
            wrap="wrap"
            justifyContent="center"
          >
            {whatsappCommunities.map((group, index) => {
              return (
                <Button
                  key={index}
                  px={10}
                  colorScheme="whatsapp"
                  fontSize="xl"
                  onClick={() => window.open(group?.link, '_blank')}
                >
                  {translate(group.title)}
                </Button>
              );
            })}
          </Stack>
        </Container>
      </>
    </PagesContainer>
  );
};
