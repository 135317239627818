import {
  Button,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from '../../../translations/useTranslations';
import { PagesContainer } from '../../common/components/PagesContainer';
import { handleBackendError } from '../../common/services/handleBackendError';
import { useResetPassword } from '../hooks/useResetPassword';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();
  const { translate } = useTranslation();
  const { updatePasswordRequest, isUpdatePasswordRequestLoading } =
    useResetPassword();

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setEmail(value);
  };

  const handleSendResetPasswordLink = async () => {
    if (!email) return;
    try {
      await updatePasswordRequest(email);
      toast({
        title: translate('EMAIL_SENT'),
        description: translate('NEW_PASSWORD_RESET_LINK_SENT'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setEmail('');
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
    setEmail('');
  };

  return (
    <PagesContainer>
      <Stack spacing={8} mx={'auto'} maxW={'lg'}>
        <Heading
          fontSize={{ base: '3xl', md: '5xl' }}
          color="secondary.default"
          textAlign="center"
        >
          {translate('FORGOTTEN_PASSWORD_TITLE')}
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color="textColor.default"
          textAlign="center"
        >
          {translate('FORGOTTEN_PASSWORD_SUBTITLE')}
        </Text>
        <FormControl id="email">
          <Input
            placeholder={translate('FORGOTTEN_PASSWORD_EXAMPLE')}
            _placeholder={{ color: 'gray.500' }}
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            color="textColor.default"
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg="primary.default"
            color={'white'}
            isLoading={isUpdatePasswordRequestLoading}
            onClick={handleSendResetPasswordLink}
          >
            {translate('SEND')}
          </Button>
        </Stack>
      </Stack>
    </PagesContainer>
  );
};
