import { Skeleton, Stack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface SkeletonWrapperProps {
  isLoading: boolean;
  children: ReactNode;
  count?: number;
  skeleton?: ReactNode;
}

export const SkeletonWrapper = ({
  isLoading,
  children,
  count = 1,
  skeleton,
}: SkeletonWrapperProps) => {
  if (isLoading) {
    return (
      <Stack w="full">
        {Array.from({ length: count }).map((_, index) =>
          skeleton ? (
            <React.Fragment key={index}>{skeleton}</React.Fragment>
          ) : (
            <Skeleton
              key={index}
              height="2"
              startColor="gray.600"
              endColor="gray.700"
              my={2}
            />
          ),
        )}
      </Stack>
    );
  }

  return <>{children}</>;
};
