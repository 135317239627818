import { useMutation, useQuery } from 'react-query';
import { get, put } from '../../common/services/HttpMethods';
import { Match } from '../types/adminTournament.types';

export const useAdminTournamentsMatches = (poolId?: number | null) => {
  const poulesMatchesByIdListQuery = useQuery<any>(
    [`pool-${poolId}-matches`],
    async (): Promise<Match[]> => get(`/admin/pools/${poolId}/matches`),
    {
      staleTime: 50000,
      enabled: !!poolId,
    },
  );

  const updateTournamentMatchMutation = useMutation(
    ({ matchId, updatedMatch }: any): Promise<any> =>
      put(`/admin/match/${matchId}`, updatedMatch),
  );

  const updateTournamentMatchTeamMutation = useMutation(
    ({ matchId, updatedMatch }: any): Promise<any> =>
      put(`/admin/match/${matchId}/team`, updatedMatch),
  );

  return {
    poulesMatchesByIdList: poulesMatchesByIdListQuery.data,
    isPoulesMatchesByIdLoading: poulesMatchesByIdListQuery.isLoading,
    refetchPoulesMatchesByIdList: poulesMatchesByIdListQuery.refetch,
    updateMatch: updateTournamentMatchMutation.mutateAsync,
    isUpdateMatchLoading: updateTournamentMatchMutation.isLoading,
    updateMatchTeam: updateTournamentMatchTeamMutation.mutateAsync,
    isUpdateMatchTeamLoading: updateTournamentMatchTeamMutation.isLoading,
  };
};
