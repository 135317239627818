import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../translations/useTranslations';
import { ResponsiveModal } from './ResponsiveModal';

export const JoinKwickersModal = ({ isModalOpen, onClose }: any) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <ResponsiveModal isOpen={isModalOpen} onClose={onClose} size="xl">
      <>
        <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
          {translate('MUST_BE_LOGGED_IN')}
        </Heading>

        <Flex mt={5}>
          <Button
            w="full"
            mr={2}
            backgroundColor="secondary.default"
            color="white"
            _hover={{
              backgroundColor: 'secondary.default',
              color: 'white',
            }}
            onClick={() => navigate('/signin')}
          >
            {translate('LOG_MYSELF_IN')}
          </Button>
          <Button w="full" ml={2} onClick={() => navigate('/signup')}>
            {translate('CREATE_ACCOUNT')}
          </Button>
        </Flex>
      </>
    </ResponsiveModal>
  );
};
