import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useUserProfile } from '../../user/hooks/useUserProfile';

export const AdminRouteGuard = ({ children }: { children: ReactElement }) => {
  const { isAuthenticated } = useAuthContext();
  const { userProfile } = useUserProfile(isAuthenticated);

  if (userProfile?.role !== 'ADMIN') {
    return <Navigate to="/" replace />;
  }

  return children;
};
