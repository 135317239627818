import { Flex, Spinner } from '@chakra-ui/react';

export const SpinnerLoading = ({ size = 'xl' }) => {
  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      backdropFilter="blur(5px)"
      zIndex={999}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="secondary.default"
        size={size}
      />
    </Flex>
  );
};
