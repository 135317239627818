import { Flex, Heading, Stack } from '@chakra-ui/react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { useAdminTournaments } from '../../../hooks/useAdminTournaments';

export const RegisteredPlayersByCategory = ({
  chosenCategory,
  tournamentId,
}: {
  chosenCategory: any;
  tournamentId: number;
}) => {
  const { translate } = useTranslation();
  const { tournamentTeams } = useAdminTournaments(
    tournamentId,
    chosenCategory?.id,
  );

  return (
    <Stack
      direction={['column']}
      spacing="24px"
      mt={10}
      borderWidth={1}
      borderRadius={10}
      p={5}
      backgroundColor="background.secondary"
    >
      <Flex justifyContent="space-between">
        <Heading
          fontSize={{ base: '2xl', md: '3xl' }}
          color="secondary.default"
        >
          {translate('REGISTERED_TEAMS', {
            category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
          })}
        </Heading>
      </Flex>
      <Flex justifyContent="center">
        <Flex w="80%" flexDirection="column">
          {tournamentTeams?.map((team: any, index) => {
            return (
              <Flex
                key={index}
                borderWidth={1}
                p={3}
                w="full"
                borderRadius={6}
                position="relative"
                mt={3}
              >
                {team?.players?.map((player: any, i: number) => {
                  return (
                    <Flex key={i} w="full" flexDirection="column">
                      <Flex color="textColor.secondary">
                        {player?.user?.displayName}
                      </Flex>
                      <Flex flexDirection="column" ml={10} color="white">
                        <Flex>{player?.user.email}</Flex>
                        <Flex>{player?.user?.phoneNumber}</Flex>
                      </Flex>
                    </Flex>
                  );
                })}
                <Flex
                  position="absolute"
                  fontSize="3xl"
                  color="textColor.secondary"
                  top={5}
                  left={-10}
                  fontWeight="bold"
                >
                  {index + 1}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Stack>
  );
};
