import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from '../../../../../translations/useTranslations';
import { computeNewElo } from '../../../../../utils/utils';
import { ResponsiveModal } from '../../../../common/components/ResponsiveModal';
import { handleBackendError } from '../../../../common/services/handleBackendError';
import { TournamentPlayer } from '../../../../formats/types/tournament.types';
import DateTimePicker from '../../../common/components/DateTimePicker';
import { MatchStatus } from '../../../common/components/MatchStatus';
import { useAdminTournamentsMatches } from '../../../hooks/useAdminTournamentMatches';
import { Match, TeamMatchStatus } from '../../../types/adminTournament.types';
import { MatchStatus as MatchStatusEnum } from '../../../types/adminTournament.types';

interface MatchModalProps {
  isOpen: boolean;
  onClose: () => void;
  match: Match | null;
  matchNumber?: number;
}

export type Inputs = {
  courtName: string;
  matchStatus: MatchStatusEnum;
  teamAMatchStatus: TeamMatchStatus | null;
  teamBMatchStatus: TeamMatchStatus | null;
  teamAScore: number[];
  teamBScore: number[];
  teamATieBreaks: number[];
  teamBTieBreaks: number[];
  winningTeamId: number | null;
  losingTeamId?: number | null;
};

const getSetScore = (match: any, teamType: any) => {
  if (!match || !match?.matchScore) return [null, null, null];

  const isTeamA = teamType === 'A';
  const isWinningTeam = isTeamA
    ? match?.teamA?.id === match?.winningTeamId
    : match?.teamB?.id === match?.winningTeamId;
  const isLosingTeam = isTeamA
    ? match?.teamA?.id === match?.losingTeamId
    : match?.teamB?.id === match?.losingTeamId;

  if (isWinningTeam) {
    return [
      match?.matchScore?.set1WinningTeam,
      match?.matchScore?.set2WinningTeam,
      match?.matchScore?.set3WinningTeam,
    ];
  } else if (isLosingTeam) {
    return [
      match?.matchScore?.set1LosingTeam,
      match?.matchScore?.set2LosingTeam,
      match?.matchScore?.set3LosingTeam,
    ];
  } else {
    return [null, null, null];
  }
};

const getTieBreaksScore = (match: any, teamType: any) => {
  if (!match || !match?.matchScore) return [null, null, null];

  const isTeamA = teamType === 'A';
  const isWinningTeam = isTeamA
    ? match?.teamA?.id === match?.winningTeamId
    : match?.teamB?.id === match?.winningTeamId;
  const isLosingTeam = isTeamA
    ? match?.teamA?.id === match?.losingTeamId
    : match?.teamB?.id === match?.losingTeamId;

  if (isWinningTeam) {
    return [
      match?.matchScore?.tieBreakSet1WinningTeam,
      match?.matchScore?.tieBreakSet2WinningTeam,
      match?.matchScore?.tieBreakSet3WinningTeam,
    ];
  } else if (isLosingTeam) {
    return [
      match?.matchScore?.tieBreakSet1LosingTeam,
      match?.matchScore?.tieBreakSet2LosingTeam,
      match?.matchScore?.tieBreakSet3LosingTeam,
    ];
  } else {
    return [null, null, null];
  }
};

export const MatchModal = ({
  isOpen,
  onClose,
  match,
  matchNumber,
}: MatchModalProps) => {
  const { translate } = useTranslation();
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);
  const toast = useToast();
  const { updateMatch, isUpdateMatchLoading, refetchPoulesMatchesByIdList } =
    useAdminTournamentsMatches(match?.tournamentPoolId);

  const { register, handleSubmit, reset, watch, setValue } = useForm<Inputs>(
    {},
  );

  const winningTeamId = watch('winningTeamId');

  useEffect(() => {
    if (winningTeamId && winningTeamId !== undefined) {
      const losingId =
        winningTeamId === match?.teamAId ? match?.teamBId : match?.teamAId;
      setValue('losingTeamId', losingId);
    } else {
      setValue('losingTeamId', null);
    }
  }, [winningTeamId, match?.teamAId, match?.teamBId, setValue]);

  useEffect(() => {
    if (match) {
      reset({
        courtName: match?.courtName,
        matchStatus: match?.matchStatus,
        teamAMatchStatus: match?.teamAStatus || null,
        teamBMatchStatus: match?.teamBStatus || null,
        teamAScore: getSetScore(match, 'A'),
        teamBScore: getSetScore(match, 'B'),
        teamATieBreaks: getTieBreaksScore(match, 'A'),
        teamBTieBreaks: getTieBreaksScore(match, 'B'),
        winningTeamId: match?.winningTeamId,
        losingTeamId: match?.losingTeamId,
      });
    }
  }, [isOpen, match]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const matchStatusOptions = Object.values(MatchStatusEnum).map((status) => ({
    value: status,
    label: status,
  }));

  const teamMatchStatusOptions = Object.values(TeamMatchStatus).map(
    (status) => ({
      value: status,
      label: status,
    }),
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // Handle form submission logic here
    const isTeamAWinning = data?.winningTeamId === match?.teamAId;

    const winningTeamScore = isTeamAWinning
      ? data?.teamAScore
      : data?.teamBScore;
    const losingTeamScore = isTeamAWinning
      ? data?.teamBScore
      : data?.teamAScore;
    const winningTeamTieBreaks = isTeamAWinning
      ? data?.teamATieBreaks
      : data?.teamBTieBreaks;
    const losingTeamTieBreaks = isTeamAWinning
      ? data?.teamBTieBreaks
      : data?.teamATieBreaks;

    try {
      await updateMatch({
        matchId: match?.id,
        updatedMatch: {
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          courtName: data?.courtName,
          matchStatus: data?.matchStatus,
          teamAStatus: data?.teamAMatchStatus,
          teamBStatus: data?.teamBMatchStatus,
          winningTeamId: data?.winningTeamId,
          losingTeamId: data?.losingTeamId,
          winningTeamScore: winningTeamScore,
          winningTeamTieBreaks: winningTeamTieBreaks,
          losingTeamScore: losingTeamScore,
          losingTeamTieBreaks: losingTeamTieBreaks,
          teamA: match?.teamA,
          teamB: match?.teamB,
        },
      });

      toast({
        title: translate('MATCH_EDITED_SUCCESSFULLY'),
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
      await refetchPoulesMatchesByIdList();
      handleClose();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  if (!match) return null;

  return (
    <ResponsiveModal isOpen={isOpen} onClose={handleClose} size="5xl">
      <>
        <Heading
          color="white"
          fontSize={{ base: '3xl', md: '3xl' }}
          textAlign="center"
        >
          {translate('MATCH_NUMBER', {
            number: matchNumber,
          })}
        </Heading>
        <Flex justifyContent="center" mt={2}>
          <MatchStatus status={match?.matchStatus} />
        </Flex>
        <Divider mt={5} />

        <form>
          <Flex mt={3}>
            <Flex w="full" flexDirection="column">
              <Flex w="full" justifyContent="center">
                <DateTimePicker
                  title={translate('START_TIME')}
                  onDateChange={(date) => setStartDateTime(date)}
                  initialDate={match?.startDate}
                />
                <DateTimePicker
                  title={translate('END_TIME')}
                  onDateChange={(date) => setEndDateTime(date)}
                  initialDate={match?.endDate}
                />
              </Flex>
              <Flex w="full" flexDirection="column">
                <FormControl mt={1}>
                  <FormLabel color="textColor.default">
                    {translate('MATCH_STATUS')}
                  </FormLabel>
                  <Select
                    placeholder="Select match status"
                    color="white"
                    // defaultValue={match?.matchStatus}
                    {...register('matchStatus')}
                  >
                    {matchStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {translate(option.label)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel color="textColor.default">
                    {translate('COURT_NAME_TITLE')}
                  </FormLabel>
                  <Input
                    color="white"
                    placeholder={translate('COURT_NAME_PLACEHOLDER')}
                    {...register('courtName')}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </Flex>
          {/* RIGHT MODAL PART */}
          <Flex w="full" flexDirection="column" p={3}>
            <Flex mt={1}>
              <Flex w="full" borderRightWidth={1} flexDirection="column" p={3}>
                <Text
                  textAlign="center"
                  color="secondary.default"
                  w="full"
                  fontSize="xl"
                  fontWeight="bold"
                >
                  {translate('TEAM_ONE_TITLE')}
                </Text>
                <Flex flexDirection="column" alignItems="center" mt={3}>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamA?.players?.[0]?.user?.displayName}`}</Text>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamA?.players?.[1]?.user?.displayName}`}</Text>
                </Flex>
                <Select
                  placeholder={translate('SELECT_TEAM_MATCH_STATUS')}
                  color="white"
                  defaultValue={match?.teamAStatus}
                  size="sm"
                  borderRadius={6}
                  mt={2}
                  {...register('teamAMatchStatus')}
                >
                  {teamMatchStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {translate(option.label)}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex w="full" flexDirection="column" p={3}>
                <Text
                  textAlign="center"
                  color="secondary.default"
                  w="full"
                  fontSize="xl"
                  fontWeight="bold"
                >
                  {translate('TEAM_TWO_TITLE')}
                </Text>
                <Flex flexDirection="column" alignItems="center" mt={3}>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamB?.players?.[0]?.user?.displayName}`}</Text>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamB?.players?.[1]?.user?.displayName}`}</Text>
                </Flex>
                <Select
                  placeholder={translate('SELECT_TEAM_MATCH_STATUS')}
                  color="white"
                  defaultValue={match?.teamBStatus}
                  size="sm"
                  borderRadius={6}
                  mt={2}
                  {...register('teamBMatchStatus')}
                >
                  {teamMatchStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {translate(option.label)}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          </Flex>
          {/* RESULT INPUTS  */}
          <Text
            color="secondary.default"
            fontSize="2xl"
            fontWeight="bold"
            mt={1}
          >
            {translate('RESULT_TITLE')}
          </Text>
          <Flex
            color="white"
            backgroundColor="background.default"
            p={3}
            borderRadius={6}
            flexDirection="column"
          >
            <Flex borderBottomWidth={1} w="full" pb={2}>
              <Flex flexDirection="column" w="full">
                <WinningProbability
                  eloTeamWinning={match?.teamA?.eloTeam}
                  eloTeamLosing={match?.teamB?.eloTeam}
                />
                <Flex>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamA?.players?.[0]?.user?.displayName}`}</Text>
                  <ElosChanges
                    match={match}
                    player={match?.teamA?.players?.[0] as TournamentPlayer}
                  />
                </Flex>
                <Flex>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamA?.players?.[1]?.user?.displayName}`}</Text>
                  <ElosChanges
                    match={match}
                    player={match?.teamA?.players?.[1] as TournamentPlayer}
                  />
                </Flex>
              </Flex>
              <Flex w="full" justifyContent="space-around" alignItems="center">
                <Flex position="relative">
                  <Input
                    w={10}
                    h={10}
                    fontSize="2xl"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamAScore.0')}
                  />
                  <Input
                    position="absolute"
                    left={12}
                    w={6}
                    h={6}
                    fontSize="sm"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamATieBreaks.0')}
                  />
                </Flex>
                <Flex position="relative">
                  <Input
                    w={10}
                    h={10}
                    fontSize="2xl"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamAScore.1')}
                  />
                  <Input
                    position="absolute"
                    left={12}
                    w={6}
                    h={6}
                    fontSize="sm"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamATieBreaks.1')}
                  />
                </Flex>
                <Flex position="relative">
                  <Input
                    w={10}
                    h={10}
                    fontSize="2xl"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamAScore.2')}
                  />
                  <Input
                    position="absolute"
                    left={12}
                    w={6}
                    h={6}
                    fontSize="sm"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamATieBreaks.2')}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <Flex flexDirection="column" pt={2} w="full">
                <Flex>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamB?.players?.[0]?.user?.displayName}`}</Text>
                  <ElosChanges
                    match={match}
                    player={match?.teamB?.players?.[0] as TournamentPlayer}
                  />
                </Flex>
                <Flex>
                  <Text
                    color="textColor.default"
                    fontWeight="bold"
                  >{`${match?.teamB?.players?.[1]?.user?.displayName}`}</Text>
                  <ElosChanges
                    match={match}
                    player={match?.teamB?.players?.[1] as TournamentPlayer}
                  />
                </Flex>
                <WinningProbability
                  eloTeamWinning={match?.teamB?.eloTeam}
                  eloTeamLosing={match?.teamA?.eloTeam}
                />
              </Flex>
              <Flex w="full" justifyContent="space-around" alignItems="center">
                <Flex position="relative">
                  <Input
                    w={10}
                    h={10}
                    fontSize="2xl"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamBScore.0')}
                  />
                  <Input
                    position="absolute"
                    left={12}
                    w={6}
                    h={6}
                    fontSize="sm"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamBTieBreaks.0')}
                  />
                </Flex>
                <Flex position="relative">
                  <Input
                    w={10}
                    h={10}
                    fontSize="2xl"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamBScore.1')}
                  />
                  <Input
                    position="absolute"
                    left={12}
                    w={6}
                    h={6}
                    fontSize="sm"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamBTieBreaks.1')}
                  />
                </Flex>
                <Flex position="relative">
                  <Input
                    w={10}
                    h={10}
                    fontSize="2xl"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamBScore.2')}
                  />
                  <Input
                    position="absolute"
                    left={12}
                    w={6}
                    h={6}
                    fontSize="sm"
                    textAlign="center"
                    type="number"
                    p={0}
                    {...register('teamBTieBreaks.2')}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex mt={3}>
            <FormControl mt={3}>
              <FormLabel color="textColor.default">
                {translate('SELECT_WINNING_TEAM')}
              </FormLabel>
              <Select
                placeholder={translate('SELECT_WINNING_TEAM')}
                color="white"
                defaultValue={match?.teamBStatus}
                size="sm"
                borderRadius={6}
                mt={2}
                {...register('winningTeamId', { valueAsNumber: true })}
              >
                <option
                  value={match?.teamAId}
                >{`${match?.teamA?.players?.[0]?.user?.displayName} & ${match?.teamA?.players?.[1]?.user?.displayName}`}</option>
                <option
                  value={match?.teamBId}
                >{`${match?.teamB?.players?.[0]?.user?.displayName} & ${match?.teamB?.players?.[1]?.user?.displayName}`}</option>
              </Select>
            </FormControl>
          </Flex>
          <Flex mt={5}>
            <Button
              w="full"
              mr={2}
              onClick={handleClose}
              isLoading={isUpdateMatchLoading}
            >
              {translate('CANCEL')}
            </Button>
            <Button
              w="full"
              ml={2}
              backgroundColor="secondary.default"
              color="white"
              _hover={{
                backgroundColor: 'secondary.default',
                color: 'white',
              }}
              onClick={handleSubmit(onSubmit)}
              isLoading={isUpdateMatchLoading}
            >
              {translate('SAVE')}
            </Button>
          </Flex>
        </form>
      </>
    </ResponsiveModal>
  );
};

const WinningProbability = ({
  eloTeamWinning,
  eloTeamLosing,
}: {
  eloTeamWinning: number | undefined;
  eloTeamLosing: number | undefined;
}) => {
  const { translate } = useTranslation();
  if (!eloTeamWinning || !eloTeamLosing) return null;

  const data = computeNewElo(eloTeamWinning, eloTeamLosing, 32);

  return (
    <Flex color="secondary.default">
      {translate('PROBABILITY_WINNING', {
        probability: (data?.probabilityWinning * 100).toFixed(2),
      })}
    </Flex>
  );
};

const ElosChanges = ({
  match,
  player,
}: {
  match: Match;
  player: TournamentPlayer | undefined;
}) => {
  if (!match || !player) return null;
  const matchEloPlayer = match?.playersElos?.find(
    (el) => el?.playerId === player?.id,
  );

  if (!matchEloPlayer) return null;

  const color =
    matchEloPlayer?.eloAfter > matchEloPlayer?.eloBefore ? 'green' : 'red';

  return (
    <Flex ml={5} alignItems="center">
      <Text mr={2} color="purple">
        {matchEloPlayer?.eloBefore}
      </Text>
      <FontAwesomeIcon icon={faArrowRight} size="sm" />
      <Text ml={2} color={color}>
        {matchEloPlayer?.eloAfter}
      </Text>
    </Flex>
  );
};
