import {
  Container,
  Heading,
  Text,
  Stack,
  Button,
  UnorderedList,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PagesContainer } from '../components/PagesContainer';
import usePageView from '../hooks/GA4/usePageView';
import useMediaQuery from '../hooks/useMediaQuery';

export default function InformationsPage() {
  usePageView('/infos', 'Informations page view');
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  return (
    <PagesContainer>
      <>
        <Heading
          fontSize={{ base: '3xl', md: '5xl' }}
          color="secondary.default"
          fontWeight="bold"
          textAlign="center"
          mb={5}
          as="h1"
        >
          Notre système de classement
        </Heading>
        <Stack
          spacing={4}
          as={Container}
          maxW={'3xl'}
          textAlign={isMobile ? 'left' : 'center'}
          color="textColor.default"
        >
          <Text fontSize={'xl'} textAlign="center">
            Kwickers Padel utilise le classement ELO comme classement de
            référence. Se mesurant en points, il offre une méthode efficace et
            équitable pour évaluer et comparer les compétences des équipes (et
            donc des joueurs).
          </Text>
          <Text fontSize={'xl'} textAlign="center">
            Il prend en compte l’écart de compétence entre deux équipes (et donc
            entre les joueurs). Si vous battez une équipe bien meilleure que
            vous, vous gagnerez beaucoup de points. Si vous perdez contre une
            équipe moins forte, vous perdrez des points.
          </Text>
          <Text fontSize={'xl'} textAlign="center">
            Nouveau chez Kwickers ? Un classement ELO vous sera attribué lors de
            votre inscription. Votre classement est renouvelé après chaque
            match. Il est plus précis, plus dynamique et donc plus représentatif
            de votre niveau réel et permettra d’assurer des matchs équitables,
            rendant la compétition plus juste et plus stimulante.
          </Text>
          <Text fontSize={'xl'} textAlign="center">
            Voici les conversions qui vous permettent d’obtenir un classement
            individuel et vous permettra, selon le classement de votre
            partenaire de jouer dans une catégorie spécifique.
          </Text>
        </Stack>
        <Flex
          justifyContent="center"
          color={'textColor.default'}
          fontWeight={700}
          fontSize={'xl'}
          mt={5}
        >
          <UnorderedList mt={3} mb={5} textAlign="center">
            <Text>Débutant(e) : 800 ELO</Text>
            <Text>P100 : 1000 ELO</Text>
            <Text>P200 : 1200 ELO</Text>
            <Text>P400 : 1600 ELO</Text>
            <Text>P500 : 1800 ELO</Text>
            <Text>P700 : 2000 ELO</Text>
            <Text>P1000 : 2200 ELO</Text>
            <Text>Professionnel(le) : 2400 ELO</Text>
          </UnorderedList>
        </Flex>

        <Flex justifyContent="center" mt={5}>
          <Button
            backgroundColor="secondary.default"
            color="white"
            fontWeight={700}
            w={300}
            _hover={{
              backgroundColor: 'secondary.default',
            }}
            onClick={() => navigate('/formats')}
          >
            Je veux jouer
          </Button>
        </Flex>

        <Stack
          spacing={4}
          as={Container}
          maxW={'3xl'}
          textAlign={isMobile ? 'left' : 'center'}
        >
          <Text
            mt={10}
            mb={4}
            textAlign="center"
            color="textColor.default"
            fontWeight="bold"
          >
            Si tu as des questions, tu peux nous contacter directement à
            l'adresse email suivante: kwickersbelgium@gmail.com
          </Text>
        </Stack>
      </>
    </PagesContainer>
  );
}
