import {
  Button,
  Flex,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useTranslation } from '../../../translations/useTranslations';
import { PagesContainer } from '../../common/components/PagesContainer';
import { ResponsiveModal } from '../../common/components/ResponsiveModal';
import { handleBackendError } from '../../common/services/handleBackendError';
import { useUserProfile } from '../../user/hooks/useUserProfile';
import { usePlayerProfile } from '../hooks/usePlayer';

const ladiesRankings = [
  {
    name: 'STARTER',
    category: 'BEGINNER_WOMEN',
    eloMin: null,
    eloMax: 999,
    description: ['BEGINNER_DESC_1', 'BEGINNER_DESC_2', 'BEGINNER_DESC_3'],
  },
  {
    name: 'F',
    category: 'P50',
    eloMin: 1000,
    eloMax: 1199,
    description: ['RUNNER_DESC_1', 'RUNNER_DESC_2', 'RUNNER_DESC_3'],
  },
  {
    name: 'E',
    category: 'P100',
    eloMin: 1200,
    eloMax: 1399,
    description: [
      'CHALLENGER_DESC_1',
      'CHALLENGER_DESC_2',
      'CHALLENGER_DESC_3',
      'CHALLENGER_DESC_4',
    ],
  },
  {
    name: 'D',
    category: 'P200',
    eloMin: 1400,
    eloMax: 1599,
    description: [
      'OUTSIDER_DESC_1',
      'OUTSIDER_DESC_2',
      'OUTSIDER_DESC_3',
      'OUTSIDER_DESC_4',
      'OUTSIDER_DESC_5',
    ],
  },
  {
    name: 'C',
    category: 'P300',
    eloMin: 1600,
    eloMax: 1799,
    description: [
      'CSERIES_DESC_1',
      'CSERIES_DESC_2',
      'CSERIES_DESC_3',
      'CSERIES_DESC_4',
    ],
  },
  {
    name: 'B',
    category: 'P500',
    eloMin: 1800,
    eloMax: 1999,
    description: [
      'BSERIES_DESC_1',
      'BSERIES_DESC_2',
      'BSERIES_DESC_3',
      'BSERIES_DESC_4',
    ],
  },

  // {
  //   name: 'A',
  //   category: 'P700',
  //   eloMin: 2000,
  //   eloMax: 2199,
  //   description: [
  //     'ASERIES_DESC_1',
  //     'ASERIES_DESC_2',
  //     'ASERIES_DESC_3',
  //     'ASERIES_DESC_4',
  //   ],
  // },
  // {
  //   name: 'ULTRA',
  //   category: 'P1000',
  //   eloMin: 2200,
  //   eloMax: null,
  //   description: [
  //     'ULTRA_DESC_1',
  //     'ULTRA_DESC_2',
  //     'ULTRA_DESC_3',
  //     'ULTRA_DESC_4',
  //   ],
  // },
];

const menRankings = [
  {
    name: 'STARTER',
    category: 'BEGINNER_MEN',
    eloMin: null,
    eloMax: 999,
    description: ['BEGINNER_DESC_1', 'BEGINNER_DESC_2', 'BEGINNER_DESC_3'],
  },
  {
    name: 'F',
    category: 'P100',
    eloMin: 1000,
    eloMax: 1199,
    description: ['RUNNER_DESC_1', 'RUNNER_DESC_2', 'RUNNER_DESC_3'],
  },
  {
    name: 'E',
    category: 'P200',
    eloMin: 1200,
    eloMax: 1399,
    description: [
      'CHALLENGER_DESC_1',
      'CHALLENGER_DESC_2',
      'CHALLENGER_DESC_3',
      'CHALLENGER_DESC_4',
    ],
  },
  {
    name: 'D',
    category: 'P300',
    eloMin: 1400,
    eloMax: 1599,
    description: [
      'OUTSIDER_DESC_1',
      'OUTSIDER_DESC_2',
      'OUTSIDER_DESC_3',
      'OUTSIDER_DESC_4',
      'OUTSIDER_DESC_5',
    ],
  },
  {
    name: 'C',
    category: 'P400',
    eloMin: 1600,
    eloMax: 1799,
    description: [
      'CSERIES_DESC_1',
      'CSERIES_DESC_2',
      'CSERIES_DESC_3',
      'CSERIES_DESC_4',
    ],
  },
  {
    name: 'B',
    category: 'P500',
    eloMin: 1800,
    eloMax: 1999,
    description: [
      'BSERIES_DESC_1',
      'BSERIES_DESC_2',
      'BSERIES_DESC_3',
      'BSERIES_DESC_4',
    ],
  },

  {
    name: 'A',
    category: 'P700',
    eloMin: 2000,
    eloMax: 2199,
    description: [
      'ASERIES_DESC_1',
      'ASERIES_DESC_2',
      'ASERIES_DESC_3',
      'ASERIES_DESC_4',
    ],
  },
  {
    name: 'ULTRA',
    category: 'P1000',
    eloMin: 2200,
    eloMax: null,
    description: [
      'ULTRA_DESC_1',
      'ULTRA_DESC_2',
      'ULTRA_DESC_3',
      'ULTRA_DESC_4',
    ],
  },
];

const RankingCardInfos = ({ ranking, handleConfirmRanking }: any) => {
  const { translate } = useTranslation();
  return (
    <Flex
      p={5}
      borderWidth={1}
      borderColor="#2F384C"
      borderRadius={10}
      flexDirection="column"
      w={300}
      minHeight="150px"
      _hover={{
        borderColor: 'secondary.default',
        cursor: 'pointer',
      }}
      onClick={() => handleConfirmRanking(ranking)}
    >
      <Flex
        mt={2}
        flexDirection="column"
        justifyContent="space-between"
        h="100%"
      >
        <Flex flexDirection="column">
          <Text
            color="third.default"
            fontWeight="bold"
            textAlign="center"
            fontSize="xl"
          >
            {translate(ranking?.name)}
          </Text>
          <Text color="white" textAlign="center" fontSize="xl">
            {translate(ranking?.category)}
          </Text>
          {!ranking?.eloMin && (
            <Text color="white" textAlign="center" fontSize="sm">
              {`<= ${ranking?.eloMax} ELO`}
            </Text>
          )}
          {!ranking?.eloMax && (
            <Text color="white" textAlign="center" fontSize="sm">
              {` >= ${ranking?.eloMin} ELO`}
            </Text>
          )}
          {ranking?.eloMin && ranking.eloMax && (
            <Text color="white" textAlign="center" fontSize="sm">
              {`${ranking?.eloMin} - ${ranking?.eloMax} ELO`}
            </Text>
          )}
          <UnorderedList color="textColor.default" mt={3}>
            {ranking?.description.map((description: string, index: number) => {
              return <ListItem key={index}>{translate(description)}</ListItem>;
            })}
          </UnorderedList>
        </Flex>
        <Button
          backgroundColor="secondary.default"
          color="white"
          mt={5}
          _hover={{
            backgroundColor: 'secondary.default',
          }}
        >
          {ranking.category === 'BEGINNER_MEN' &&
            translate('I_AM_CATEGORY_BEGGINER_MEN')}
          {ranking.category === 'BEGINNER_WOMEN' &&
            translate('I_AM_CATEGORY_BEGGINER_WOMEN')}
          {ranking.category !== 'BEGINNER_MEN' &&
            ranking.category !== 'BEGINNER_WOMEN' &&
            translate('I_AM_CATEGORY', {
              categoryName: ranking?.category,
            })}
        </Button>
      </Flex>
    </Flex>
  );
};

export const RankingEquivalence = () => {
  const { isAuthenticated } = useAuthContext();
  const { translate } = useTranslation();
  const { userProfile } = useUserProfile(isAuthenticated);
  const { playerProfile } = usePlayerProfile(userProfile?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chosenRanking, setChosenRanking] = useState<any>(null);
  const { updatePlayerProfile, isPlayerProfileLoading } = usePlayerProfile();
  const toast = useToast();
  const navigate = useNavigate();

  if (playerProfile?.elo && playerProfile?.elo !== 0) {
    navigate('/dashboard');
  }

  const handleSetPlayerElo = async () => {
    try {
      await updatePlayerProfile({
        elo: chosenRanking?.eloMin === null ? 800 : chosenRanking?.eloMin,
      });
      toast({
        title: translate('PLAYER_RANKING_SET'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsModalOpen(false);
      navigate('/dashboard');
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  return (
    <PagesContainer>
      <>
        <Stack align={'center'}>
          <Heading
            fontSize={'4xl'}
            textAlign={'center'}
            color="secondary.default"
            as="h1"
          >
            {translate('YOUR_RANKING_TITLE')}
          </Heading>
          <Text fontSize={'lg'} color="textColor.default" textAlign="center">
            {translate('CHOOSE_RANKING_DESCRIPTION')}
          </Text>
          <Text fontSize={'lg'} color="textColor.default" textAlign="center">
            {translate('CHOOSE_RANKING_DESCRIPTION_WARNING')}
          </Text>
        </Stack>
        {userProfile?.gender === 'MEN' ? (
          <Stack
            direction="row"
            spacing="15px"
            wrap="wrap"
            w="fit-content"
            align="stretch"
            justifyContent="center"
            mt={5}
          >
            {menRankings.map((ranking, index) => {
              return (
                <RankingCardInfos
                  key={index}
                  ranking={ranking}
                  handleConfirmRanking={(ranking: any) => {
                    setChosenRanking(ranking);
                    setIsModalOpen(true);
                  }}
                />
              );
            })}
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing="15px"
            wrap="wrap"
            w="fit-content"
            align="stretch"
            justifyContent="center"
            mt={5}
          >
            {ladiesRankings.map((ranking, index) => {
              return (
                <RankingCardInfos
                  key={index}
                  ranking={ranking}
                  handleConfirmRanking={(ranking: any) => {
                    setChosenRanking(ranking);
                    setIsModalOpen(true);
                  }}
                />
              );
            })}
          </Stack>
        )}
        <ResponsiveModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size="xl"
        >
          <Heading color="white" fontSize={{ base: 'xl', md: '2xl' }}>
            {translate('CONFIRM_RANKING_TITLE')}
          </Heading>
          <Text textColor="textColor.default" mt={5} textAlign="center">
            {translate('CONFIRM_RANKING_SUBTITLE', {
              categoryName: chosenRanking?.name,
            })}
          </Text>
          <Flex>
            <Button
              backgroundColor="#384257"
              color="white"
              mt={5}
              _hover={{
                backgroundColor: 'none',
              }}
              mr={5}
              w="full"
              onClick={() => {
                setChosenRanking(null);
                setIsModalOpen(false);
              }}
              isLoading={isPlayerProfileLoading}
            >
              {translate('CANCEL')}
            </Button>
            <Button
              backgroundColor="secondary.default"
              color="white"
              mt={5}
              _hover={{
                backgroundColor: 'secondary.default',
              }}
              w="full"
              onClick={handleSetPlayerElo}
              isLoading={isPlayerProfileLoading}
            >
              {translate('I_CONFIRM')}
            </Button>
          </Flex>
        </ResponsiveModal>
      </>
    </PagesContainer>
  );
};
