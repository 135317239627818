import { useMutation, useQuery } from 'react-query';
import { del, get, post, put } from '../../common/services/HttpMethods';
import {
  Inputs,
  SportCategory,
} from '../components/sportCategories/AdminSportCategories';

export const useSportCategories = () => {
  const getSportsCategoriesListQuery = useQuery(
    'sportsCategoriesList',
    async (): Promise<SportCategory[]> => get('/admin/sportsCategories'),
    {
      staleTime: 500000,
    },
  );

  const createSportCategoryMutation = useMutation(
    (newSportCategory: Inputs): Promise<any> =>
      post('/admin/sportsCategories', newSportCategory),
  );

  const updateSportCategoryMutation = useMutation(
    ({ categoryId, updatedCategory }: any): Promise<any> =>
      put(`/admin/sportsCategories/${categoryId}`, updatedCategory),
  );

  const deleteSportCategoryMutation = useMutation(
    (categoryId: number): Promise<any> =>
      del(`/admin/sportsCategories/${categoryId}`),
  );

  return {
    createSportCategory: createSportCategoryMutation.mutateAsync,
    isCreateSportCategoryLoading: createSportCategoryMutation.isLoading,
    sportsCategoriesList: getSportsCategoriesListQuery.data,
    isSportsCategoriesListLoading: getSportsCategoriesListQuery.isLoading,
    refetchCategoriesList: getSportsCategoriesListQuery.refetch,
    deleteSportCategory: deleteSportCategoryMutation.mutateAsync,
    isDeleteSportCategoryLoading: deleteSportCategoryMutation.isLoading,
    updateSportCategory: updateSportCategoryMutation.mutateAsync,
  };
};
