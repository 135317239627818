import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useTranslation } from '../../../../translations/useTranslations';

interface IDeleteModalProp {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  title: ReactElement | string;
  content: ReactElement | string;
}

export const DeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
  title,
  content,
}: IDeleteModalProp) => {
  const { translate } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center">{content}</ModalBody>

        <ModalFooter>
          <Button
            variant="outline"
            w="full"
            mr={2}
            onClick={onClose}
            isLoading={isLoading}
          >
            {translate('CANCEL')}
          </Button>
          <Button
            bg={'red'}
            color={'white'}
            _hover={{
              bg: 'red',
            }}
            w="full"
            ml={2}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {translate('CONFIRM')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
