import { Button, Flex, Heading, Text, Image } from '@chakra-ui/react';
import KwickersPadelBackgroundMobile from '../../../assets/images/KwickersPadelBackgroundMobile.png';
import PadelPlayerOne from '../../../assets/images/padelPlayer1.webp';
import PadelBackgroundTwo from '../../../assets/images/padelBackground2.jpg';
import PadelBackgroundFour from '../../../assets/images/padelBackground4.jpg';
import AboutWhite from '../../../assets/images/aboutWhite.png';
import YellowArrow from '../../../assets/images/yellowArrow.png';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import usePageView from '../../common/hooks/GA4/usePageView';
import { useTranslation } from '../../../translations/useTranslations';

export const Home = () => {
  usePageView('/', 'Home page view');
  const { isDesktop } = useMediaQuery();
  const navigate = useNavigate();

  const handleRedirectSignin = () => {
    navigate('/signin');
  };

  const handleRedirectSignUp = () => {
    navigate('/signup');
  };

  return (
    <>
      {isDesktop ? (
        <DesktopVersion
          handleRedirectSignin={handleRedirectSignin}
          handleRedirectSignUp={handleRedirectSignUp}
          navigate={navigate}
        />
      ) : (
        <MobileVersion
          handleRedirectSignin={handleRedirectSignin}
          handleRedirectSignUp={handleRedirectSignUp}
          navigate={navigate}
        />
      )}
    </>
  );
};

interface IProps {
  handleRedirectSignin: () => void;
  handleRedirectSignUp: () => void;
  navigate: any;
}

const DesktopVersion = ({
  handleRedirectSignin,
  handleRedirectSignUp,
  navigate,
}: IProps) => {
  const { translate } = useTranslation();
  return (
    <>
      <Flex
        flexGrow={1}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT_URL}/static/KwickersPadelBackground.png)`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        h="calc(100vh)"
        padding={20}
        paddingTop={5}
      >
        <Flex
          mt={{ base: 10, md: 100 }}
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
        >
          <Flex flexDirection="column">
            <Heading size="2xl" color="secondary.default">
              {translate('SEASON_TITLE')}
            </Heading>
            <Heading size="4xl" color="white" as="h1">
              {translate('KWICKERS_PADEL')}
            </Heading>
            <Heading size="xl" color="secondary.default" as="h1">
              {translate('THE_PLACE_TO_PLAY')}
            </Heading>
          </Flex>
          <Flex
            justifyContent="center"
            mb={50}
            flexDirection="column"
            alignItems="center"
          >
            <>
              <Button
                size="lg"
                fontSize="2xl"
                bg="secondary.default"
                color={'white'}
                _hover={{
                  bg: 'secondary.default',
                }}
                onClick={handleRedirectSignin}
              >
                {translate('LOG_IN')}
              </Button>
              <Text
                color="white"
                textDecoration="underline"
                fontSize="xl"
                mt={3}
                cursor="pointer"
                onClick={handleRedirectSignUp}
              >
                {translate('CREATE_ACCOUNT')}
              </Text>
            </>
          </Flex>
        </Flex>
      </Flex>
      <Flex backgroundColor="background.default" py={10} position="relative">
        <Flex w="40%" flexDirection="column" alignItems="center">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={49}
            px={2}
            border="2px solid white"
            borderRadius={50}
          >
            <Heading as="h1" color="white" size="lg">
              {translate('KWICKERS_TITLE')}
            </Heading>
            <Heading color="white" size="md" as="h1">
              {translate('PADEL_TITLE')}
            </Heading>
          </Flex>
          <Heading size="3xl" color="secondary.default" mt={10} mb={10} as="h2">
            {translate('ABOUT_US')}
          </Heading>
          <Flex
            w="80%"
            borderRadius={10}
            color="white"
            p={10}
            backgroundColor="#262D3E"
          >
            <Text fontSize="xl">
              <Text fontWeight={700} as="h1">
                {translate('KWICKERS_PADEL_SMALL')}
              </Text>
              {translate('KWICKERS_VALUE_PROPOSITION')}
              <br />
              <br />
              <Text color="secondary.default" fontWeight={700} as="h2">
                {translate('KWICKERS_CENTRALISED_LEAGUE_TITLE')}
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex w="60%" justifyContent="center">
          <Flex w="85%">
            <Image src={PadelPlayerOne} objectFit="cover" />
          </Flex>
        </Flex>
        <Flex position="absolute" right={7} height="90%">
          <Image src={AboutWhite} />
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Flex p={10}>
          <Flex w="70%">
            <Flex w="100%" m={5} flexDirection="column">
              <Text
                color="primary.default"
                fontSize="2xl"
                fontWeight={700}
                as="h2"
              >
                {translate('SEARCHING_FOR_PARTNERS')}
              </Text>
              <Image src={YellowArrow} w={100} />
              <Flex
                cursor="pointer"
                h={400}
                style={{
                  backgroundImage: `url(${PadelBackgroundTwo})`,

                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                flexDirection="column"
                onClick={() => navigate('/whatsapp')}
              >
                <Text
                  fontSize="7xl"
                  color="white"
                  fontWeight="bold"
                  p={5}
                  lineHeight="70px"
                  as="h1"
                >
                  {translate('KWICKERS_TITLE_SMALL')}
                  <br />
                  {translate('PARTNERS_TITLE')}
                </Text>
              </Flex>
            </Flex>
            <Flex w="100%" m={5} flexDirection="column">
              <Flex
                cursor="pointer"
                h={400}
                style={{
                  backgroundImage: `url(${PadelBackgroundFour})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                justifyContent="flex-end"
                alignItems="flex-end"
                onClick={() => navigate('/formats')}
              >
                <Text
                  fontSize="7xl"
                  color="white"
                  fontWeight="bold"
                  p={5}
                  lineHeight="70px"
                  as="h1"
                >
                  {translate('KWICKERS_TITLE_SMALL')}
                  <br />
                  {translate('LEAGUE_TITLE')}
                </Text>
              </Flex>
              <Image
                src={YellowArrow}
                w={100}
                transform="rotate(-100deg)"
                ml={40}
                alignSelf="center"
              />
              <Text
                color="primary.default"
                fontSize="2xl"
                fontWeight={700}
                position="relative"
                bottom={8}
                as="h2"
              >
                {translate('WANT_TO_PLAY_MATCHES')}
              </Text>
            </Flex>
          </Flex>
          <Flex w="30%" flexDirection="column">
            <Flex
              justifyContent="center"
              alignItems="center"
              position="relative"
              top={150}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                py={49}
                px={2}
                border="2px solid #062878"
                borderRadius={50}
              >
                <Heading as="h1" color="primary.default" size="lg">
                  {translate('KWICKERS_TITLE')}
                </Heading>
                <Heading as="h1" color="primary.default" size="md">
                  {translate('PADEL_TITLE')}
                </Heading>
              </Flex>
            </Flex>
            <Flex>
              <Text
                color="secondary.default"
                fontSize="130px"
                fontWeight={700}
                position="relative"
                top={250}
                lineHeight="120px"
                as="h1"
              >
                {translate('SPORT_IS_EVOLVING')}
                <br /> {translate('SPORT_IS_EVOLVING_SO_ARE_YOU')}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex w="100%" backgroundColor="background.default" p={10}>
          <Flex w="50%">
            <Text color="white" fontSize="2xl" fontWeight="bold" as="h2">
              {translate('WELCOME_ON_KWICKERS_INFOS')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const MobileVersion = ({
  handleRedirectSignin,
  handleRedirectSignUp,
  navigate,
}: IProps) => {
  const { translate } = useTranslation();

  return (
    <>
      <Flex
        flexGrow={1}
        style={{
          backgroundImage: `url(${KwickersPadelBackgroundMobile})`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        h="calc(100vh)"
      >
        <Flex w="100%" flexDirection="column" justifyContent="space-between">
          <Flex flexDirection="column" mt={100} alignItems="center" w="100%">
            <Heading size="lg" color="secondary.default" as="h1">
              {translate('SEASON_TITLE')}
            </Heading>
            <Heading size="2xl" color="white" as="h1">
              {translate('KWICKERS_PADEL')}
            </Heading>
            <Heading
              size="sm"
              color="secondary.default"
              textAlign="center"
              as="h1"
            >
              {translate('THE_PLACE_TO_PLAY')}
            </Heading>
          </Flex>
          <Flex flexDirection="column" alignItems="center" mb={130}>
            <>
              <Button
                size="lg"
                fontSize="2xl"
                bg="secondary.default"
                color={'white'}
                _hover={{
                  bg: 'secondary.default',
                }}
                onClick={handleRedirectSignin}
              >
                {translate('LOG_IN')}
              </Button>
              <Text
                color="white"
                textDecoration="underline"
                fontSize="xl"
                mt={3}
                cursor="pointer"
                onClick={handleRedirectSignUp}
              >
                {translate('CREATE_ACCOUNT')}
              </Text>
            </>
          </Flex>
        </Flex>
      </Flex>

      {/* ///////////////////// */}

      <Flex flexDirection="column">
        <Flex p={10} flexDirection="column">
          <Text color="primary.default" fontSize="2xl" fontWeight={700}>
            {translate('SEARCHING_FOR_PARTNERS')}
          </Text>
          <Image src={YellowArrow} w={70} />
          <Flex
            h={250}
            cursor="pointer"
            style={{
              backgroundImage: `url(${PadelBackgroundTwo})`,

              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            flexDirection="column"
            onClick={() => navigate('/whatsapp')}
          >
            <Text
              fontSize="5xl"
              color="white"
              fontWeight="bold"
              p={5}
              lineHeight="50px"
              as="h1"
            >
              {translate('KWICKERS_TITLE_SMALL')}
              <br />
              {translate('PARTNERS_TITLE')}
            </Text>
          </Flex>
        </Flex>
        <Flex backgroundColor="background.default" p={10}>
          <Text
            color="white"
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            as="h2"
          >
            {translate('WELCOME_ON_KWICKERS_INFOS')}
          </Text>
        </Flex>
        <Flex p={10} flexDirection="column">
          <Flex
            cursor="pointer"
            h={250}
            style={{
              backgroundImage: `url(${PadelBackgroundFour})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            justifyContent="flex-end"
            alignItems="flex-end"
            onClick={() => navigate('/formats')}
          >
            <Text
              fontSize="5xl"
              color="white"
              fontWeight="bold"
              p={5}
              lineHeight="50px"
              as="h1"
            >
              {translate('KWICKERS_TITLE_SMALL')}
              <br />
              {translate('LEAGUE_TITLE')}
            </Text>
          </Flex>
          <Image
            src={YellowArrow}
            w={70}
            transform="rotate(-100deg)"
            alignSelf="center"
            ml={100}
          />
          <Text color="primary.default" fontSize="2xl" fontWeight={700} as="h2">
            {translate('WANT_TO_PLAY_MATCHES')}
          </Text>
        </Flex>
      </Flex>

      {/* /////////////////////////// */}
      <Flex>
        <Flex w="100%">
          <Image src={PadelPlayerOne} objectFit="cover" />
        </Flex>
      </Flex>
      <Flex backgroundColor="background.default" py={10} flexDirection="column">
        <Flex flexDirection="column" alignItems="center">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={49}
            px={3}
            border="2px solid white"
            borderRadius={50}
          >
            <Heading color="white" size="xl" as="h1">
              {translate('KWICKERS_TITLE')}
            </Heading>
            <Heading color="white" size="md" as="h1">
              {translate('PADEL_TITLE')}
            </Heading>
          </Flex>
          <Heading size="3xl" color="secondary.default" mt={10} mb={10} as="h2">
            {translate('ABOUT_US')}
          </Heading>
          <Flex
            w="90%"
            borderRadius={10}
            color="white"
            p={5}
            textAlign="center"
            backgroundColor="#262D3E"
          >
            <Text fontSize="xl">
              <Text fontWeight={700} as="h1">
                {translate('KWICKERS_PADEL_SMALL')}
              </Text>
              {translate('KWICKERS_VALUE_PROPOSITION')}
              <br />
              <Text color="secondary.default" fontWeight={700} as="h2">
                {translate('KWICKERS_CENTRALISED_LEAGUE_TITLE')}
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
