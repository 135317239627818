import { FormEvent, useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  Center,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { PagesContainer } from '../../common/components/PagesContainer';
import { handleBackendError } from '../../common/services/handleBackendError';
import { useTranslation } from '../../../translations/useTranslations';
import { useAuthContext } from '../../../contexts/AuthProvider';
import i18n from '../../../translations/i18n';
import usePageView from '../../common/hooks/GA4/usePageView';

const defaultFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export const Signup = () => {
  usePageView('/signup', 'Sign up page view');
  const [showPassword, setShowPassword] = useState(false);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const toast = useToast();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { registerUser, isRegisterUserLoading } = useAuthContext();

  const { firstName, lastName, email, password, confirmPassword } = formFields;

  const handleChange = (event: FormEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: translate('DIFFERENT_PASSWORDS_TITLE'),
        description: translate('DIFFERENT_PASSWORDS_DESCRIPTION'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      await registerUser({
        email: email,
        lastname: lastName,
        firstname: firstName,
        password: password,
        confirmPassword: confirmPassword,
        locale: i18n.language,
      });
      navigate('/dashboard');
      toast({
        title: translate('WELCOME_TO_KWICKERS'),
        description: translate('VERIFICATION_EMAIL_SENT'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      resetFormFields();
    } catch (error) {
      handleBackendError(error, toast, translate);
    }
  };

  const signInWithGoogle = async () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/auth/google`, '_self');
  };

  return (
    <PagesContainer>
      <form onSubmit={(e) => handleSubmitForm(e)}>
        <Flex align={'center'} justify={'center'}>
          <Stack spacing={8} mx={'auto'} maxW={'lg'}>
            <Stack align={'center'}>
              <Heading
                fontSize={{ base: '3xl', md: '5xl' }}
                textAlign={'center'}
                color="secondary.default"
              >
                {translate('CREATE_ACCOUNT')}
              </Heading>
              <Text
                fontSize={'lg'}
                color="textColor.default"
                textAlign="center"
              >
                {translate('READY_TO_JOIN')}
              </Text>
            </Stack>
            <Box p={8} pb={0}>
              <Stack spacing={4} color="textColor.default">
                <HStack>
                  <Box>
                    <FormControl id="firstName" isRequired>
                      <Input
                        type="text"
                        onChange={handleChange}
                        name="firstName"
                        value={firstName}
                        placeholder={translate('FIRST_NAME')}
                        border="none"
                        backgroundColor="fourth.default"
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="lastName" isRequired>
                      <Input
                        type="text"
                        onChange={handleChange}
                        name="lastName"
                        value={lastName}
                        placeholder={translate('LAST_NAME')}
                        border="none"
                        backgroundColor="fourth.default"
                      />
                    </FormControl>
                  </Box>
                </HStack>
                <FormControl id="email" isRequired>
                  <Input
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={email}
                    placeholder={translate('EMAIL_ADDRESS')}
                    border="none"
                    backgroundColor="fourth.default"
                  />
                </FormControl>
                <FormControl id="password" isRequired>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      name="password"
                      value={password}
                      placeholder={translate('PASSWORD')}
                      border="none"
                      backgroundColor="fourth.default"
                    />
                    <InputRightElement h={'full'}>
                      <Button
                        variant={'ghost'}
                        color="textColor.default"
                        _hover={{ bg: 'none' }}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl id="confirmPassword" isRequired>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      name="confirmPassword"
                      value={confirmPassword}
                      placeholder={translate('CONFIRM_PASSWORD')}
                      border="none"
                      backgroundColor="fourth.default"
                    />
                    <InputRightElement h={'full'}>
                      <Button
                        variant={'ghost'}
                        color="textColor.default"
                        _hover={{ bg: 'none' }}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Stack spacing={5} pt={2}>
                  <Button
                    loadingText={translate('CREATE_ACCOUNT_LOADING')}
                    size="lg"
                    bg={'primary.default'}
                    color={'white'}
                    _hover={{
                      bg: 'primary.default',
                    }}
                    type="submit"
                    isLoading={isRegisterUserLoading}
                  >
                    {translate('REGISTER')}
                  </Button>
                  <Text textAlign="center">{translate('OR')}</Text>
                  <Button
                    w={'full'}
                    maxW={'md'}
                    variant={'outline'}
                    leftIcon={<FcGoogle />}
                    onClick={signInWithGoogle}
                    type="button"
                    color="textColor.default"
                    _hover={{
                      bg: 'none',
                      color: 'secondary.default',
                      borderColor: 'secondary.default',
                    }}
                  >
                    <Center>
                      <Text>{translate('SIGN_IN_WITH_GOOGLE')}</Text>
                    </Center>
                  </Button>
                </Stack>
                <Stack pt={6}>
                  <Text align={'center'}>
                    {translate('ALREADY_MEMBER')}
                    <br />
                    <Link to="/signin">
                      <Text
                        color="secondary.default"
                        fontWeight="bold"
                        textDecoration="underline"
                      >
                        {translate('SIGN_IN')}
                      </Text>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </form>
    </PagesContainer>
  );
};
