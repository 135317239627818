import React, { ReactNode, FC } from 'react';

import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerProps as ChakraDrawerProps,
} from '@chakra-ui/modal';

export interface DrawerProps extends ChakraDrawerProps {
  children: ReactNode;
  header?: ReactNode;
  closeButtonDisabled?: boolean;
}

export const Drawer: FC<DrawerProps> = ({
  children,
  header,
  placement,
  closeButtonDisabled,
  ...props
}) => {
  return (
    <ChakraDrawer placement={placement} variant={placement} {...props}>
      <DrawerOverlay onClick={props.onClose} />
      <DrawerContent
        backgroundColor="background.secondary"
        paddingBottom={4}
        borderRadius={10}
      >
        <DrawerHeader>
          {header}
          {!closeButtonDisabled && (
            <DrawerCloseButton
              color="textColor.light"
              _hover={{
                color: 'white',
              }}
            />
          )}
        </DrawerHeader>
        <DrawerBody paddingX={4}>{children}</DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};
