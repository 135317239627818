import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslation from './__translations__/en.json';
import frenchTranslation from './__translations__/fr.json';
import dutchTranslation from './__translations__/nl.json';
import sourceTranslation from './source.json';

const resources = {
  en:
    process.env.NODE_ENV === 'development'
      ? sourceTranslation
      : englishTranslation,
  fr: frenchTranslation,
  nl: dutchTranslation,
};

const browserLanguage = navigator.language;

i18n
  .use(initReactI18next) // for react
  .init({
    resources,
    lng: browserLanguage,
    fallbackLng: {
      'fr-BE': ['fr'],
      'en-US': ['en'],
      default: ['en'],
    },
    debug: process.env.NODE_ENV === 'development', // Enable debugging mode only in development
    interpolation: {
      escapeValue: false, // React already escapes strings
    },
  });

export default i18n;
