import { useQuery } from 'react-query';
import { get } from '../../common/services/HttpMethods';

export const useTournamentMatches = (
  tournamentId?: number,
  poolId?: number,
) => {
  const tournamentMatcheByPoolId = useQuery(
    [`client-matches-tournament-${tournamentId}-${poolId}-matches`],
    async (): Promise<any> =>
      get(`/tournaments/${tournamentId}/pools/${poolId}/matches`),
    {
      staleTime: 50000,
      enabled: !!tournamentId && !!poolId,
    },
  );

  return {
    tournamentMatchesByPoolIdList: tournamentMatcheByPoolId.data,
    isTournamentMatchesByPoolIdLoading: tournamentMatcheByPoolId.isLoading,
    refetchTournamentMatchesByPoolIdList: tournamentMatcheByPoolId.refetch,
  };
};
