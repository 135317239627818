import { Flex, FlexProps } from '@chakra-ui/react';
import { ReactElement } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

export const PagesContainer = ({
  flexProps = {},
  children,
}: {
  flexProps?: FlexProps;
  children: ReactElement;
}) => {
  const { isDesktop } = useMediaQuery();
  return (
    <Flex
      padding={isDesktop ? 40 : 5}
      paddingTop={100}
      flexDirection="column"
      backgroundColor="background.default"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};
