import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { TournamentCategory } from '../../../types/tournamentCategory.types';
import { CategoryPoolsList } from './components/CategoryPoolList';
import { TournamentCategories } from './components/TournamentCategories';

interface TournamentPoolsProps {
  tournamentId: number;
}

export const TournamentPools = ({ tournamentId }: TournamentPoolsProps) => {
  const [chosenCategory, setChosenCategory] = useState<TournamentCategory>();
  const handleSetChosenCategory = (category: TournamentCategory) => {
    setChosenCategory(category);
  };

  return (
    <Flex w="full" overflowX="hidden" flexDirection="column">
      <TournamentCategories
        tournamentId={tournamentId}
        setChosenCategory={handleSetChosenCategory}
      />
      <CategoryPoolsList
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
      />
    </Flex>
  );
};
