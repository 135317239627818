import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from '../../../../../translations/useTranslations';
import { TournamentCategory } from '../../../types/adminTournamentCategory.types';
import { AddCategoryPoolModal } from './AddCategoryPoolModal';
import { CategoryPoolsList } from './CategoryPoolsList';

export const PoolPhase = ({
  chosenCategory,
  tournamentId,
}: {
  chosenCategory?: TournamentCategory;
  tournamentId: number;
}) => {
  const { translate } = useTranslation();
  const [isAddPoolOpen, setIsAddPoolOpen] = useState(false);
  return (
    <Stack
      direction={['column']}
      spacing="24px"
      mt={10}
      borderWidth={1}
      borderRadius={10}
      p={5}
      backgroundColor="background.secondary"
    >
      <Flex justifyContent="space-between">
        <Heading
          fontSize={{ base: '2xl', md: '3xl' }}
          color="secondary.default"
        >
          {translate('POOL_PHASE', {
            category: `${chosenCategory?.categoryGender} ${chosenCategory?.categoryRanking}`,
          })}
        </Heading>
        {chosenCategory && chosenCategory?.teams?.length > 0 && (
          <Button
            leftIcon={<AddIcon />}
            w="fit-content"
            backgroundColor="secondary.default"
            size="sm"
            color="white"
            _hover={{
              backgroundColor: 'none',
            }}
            onClick={() => setIsAddPoolOpen(true)}
          >
            {translate('ADD_POULE_IN', {
              category: `${chosenCategory?.categoryGender} - ${chosenCategory?.categoryRanking}`,
            })}
          </Button>
        )}
      </Flex>
      <CategoryPoolsList
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
      />
      <AddCategoryPoolModal
        isOpen={isAddPoolOpen}
        onClose={() => setIsAddPoolOpen(false)}
        chosenCategory={chosenCategory}
        tournamentId={tournamentId}
      />
    </Stack>
  );
};
