import { TournamentCategory } from './tournamentCategory.types';

export type Tournament = {
  id: number;
  name: string;
  address: string;
  isOpen: boolean;
  location: string;
  startDate: string;
  endDate: string;
  registrationClosingDate: string;
  priceCents: number;
  teams: TournamentTeam[];
  tournamentCategories: TournamentCategory[];
  tournamentImageName: string;
  tournamentImageUrl: string;
  tournamentRulesUrl: string;
  tournamentTypeId: number;
};

export type TournamentTeam = {
  id: number;
  eloTeam: number;
  tournamentCategoryId: number;
  tournamentId: number;
  players: TournamentPlayer[];
  playersEloAtRegistration?: {
    id: number;
    playerId: number;
    elo: number;
    teamID: number;
  }[];
};

export type TournamentPlayer = {
  id: number;
  elo: number;
  kwickersPoints: number;
  userId: number;
  user: {
    id: number;
    email: string;
    displayName: string;
    firstname: string;
    lastname: string;
    profileImageName: string;
  };
  playerEloAtRegistration: {
    id: number;
    playerId: number;
    elo: number;
    teamID: number;
  }[];
};

export type PlayerPayment = {
  id: number;
  hasPaid: boolean;
  paymentDate: Date;
  teamId: number;
  userId: number;
};

export type PlayerTeam = {
  id: number;
  createdAt: Date;
  eloTeam: number;
  players: TournamentPlayer[];
  playersPayments: PlayerPayment[];
  tournamentCategory: TournamentCategory;
  tournamentCategoryId: number;
  tournamentId: number;
  paymentStatus: 'PAID' | 'WAITING';
};

export enum MatchStatus {
  SCHEDULED = 'SCHEDULED',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum TeamMatchStatus {
  INJURY = 'INJURY',
  WALK_OVER = 'WALK_OVER',
  ABSENT = 'ABSENT',
  GAVE_UP = 'GAVE_UP',
  CHECKED_IN = 'CHECKED_IN',
}

export type Match = {
  id: number;
  tournamentId: number;
  tournamentPoolId: number;
  teamAId: number;
  teamBId: number;
  winningTeamId?: number;
  losingTeamId?: number;
  matchStatus?: MatchStatus;
  teamAStatus?: TeamMatchStatus;
  teamBStatus?: TeamMatchStatus;
  startDate?: string;
  endDate?: string;
  nextMatchId?: number;
  courtName?: string;
  matchScore?: MatchScore;
  teamA?: PlayerTeam;
  teamB?: PlayerTeam;
  playersElos?: {
    id: number;
    matchId: number;
    eloBefore: number;
    eloAfter: number;
    playerId: number;
    teamId: number;
  }[];
};

export type MatchScore = {
  matchId: number;
  set1WinningTeam?: number;
  tieBreakSet1WinningTeam?: number;
  set1LosingTeam?: number;
  tieBreakSet1LosingTeam?: number;
  set2WinningTeam?: number;
  tieBreakSet2WinningTeam?: number;
  set2LosingTeam?: number;
  tieBreakSet2LosingTeam?: number;
  set3WinningTeam?: number;
  tieBreakSet3WinningTeam?: number;
  set3LosingTeam?: number;
  tieBreakSet3LosingTeam?: number;
};
