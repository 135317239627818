import { useEffect } from 'react';
import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-8ZS28GZLLP'; // OUR_TRACKING_ID

const useEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
        value: value, // optional, must be a number
      });
    }
  }, [category, action, label, value]);
};

export default useEvent;
